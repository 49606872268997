<template>
  <span>
    <slot name="searchParams">
      <v-text-field
        v-if="search"
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Search"
        clearable
        single-line
        hide-details
      ></v-text-field>
    </slot>
    <v-data-table
      v-bind="$attrs"
      :headers="computedHeaders"
      :items="items"
      :server-items-length="searchTotal"
      :loading="loading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="descending"
      :group-by.sync="groupByProperty"
      :group-desc.sync="groupByDescending"
      :page.sync="selectedPage"
      :items-per-page.sync="rowsPerPage"
      :item-key="itemKey"
      :footer-props="footerProps"
      :dense="dense"
    >
      <template
        v-if="searchRowsPerPage && paginateHeader"
        v-slot:top="{pagination, options, updateOptions}"
      >
        <v-data-footer
          :sort-by.sync="sortBy"
          :sort-desc.sync="descending"
          :page.sync="selectedPage"
          :items-per-page.sync="rowsPerPage"
          :showFirstLastPage="showFirstLastPage"
          :showCurrentPage="showCurrentPage"
          :itemsPerPageOptions="availableItemsPerPageOptions"
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
        >
          <template v-slot:prepend="">
            <div class="v-data-footer__select flex-grow-1">
              Selected Page:
              <v-select
                hide-details
                v-model="searchPage"
                :items="availablePages"
              ></v-select>
            </div>
          </template>
        </v-data-footer>
      </template>
      <template v-if="searchRowsPerPage" v-slot:footer.prepend="">
        <div class="v-data-footer__select flex-grow-1">
          Selected Page:
          <v-select
            hide-details
            v-model="searchPage"
            :items="availablePages"
          ></v-select>
        </div>
      </template>
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>
    </v-data-table>
  </span>
</template>
<script>
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    items: {
      type: Array,
      required: true,
    },
    paginateHeader: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 15, 25, 50, 100, 500],
    },
    searchText: {
      type: String,
      default: '',
    },
    itemKey: {
      type: String,
      default: 'ID',
    },
    searchSortBy: {
      type: [String, Array],
    },
    searchDescending: {
      type: [Boolean, Array],
    },
    groupBy: {
      type: [String, Array],
    },
    groupDesc: {
      type: [Boolean, Array],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showFirstLastPage: {
      type: Boolean,
      default: true,
    },
    showCurrentPage: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    searchRowsPerPage: {
      type: Number,
    },
    searchTotal: {
      type: Number,
    },
    searchPage: {
      type: Number,
    },
    pagination: {
      type: Number,
    },
    maxSearchResultsPerPage: {
      type: Number,
    },
  },
  components: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    availableItemsPerPageOptions() {
      if (this.maxSearchResultsPerPage && this.maxSearchResultsPerPage > 0) {
        return this.itemsPerPageOptions.filter((i) => {
          return i <= this.maxSearchResultsPerPage
        })
      } else {
        return this.itemsPerPageOptions
      }
    },
    rowsPerPage: {
      get: function () {
        return this.searchRowsPerPage
      },
      set: function (value) {
        this.$emit('update:searchRowsPerPage', value)
      },
    },
    sortBy: {
      get: function () {
        return this.searchSortBy
      },
      set: function (value) {
        this.$emit('update:searchSortBy', value)
      },
    },
    descending: {
      get: function () {
        return this.searchDescending
      },
      set: function (value) {
        this.$emit('update:searchDescending', value)
      },
    },
    groupByProperty: {
      get: function () {
        return this.groupBy
      },
      set: function (value) {
        this.$emit('update:groupBy', value)
      },
    },
    groupByDescending: {
      get: function () {
        return this.groupDesc
      },
      set: function (value) {
        this.$emit('update:groupDesc', value)
      },
    },
    selectedPage: {
      get: function () {
        return this.searchPage
      },
      set: function (value) {
        this.$emit('update:searchPage', value)
      },
    },
    text: {
      get: function () {
        return this.searchText
      },
      set: function (value) {
        this.$emit('update:searchText', value)
      },
    },
    computedHeaders() {
      if (this.headers && this.headers.length > 0) {
        return this.headers
      } else {
        if (this.items.length > 0) {
          return Object.keys(this.items[0]).map((i) => {
            return {
              text: i.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1'),
              value: i,
              sortable: true,
            }
          })
        } else {
          return []
        }
      }
    },
    footerProps() {
      return {
        showFirstLastPage: this.showFirstLastPage,
        showCurrentPage: this.showCurrentPage,
        itemsPerPageOptions: this.itemsPerPageOptions,
      }
    },
    availablePages() {
      if (this.searchRowsPerPage) {
        return Array.from(
          {length: Math.ceil(this.searchTotal / this.searchRowsPerPage)},
          (v, i) => i + 1
        )
      } else {
        return []
      }
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>