import Vue from 'vue'

const approvalStatus = {
  state: {
    approvalStatus: [],
    selectedApprovalStatus: {},
  },

  getters: {
    approvalStatus(state) {
      return state.approvalStatus
    },
    selectedApprovalStatus(state) {
      return state.selectedApprovalStatus
    },
  },

  mutations: {
    approvalStatus(state, payload) {
      state.approvalStatus = payload
    },
    selectedApprovalStatus(state, payload) {
      state.selectedApprovalStatus = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedApprovalStatus(context, payload) {
      context.commit('selectedApprovalStatus', payload)
    },
    loadApprovalStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ApprovalStatus'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('approvalStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedApprovalStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ApprovalStatus/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedApprovalStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateApprovalStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ApprovalStatus/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'status updated',
              },
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadApprovalStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createApprovalStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ApprovalStatus`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'status created',
              },
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadApprovalStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveApprovalStatus(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateApprovalStatus', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createApprovalStatus', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteApprovalStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ApprovalStatus/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'status deleted',
              },
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadApprovalStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default approvalStatus
