<template>
  <div class="editor-container">
    <tui-editor
      height="450px"
      ref="toastuiEditor"
      :options="options"
      :initialValue="text"
      initialEditType="wysiwyg"
      @load="onEditorLoad"
      @focus="onEditorFocus"
      @blur="onEditorBlur"
      @change="onEditorChange"
    ></tui-editor
  ></div>
</template>
<script>
// import '@toast-ui/editor/dist/toastui-editor.css'
// import '@toast-ui/editor/dist/theme/toastui-editor-dark.css'

import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
// import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'

// import 'tui-color-picker/dist/tui-color-picker.css'
// import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
//note: disabled for now due to bug
//https://github.com/nhn/tui.editor/issues/1589
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'

import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js'
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell'
import { Editor } from '@toast-ui/vue-editor'
import youtubePlugin from '@src/plugins/tuiYoutube'

export default {
  model: {
    prop: 'text',
    event: 'change',
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  components: { 'tui-editor': Editor },
  filters: {},
  data() {
    return {}
  },
  mounted() {
    // note: the editor was rendering with just a 'more' button toolbar, this will switch modes to correct this
    this.$refs.toastuiEditor.invoke('changeMode', 'markdown')
    setTimeout(() => {
      this.$refs.toastuiEditor.invoke('changeMode', 'wysiwyg')
    }, 1)
  },
  computed: {
    options() {
      return {
        minHeight: '300px',
        previewStyle: 'vertical',
        usageStatistics: false,
        plugins: [
          [codeSyntaxHighlight, { highlighter: Prism }],
          colorSyntax,
          tableMergedCell,
          [youtubePlugin, { width: 325 }],
        ],
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          [
            'table',
            // 'image',
            'link',
          ],
          // ['code', 'codeblock'],
        ],
        customHTMLRenderer: {
          htmlBlock: {
            iframe(node) {
              return [
                {
                  type: 'openTag',
                  tagName: 'iframe',
                  outerNewLine: true,
                  attributes: node.attrs,
                },
                { type: 'html', content: node.childrenHTML },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
              ]
            },
          },
          htmlInline: {},
        },
        theme: this.$vuetify.theme.dark ? 'dark' : '',
      }
    },
  },
  methods: {
    getMarkdown() {
      this.$emit('change', this.$refs.toastuiEditor.invoke('getMarkdown'))
    },
    onEditorLoad() {
      // console.devlog('load', arguments)
      // implement your code
    },
    onEditorFocus() {
      // console.devlog('focus', arguments)
      // implement your code
    },
    onEditorBlur() {
      // console.devlog('blur', arguments)
      // implement your code
      // this.$emit('change', this.$refs.toastuiEditor.invoke('getMarkdown'))
    },
    onEditorChange(change) {
      // console.devlog('editor', change, arguments)
      // implement your code
      // var text = this.$refs.toastuiEditor.invoke('getMarkdown')
      // console.devlog(text)
    },
    setMarkdown(value) {
      this.$refs.toastuiEditor.invoke('setMarkdown', value)
    },
  },

  watch: {
    text(nval, oval) {
      console.devlog(nval, oval)
      this.$refs.toastuiEditor.invoke('setMarkdown', nval)
    },
  },
}
</script>
<style scoped>
.editor-container {
  min-height: 450px;
  min-width: 600px;
}
</style>
<style>
.ProseMirror {
  padding-top: 60px !important;
}
.toastui-editor-md-preview {
  padding-top: 50px !important;
}
/* remove text shadow - looks weird in dark mode */
code[class*='language-'],
pre[class*='language-'] {
  text-shadow: none !important;
}
/* .token.operator has weird background in dark mode */
.token {
  background: none !important;
}
/* .token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.5);
} */

/* vuetify has different background color for code elements */
.toastui-editor-contents pre code {
  background-color: unset !important;
}
</style>
