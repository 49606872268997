import Vue from 'vue'

const ProjectLicense = {
  state: {
    ProjectLicense: [],
    selectedProjectLicense: {},
  },

  getters: {
    ProjectLicense(state) {
      return state.ProjectLicense
    },
    selectedProjectLicense(state) {
      return state.selectedProjectLicense
    },
  },

  mutations: {
    ProjectLicense(state, payload) {
      state.ProjectLicense = payload
    },
    selectedProjectLicense(state, payload) {
      state.selectedProjectLicense = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectLicense(context, payload) {
      context.commit('selectedProjectLicense', payload)
    },
    loadProjectLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectLicense'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('ProjectLicense', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectLicense/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectLicense', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectLicense/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'ProjectLicense updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectLicense')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectLicense`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'ProjectLicense created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectLicense')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectLicense(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectLicense', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectLicense', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectLicense/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'ProjectLicense deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectLicense')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default ProjectLicense
