import Vue from 'vue'

const user = {
  state: {
    user: [],
    userLookup: [],
    selectedUser: {},
    searchCount: 0,
  },

  getters: {
    user(state) {
      return state.user
    },
    userLookup(state) {
      return state.userLookup
    },
    selectedUser(state) {
      return state.selectedUser
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    user(state, payload) {
      state.user = payload
    },
    userLookup(state, payload) {
      state.userLookup = payload
    },
    selectedUser(state, payload) {
      state.selectedUser = JSON.parse(JSON.stringify(payload))
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedUser(context, payload) {
      context.commit('selectedUser', payload)
    },
    loadUser(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'User'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('user', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedUser(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'User/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedUser', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadUserLookup(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'User/Lookups'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            let data = res.data.map((item) => {
              return {
                ...item,
                FullName: item.FirstName + ' ' + item.LastName,
              }
            })
            context.commit('userLookup', data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateUser(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `User/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'user updated',
              },
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadUser')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createUser(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `User`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'user created',
              },
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadUser')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveUser(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateUser', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createUser', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteUser(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `User/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'user deleted',
              },
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadUser')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default user
