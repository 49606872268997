import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[(_vm.collapsable)?_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-h5 text-left",class:_vm.headerClass,scopedSlots:_vm._u([(_vm.textHeaderClass)?{key:"actions",fn:function(){return [_c(VIcon,{class:_vm.textHeaderClass},[_vm._v("$vuetify.icons.expand")])]},proxy:true}:null],null,true)},[_c('span',[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.headerText))]})],2)]),_c(VExpansionPanelContent,[_vm._t("content"),_vm._t("default")],2)],1)],1):_c('div',[_c(VListItem,{class:_vm.headerClass},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 text-left",class:_vm.headerClass},[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.headerText))]})],2)],1)],1),_c(VCardText,[_vm._t("content"),_vm._t("default")],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }