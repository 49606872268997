import Vue from 'vue'
import {debounce, range} from 'lodash'
import dayjs from 'dayjs'
import download from 'downloadjs'
const exciseExceptions = {
  state: {
    activateDeactivateRunning: false,
    downloadPending: false,
    datasheetUnsavedItems: false,
    relations: {},
  },
  getters: {},
  mutations: {
    activateDeactivateRunning(state, payload) {
      state.activateDeactivateRunning = payload
    },
    downloadPending(state, payload) {
      state.downloadPending = payload
    },
    relations(state, payload) {
      state.relations = payload
    },
    datasheetUnsavedItems(state, payload) {
      state.datasheetUnsavedItems = payload
    },
    updateExceptionItemInList(state, payload) {
      const {index, item} = payload
      if (index >= 0) {
        let match = state.exciseExceptions[index]
        if (!match) {
          //item was appended to grid, and is not in the source array
          console.devlog('no match', index, item, match)
          state.exciseExceptions.splice(index, 1, item)
        } else if (match.ID == item.ID) {
          console.devlog('match', index, item, match)
          state.exciseExceptions.splice(index, 1, item)
        } else {
          //match, but item has ID mismatch
          console.devlog('match, but item has ID mismatch')
        }
        console.devlog(state.exciseExceptions)
      } else if (index == -1) {
        state.exciseExceptions.unshift(item)
        // context.dispatch('searchExciseExceptions')
      }
    },
  },
  actions: {
    setActivateDeactivateRunning(context, payload) {
      if (payload != context.state.activateDeactivateRunning) {
        context.commit('activateDeactivateRunning', payload)
      }
    },
    setDownloadPending(context, payload) {
      if (payload != context.state.downloadPending) {
        context.commit('activateDeactivateRunning', payload)
      }
    },
    setDatasheetUnsavedItems(context, payload) {
      if (payload != context.state.datasheetUnsavedItems) {
        context.commit('datasheetUnsavedItems', payload)
      }
    },
    activateDeactivateExciseExceptions(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
        if (!Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy) {
            context.commit('searchSortBy', [context.state.searchSortBy])
            if (!Array.isArray(context.state.searchDescending)) {
              context.commit('searchDescending', [
                context.state.searchDescending,
              ])
            }
          } else {
            context.commit('searchSortBy', [searchSortByDefault])
            context.commit('searchDescending', [true])
          }
        }
        if (!context.state.searchText) {
          context.commit('searchText', '')
        }
        context.commit('increaseSearchCount')
        context.commit('activateDeactivateRunning', true)
        var params = context.getters.searchParameters
        var paramString = JSON.stringify(params)
        let {active} = payload
        let url = `ExciseExceptions/`
        if (active) {
          url += 'Activate'
        } else {
          url += 'Deactivate'
        }
        Vue.prototype.$axios.post(url, params).then(
          (res) => {
            if (
              JSON.stringify(context.getters.searchParameters) == paramString
            ) {
              // let entries = res.data.Entries.map((entry) => {
              //   return {
              //     ...entry,
              //     InvoiceDate: dayjs(entry.InvoiceDate).toDate(),
              //     //   .format(
              //     //   'MM/DD/YYYY'
              //     // ),
              //   }
              // })
              context.commit('exciseExceptions', res.data.Entries)
              context.commit('searchRowsPerPage', res.data.Page.Size)
              context.commit('searchPage', res.data.Page.Number + 1)
              context.commit('searchTotal', res.data.Page.TotalElements)
            }
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: active
                  ? 'Exceptions Activated'
                  : 'Exceptions Deactivated',
              },
              {root: true}
            )
            context.commit('decreaseSearchCount')
            context.commit('activateDeactivateRunning', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseSearchCount')
            context.commit('activateDeactivateRunning', false)
            reject(err)
          }
        )
      })
    },
    activateDeactivateSelectedExciseExceptions(context, payload) {
      return new Promise((resolve, reject) => {
        console.devlog(payload)
        let {ids, active} = payload
        let url = `ExciseExceptions/`
        if (active) {
          url += 'ActivateSelected'
        } else {
          url += 'DeactivateSelected'
        }
        context.commit('activateDeactivateRunning', true)
        Vue.prototype.$axios.post(url, ids).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: active
                  ? 'Exceptions Activated'
                  : 'Exceptions Deactivated',
              },
              {root: true}
            )
            context.commit('activateDeactivateRunning', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('activateDeactivateRunning', false)
            reject(err)
          }
        )
      })
    },
    saveMultiple(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ExciseExceptions/Multiple`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Exceptions Saved'},
              {root: true}
            )

            // context.dispatch('searchExciseExceptions')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.devlog(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    projectionErrorRates(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ExciseExceptions/Projections/ErrorRate/${payload}`
        Vue.prototype.$axios.get(url).then(
          (res) => {
            if (res.data.length > 0) {
              context.dispatch(
                'messages/dialogMessage',
                {message: res.data},
                {root: true}
              )
            } else {
              context.dispatch(
                'messages/toastMessage',
                {color: 'success', message: 'Error Rate Projections Completed'},
                {root: true}
              )
            }

            // context.dispatch('searchExciseExceptions')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.devlog(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    projectionAverageError(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ExciseExceptions/Projections/AverageError/${payload}`
        Vue.prototype.$axios.get(url).then(
          (res) => {
            if (res.data.length > 0) {
              context.dispatch(
                'messages/dialogMessage',
                {message: res.data},
                {root: true}
              )
            }
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'Average Error Projections Completed',
              },
              {root: true}
            )

            // context.dispatch('searchExciseExceptions')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.devlog(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    searchExportExciseExceptions(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
        if (!Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy) {
            context.commit('searchSortBy', [context.state.searchSortBy])
            if (!Array.isArray(context.state.searchDescending)) {
              context.commit('searchDescending', [
                context.state.searchDescending,
              ])
            }
          } else {
            context.commit('searchSortBy', [searchSortByDefault])
            context.commit('searchDescending', [true])
          }
        }
        if (!context.state.searchText) {
          context.commit('searchText', '')
        }
        context.commit('downloadPending', true)
        var params = context.getters.searchParameters
        const url = `ExciseExceptions/Search/Export`
        Vue.prototype.$axios.post(url, params, {responseType: 'blob'}).then(
          (res) => {
            const content = res.headers['content-type']
            const dispo = res.headers['content-disposition']
            var filename = ''
            if (dispo && dispo.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              var matches = filenameRegex.exec(dispo)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            download(res.data, filename, content)
            context.commit('downloadPending', false)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('downloadPending', false)
            reject(err)
          }
        )
      })
    },
    searchDetailedExportExciseExceptions(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
        if (!Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy) {
            context.commit('searchSortBy', [context.state.searchSortBy])
            if (!Array.isArray(context.state.searchDescending)) {
              context.commit('searchDescending', [
                context.state.searchDescending,
              ])
            }
          } else {
            context.commit('searchSortBy', [searchSortByDefault])
            context.commit('searchDescending', [true])
          }
        }
        if (!context.state.searchText) {
          context.commit('searchText', '')
        }
        context.commit('downloadPending', true)
        var params = context.getters.searchParameters
        const url = `ExciseExceptions/Search/DetailExport`
        Vue.prototype.$axios.post(url, params, {responseType: 'blob'}).then(
          (res) => {
            const content = res.headers['content-type']
            const dispo = res.headers['content-disposition']
            var filename = ''
            if (dispo && dispo.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              var matches = filenameRegex.exec(dispo)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            download(res.data, filename, content)
            context.commit('downloadPending', false)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('downloadPending', false)
            reject(err)
          }
        )
      })
    },
    downloadImportFields(context, payload) {
      return new Promise((resolve, reject) => {
        console.blue(context.rootState.project.selectedProject.ID)
        const url = `ExciseExceptions/ImportFields/${context.rootState.project.selectedProject.ID}`
        Vue.prototype.$axios.get(url, {responseType: 'blob'}).then(
          (res) => {
            const content = res.headers['content-type']
            const dispo = res.headers['content-disposition']
            var filename = ''
            if (dispo && dispo.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              var matches = filenameRegex.exec(dispo)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            download(res.data, filename, content)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    getRelatedOptions(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ExciseExceptions/RelatedFields/${context.rootState.project.selectedProject.ID}`
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('relations', res.data)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}
export default exciseExceptions
