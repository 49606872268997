<template>
  <span>
    <div
      v-show="!isLoggedIn || (loginType != 'google' && alwaysShowButton)"
      ref="google_one_tap"
    ></div>
    <div v-if="isLoggedIn && loginType == 'google'">
      <v-btn
        color="background"
        :dark="$vuetify.theme.dark ? true : false"
        :light="$vuetify.theme.dark ? false : true"
        @click="logoutAndRedirect"
      >
        <v-icon>mdi-google</v-icon>
        &nbsp;Sign out<span v-if="currentUser"
          >: {{ currentUser.FirstName }} {{ currentUser.LastName }}</span
        >
      </v-btn>
    </div>
  </span>
</template>

<script>
import {authMethods, authComputed} from '@state/helpers'
export default {
  data() {
    return {}
  },
  props: {
    alwaysShowButton: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.$google.getClient()
    var buttonDiv = this.$refs.google_one_tap
    var theme = this.$vuetify.theme.dark ? 'filled_black' : 'outline'
    this.$google.client.accounts.id.renderButton(buttonDiv, {
      type: 'standard',
      shape: 'rectangular',
      theme,
      size: 'large',
      logo_alignment: 'left',
      width: 350,
    })
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
    logoutAndRedirect() {
      this.$router.push({name: 'home'})
      this.logOut()
    },
  },
}
</script>

<style scoped>
</style>
