import Vue from 'vue'

const contact = {
  state: {
    contact: [],
    selectedContact: {},
  },

  getters: {
    contact(state) {
      return state.contact
    },
    selectedContact(state) {
      return state.selectedContact
    },
  },

  mutations: {
    contact(state, payload) {
      state.contact = payload
    },
    selectedContact(state, payload) {
      state.selectedContact = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedContact(context, payload) {
      context.commit('selectedContact', payload)
    },
    loadContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Contact'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('contact', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Contact/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedContact', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Contact/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Contact`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveContact(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateContact', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createContact', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Contact/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default contact
