import Vue from 'vue'

const luExamType = {
  state: {
    luExamType: [],
    selectedLuExamType: {},
  },

  getters: {
    luExamType(state) {
      return state.luExamType
    },
    selectedLuExamType(state) {
      return state.selectedLuExamType
    },
  },

  mutations: {
    luExamType(state, payload) {
      state.luExamType = payload
    },
    selectedLuExamType(state, payload) {
      state.selectedLuExamType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuExamType(context, payload) {
      context.commit('selectedLuExamType', payload)
    },
    loadLuExamType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuExamType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luExamType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuExamType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuExamType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuExamType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuExamType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuExamType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luExamType updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExamType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuExamType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuExamType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luExamType created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExamType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuExamType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuExamType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuExamType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuExamType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuExamType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luExamType deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExamType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luExamType
