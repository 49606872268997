import Vue from 'vue'

const luCensusReport = {
  state: {
    luCensusReport: [],
    selectedLuCensusReport: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luCensusReport(state) {
      return state.luCensusReport
    },
    selectedLuCensusReport(state) {
      return state.selectedLuCensusReport
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luCensusReport(state, payload) {
      state.luCensusReport = payload
    },
    selectedLuCensusReport(state, payload) {
      state.selectedLuCensusReport = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuCensusReport(context, payload) {
      context.commit('selectedLuCensusReport', payload)
    },
    loadLuCensusReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuCensusReport'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luCensusReport', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    refreshSelectedLuCensusReport(context, payload) {
      if (
        context.state.selectedLuCensusReport &&
        context.state.selectedLuCensusReport.ID
      ) {
        context.dispatch(
          'loadSelectedLuCensusReport',
          context.state.selectedLuCensusReport.ID
        )
      }
    },
    loadSelectedLuCensusReport(context, payload) {
      if (
        context.state.selectedLuCensusReport &&
        context.state.selectedLuCensusReport.ID != payload
      ) {
        context.commit('selected', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'LuCensusReport/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuCensusReport', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    clearLookupCacheAndReloadLuCensusReport(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
        context.dispatch('loadLuCensusReport')
      })
    },
    updateLuCensusReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuCensusReport/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Lu Census Report updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCensusReport')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuCensusReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuCensusReport`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Lu Census Report created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCensusReport')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuCensusReport(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuCensusReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuCensusReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuCensusReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuCensusReport/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Lu Census Report deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCensusReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luCensusReport
