import Vue from 'vue'

const projectExpenses = {
  state: {
    projectExpenses: [],
    selectedProjectExpenses: {},
    saving: false,
    searchCount: 0,
    selectedProjectID: null,
  },

  getters: {
    projectExpenses(state) {
      return state.projectExpenses
    },
    selectedProjectExpenses(state) {
      return state.selectedProjectExpenses
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    projectExpenses(state, payload) {
      state.projectExpenses = payload
    },
    selectedProjectID(state, payload) {
      state.selectedProjectID = payload
    },
    selectedProjectExpenses(state, payload) {
      state.selectedProjectExpenses = JSON.parse(JSON.stringify(payload))
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
    saving(state, payload) {
      state.saving = payload
    },
  },

  actions: {
    setSelectedProjectExpenses(context, payload) {
      context.commit('selectedProjectExpenses', payload)
    },
    // this has been modified from the original code to get the expenses for a single project
    loadProjectExpenses(context, payload) {
      if (payload != context.state.selectedProjectID) {
        context.commit('projectExpenses', [])
      }
      context.commit('selectedProjectID', payload)
      return new Promise((resolve, reject) => {
        const url = `ProjectExpenses/ByProject/${payload}`
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectExpenses', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectExpenses(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectExpenses/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectExpenses', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectExpenses(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectExpenses/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectExpenses updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectExpenses')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('saving', false)
            reject(err)
          }
        )
      })
    },
    createProjectExpenses(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectExpenses`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectExpenses created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectExpenses')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('saving', false)
            reject(err)
          }
        )
      })
    },
    saveProjectExpenses(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectExpenses', payload).then(
            (res) => {
              //reload project expenses
              context.dispatch('loadProjectExpenses', payload.ProjectID)
              return resolve(res)
            },
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectExpenses', payload).then(
            (res) => {
              context.dispatch('loadProjectExpenses', payload.ProjectID)
              return resolve(res)
            },
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectExpenses(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectExpenses/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectExpenses deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectExpenses')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectExpenses
