import Vue from 'vue'

const subEntityContact = {
  state: {
    subEntityContact: [],
    selectedSubEntityContact: {},
  },

  getters: {
    subEntityContact(state) {
      return state.subEntityContact
    },
    selectedSubEntityContact(state) {
      return state.selectedSubEntityContact
    },
  },

  mutations: {
    subEntityContact(state, payload) {
      state.subEntityContact = payload
    },
    selectedSubEntityContact(state, payload) {
      state.selectedSubEntityContact = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedSubEntityContact(context, payload) {
      context.commit('selectedSubEntityContact', payload)
    },
    loadSubEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SubEntityContact'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('subEntityContact', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedSubEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SubEntityContact/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedSubEntityContact', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateSubEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SubEntityContact/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSubEntityContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createSubEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SubEntityContact`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSubEntityContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveSubEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateSubEntityContact', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createSubEntityContact', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteSubEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SubEntityContact/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSubEntityContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default subEntityContact
