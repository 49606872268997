import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VLayout,[_c(VSnackbar,{staticClass:"snackbarStyles",attrs:{"bottom":"bottom","timeout":-1,"vertical":"vertical","multi-line":true,"left":""},model:{value:(_vm.displayError),callback:function ($$v) {_vm.displayError=$$v},expression:"displayError"}},[(_vm.errors.length > 0)?_c(VList,{attrs:{"dense":"","dark":""}},_vm._l((_vm.errors),function(error,index){return _c(VListItem,{key:index},[_c(VListItemContent,{on:{"click":function($event){return _vm.showDetails(error)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("snippet")(error))),(error.length > 80)?_c(VIcon,[_vm._v("mdi-open-in-new")]):_vm._e()],1)],1),_c(VListItemAction,{staticClass:"ma-0"},[_c(VBtn,{staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){return _vm.rmError(index)}}},[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("clear")])],1)],1)],1)}),1):_c('div',[_vm._v(_vm._s(_vm.errorMessage))]),_c('br'),_c(VBtn,{attrs:{"color":"grey lighten-5","text":""},on:{"click":_vm.closeError}},[_vm._v("Close")])],1),_c(VSnackbar,{attrs:{"vertical":"vertical","multi-line":true,"timeout":-1,"right":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',[_vm._v(" "+_vm._s(_vm.errorDetails)+" ")]),_c(VBtn,{attrs:{"color":"grey lighten-5","text":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }