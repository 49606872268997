import Vue from 'vue'

const message = {
  state: {
    messages: [],
    messageText: '',
    displayMessage: false,
    openMessage: false,
    color: '',
  },

  getters: {
    messages(state) {
      return state.messages
    },
    color(state) {
      return state.color
    },
    messageText(state) {
      return state.messageText
    },
    displayMessage(state) {
      return state.displayMessage
    },
    openMessage(state) {
      return state.openMessage
    },
  },

  mutations: {
    messages(state, payload) {
      state.messages = payload
    },
    color(state, payload) {
      state.color = payload
    },
    messageText(state, payload) {
      state.messageText = payload
    },
    displayMessage(state, payload) {
      state.displayMessage = payload
    },
    openMessage(state, payload) {
      state.openMessage = payload
    },
    addMessage(state, payload) {
      state.messages.push(payload)
    },
    removeMessage(state, payload) {
      state.messages.splice(payload, 1)
      if (state.messages.length == 0) {
        state.displayMessage = false
      }
    },
    clearMessages(state, payload) {
      state.messages = []
    },
  },

  actions: {
    setMessages(context, payload) {
      context.commit('messages', payload)
    },
    setColor(context, payload) {
      context.commit('color', payload)
    },
    setMessageText(context, payload) {
      context.commit('messageText', payload)
    },
    setDisplayMessage(context, payload) {
      context.commit('displayMessage', payload)
    },
    addMessage(context, payload) {
      context.commit('addMessage', payload)
    },
    removeMessage(context, payload) {
      context.commit('removeMessage', payload)
    },
    clearMessage(context) {
      context.commit('messageText', '')
      context.commit('displayMessage', false)
    },
    closeMessage(context) {
      context.commit('messageText', '')
      context.commit('openMessage', false)
    },
    processMessage(context, payload) {
      let message = null
      if (payload.color || payload.message) {
        context.commit('color', payload.color || 'primary')
        message = payload.message
      } else {
        context.commit('color', 'primary')
        message = payload
      }
      console.info(message)
      let messageText = ''
      context.commit('clearMessages')
      let messageData = null
      if (message.response && message.response.data) {
        context.commit('addMessage', message)
        messageData = message.response.data
      } else if (Array.isArray(message) || typeof message == 'string') {
        messageData = message
      }
      if (messageData === Object(messageData)) {
        let messageList = Object.values(messageData)
        if (Array.isArray(messageList)) {
          messageList.forEach((messageItem) => {
            if (Array.isArray(messageItem)) {
              messageItem.forEach((msg) => {
                if (typeof msg == 'string') {
                  messageText += '\n' + msg
                  context.commit('addMessage', msg)
                } else {
                  console.info(msg)
                }
              })
            } else if (typeof messageItem == 'string') {
              messageText += '\n' + messageItem
              context.commit('addMessage', messageItem)
            } else {
              console.info(messageItem)
            }
          })
        } else if (typeof messageList == 'string') {
          messageText += '\n' + messageList
          context.commit('addMessage', messageList)
        } else {
          console.info(messageList)
        }
      } else if (Array.isArray(messageData)) {
        messageData.forEach((msg) => {
          if (typeof msg == 'string') {
            messageText += '\n' + msg
            context.commit('addMessage', msg)
          } else {
            console.info(msg)
          }
        })
      } else if (typeof messageData == 'string') {
        messageText += '\n' + messageData
        context.commit('addMessage', messageData)
      } else {
        console.info(messageData)
      }
      context.commit('messageText', messageText)
    },
    dialogMessage(context, payload) {
      context.dispatch('processMessage', payload)
      context.commit('openMessage', true)
    },
    toastMessage(context, payload) {
      context.dispatch('processMessage', payload)
      context.commit('displayMessage', true)
    },
  },
}

export default message
