import Vue from 'vue'

const auditEntity = {
  state: {
    auditEntity: [],
    selectedAuditEntity: {},
  },

  getters: {
    auditEntity(state) {
      return state.auditEntity
    },
    selectedAuditEntity(state) {
      return state.selectedAuditEntity
    },
  },

  mutations: {
    auditEntity(state, payload) {
      state.auditEntity = payload
    },
    selectedAuditEntity(state, payload) {
      state.selectedAuditEntity = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    refreshSelectedAuditEntity(context, payload) {
      if (
        context.state.selectedAuditEntity &&
        context.state.selectedAuditEntity.ID > 0
      ) {
        context.dispatch(
          'loadSelectedAuditEntity',
          context.state.selectedAuditEntity.ID
        )
      }
    },
    setSelectedAuditEntity(context, payload) {
      context.commit('selectedAuditEntity', payload)
    },
    loadAuditEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'AuditEntity'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('auditEntity', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedAuditEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'AuditEntity/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedAuditEntity', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateAuditEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `AuditEntity/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'entity updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadAuditEntity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createAuditEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `AuditEntity`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'entity created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadAuditEntity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveAuditEntity(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateAuditEntity', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createAuditEntity', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteAuditEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `AuditEntity/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'entity deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadAuditEntity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default auditEntity
