import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const exciseTaxRateToSpecialDistrict = {
  state: {
    exciseTaxRateToSpecialDistrict: [],
    selectedExciseTaxRateToSpecialDistrict: {},
    selectedExciseTaxRateToSpecialDistrictOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    exciseTaxRateToSpecialDistrict(state) {
      return state.exciseTaxRateToSpecialDistrict
    },
    selectedExciseTaxRateToSpecialDistrict(state) {
      return state.selectedExciseTaxRateToSpecialDistrict
    },
    selectedExciseTaxRateToSpecialDistrictOriginal(state) {
      return state.selectedExciseTaxRateToSpecialDistrictOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    exciseTaxRateToSpecialDistrict(state, payload) {
      state.exciseTaxRateToSpecialDistrict = payload
    },
    selectedExciseTaxRateToSpecialDistrict(state, payload) {
      state.selectedExciseTaxRateToSpecialDistrict = JSON.parse(JSON.stringify(payload))
    },
    selectedExciseTaxRateToSpecialDistrictOriginal(state, payload) {
      state.selectedExciseTaxRateToSpecialDistrictOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedExciseTaxRateToSpecialDistrict(context, payload) {
      context.commit('selectedExciseTaxRateToSpecialDistrict', payload)
    },
    loadExciseTaxRateToSpecialDistrict(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ExciseTaxRateToSpecialDistrict'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('exciseTaxRateToSpecialDistrict', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedExciseTaxRateToSpecialDistrict(context, payload) {
      if (context.state.selectedExciseTaxRateToSpecialDistrict && context.state.selectedExciseTaxRateToSpecialDistrict.ID > 0) {
        context.dispatch('loadSelectedExciseTaxRateToSpecialDistrict', context.state.selectedExciseTaxRateToSpecialDistrict.ID)

      }
    },
    loadSelectedExciseTaxRateToSpecialDistrict(context, payload) {
      if (context.state.selectedExciseTaxRateToSpecialDistrict && context.state.selectedExciseTaxRateToSpecialDistrict.ID != payload) {
        context.commit('selectedExciseTaxRateToSpecialDistrict', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'ExciseTaxRateToSpecialDistrict/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedExciseTaxRateToSpecialDistrict', res.data)
            context.commit('selectedExciseTaxRateToSpecialDistrictOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadExciseTaxRateToSpecialDistrict(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadExciseTaxRateToSpecialDistrict')
      }, )
    },
    createExciseTaxRateToSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseTaxRateToSpecialDistrict`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to special district created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToSpecialDistrict')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchExciseTaxRateToSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedExciseTaxRateToSpecialDistrict
        if (payload.ID && 
        payload.ID == context.state.selectedExciseTaxRateToSpecialDistrictOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedExciseTaxRateToSpecialDistrictOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `ExciseTaxRateToSpecialDistrict/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise tax rate to special district updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadExciseTaxRateToSpecialDistrict')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateExciseTaxRateToSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `ExciseTaxRateToSpecialDistrict/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to special district updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToSpecialDistrict')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveExciseTaxRateToSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateExciseTaxRateToSpecialDistrict', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createExciseTaxRateToSpecialDistrict', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteExciseTaxRateToSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseTaxRateToSpecialDistrict/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to special district deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToSpecialDistrict')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default exciseTaxRateToSpecialDistrict