import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luNotificationType = {
  state: {
    luNotificationTypes: [],
    selectedLuNotificationType: {},
    selectedLuNotificationTypeOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luNotificationTypes(state) {
      return state.luNotificationTypes
    },
    selectedLuNotificationType(state) {
      return state.selectedLuNotificationType
    },
    selectedLuNotificationTypeOriginal(state) {
      return state.selectedLuNotificationTypeOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luNotificationTypes(state, payload) {
      state.luNotificationTypes = payload
    },
    selectedLuNotificationType(state, payload) {
      state.selectedLuNotificationType = JSON.parse(JSON.stringify(payload))
    },
    selectedLuNotificationTypeOriginal(state, payload) {
      state.selectedLuNotificationTypeOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuNotificationType(context, payload) {
      context.commit('selectedLuNotificationType', payload)
    },
    async loadLuNotificationTypes(context, payload) {
      const url = 'LuNotificationType'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luNotificationTypes', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedLuNotificationType(context, payload) {
      if (
        context.state.selectedLuNotificationType &&
        context.state.selectedLuNotificationType.Id >  0 
      ) {
        return await context.dispatch(
          'loadSelectedLuNotificationType',
          context.state.selectedLuNotificationType.Id
        )
      }
    },
    async loadSelectedLuNotificationType(context, payload) {
      if (
        context.state.selectedLuNotificationType &&
        context.state.selectedLuNotificationType.Id != payload
      ) {
        context.commit('selectedLuNotificationType', {})
      }
      const url = 'LuNotificationType/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuNotificationType', res.data)
        context.commit('selectedLuNotificationTypeOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadLuNotificationTypes(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadLuNotificationTypes')
    },
    async createLuNotificationType(context, payload) {
      const url = `LuNotificationType`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'notification type created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuNotificationTypes')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuNotificationType(context, payload) {
      var item = context.state.selectedLuNotificationType
      if (
        payload.Id &&
        payload.Id == context.state.selectedLuNotificationTypeOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuNotificationTypeOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuNotificationType/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'notification type updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuNotificationTypes')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuNotificationType(context, payload) {
      const url = `LuNotificationType/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'notification type updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuNotificationTypes')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuNotificationType(context, payload) {
      if (payload.Id >  0 ) {
        return context.dispatch('updateLuNotificationType', payload)
      } else {
        return context.dispatch('createLuNotificationType', payload)
      }
    },
    async deleteLuNotificationType(context, payload) {
      const url = `LuNotificationType/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'notification type deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuNotificationTypes')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luNotificationType
