import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const exciseTaxRateToCounty = {
  state: {
    exciseTaxRateToCounty: [],
    selectedExciseTaxRateToCounty: {},
    selectedExciseTaxRateToCountyOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    exciseTaxRateToCounty(state) {
      return state.exciseTaxRateToCounty
    },
    selectedExciseTaxRateToCounty(state) {
      return state.selectedExciseTaxRateToCounty
    },
    selectedExciseTaxRateToCountyOriginal(state) {
      return state.selectedExciseTaxRateToCountyOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    exciseTaxRateToCounty(state, payload) {
      state.exciseTaxRateToCounty = payload
    },
    selectedExciseTaxRateToCounty(state, payload) {
      state.selectedExciseTaxRateToCounty = JSON.parse(JSON.stringify(payload))
    },
    selectedExciseTaxRateToCountyOriginal(state, payload) {
      state.selectedExciseTaxRateToCountyOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedExciseTaxRateToCounty(context, payload) {
      context.commit('selectedExciseTaxRateToCounty', payload)
    },
    loadExciseTaxRateToCounty(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ExciseTaxRateToCounty'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('exciseTaxRateToCounty', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedExciseTaxRateToCounty(context, payload) {
      if (context.state.selectedExciseTaxRateToCounty && context.state.selectedExciseTaxRateToCounty.ID > 0) {
        context.dispatch('loadSelectedExciseTaxRateToCounty', context.state.selectedExciseTaxRateToCounty.ID)

      }
    },
    loadSelectedExciseTaxRateToCounty(context, payload) {
      if (context.state.selectedExciseTaxRateToCounty && context.state.selectedExciseTaxRateToCounty.ID != payload) {
        context.commit('selectedExciseTaxRateToCounty', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'ExciseTaxRateToCounty/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedExciseTaxRateToCounty', res.data)
            context.commit('selectedExciseTaxRateToCountyOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadExciseTaxRateToCounty(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadExciseTaxRateToCounty')
      }, )
    },
    createExciseTaxRateToCounty(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseTaxRateToCounty`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to county created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToCounty')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchExciseTaxRateToCounty(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedExciseTaxRateToCounty
        if (payload.ID && 
        payload.ID == context.state.selectedExciseTaxRateToCountyOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedExciseTaxRateToCountyOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `ExciseTaxRateToCounty/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise tax rate to county updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadExciseTaxRateToCounty')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateExciseTaxRateToCounty(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `ExciseTaxRateToCounty/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to county updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToCounty')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveExciseTaxRateToCounty(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateExciseTaxRateToCounty', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createExciseTaxRateToCounty', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteExciseTaxRateToCounty(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseTaxRateToCounty/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to county deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToCounty')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default exciseTaxRateToCounty