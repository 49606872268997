<template>
  <v-layout>
    <v-snackbar
      class="snackbarStyles"
      v-model="displayMessage"
      :color="color"
      bottom="bottom"
      :timeout="timeout"
      left
      :multi-line="true"
      vertical="vertical"
    >
      <div v-for="(message, index) in messages" :key="index">
        <div>{{ message }}</div>
      </div>
    </v-snackbar>
  </v-layout>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  data() {
    return {
      dialog: false,
      messageDetails: '',
      timeout: 6000,
    }
  },
  filters: {
    snippet(text) {
      if (text.toString().length > 80) {
        return text.toString().substring(0, 80) + '...'
      } else {
        return text
      }
    },
  },
  computed: {
    ...sync('messages', ['displayMessage']),
    ...mapGetters('messages', ['messages', 'messageText', 'color']),
    // displayMessage: {
    //   get() {
    //     return this.$store.state.messages.displayMessage
    //   },
    //   set(newValue) {
    //     console.info(this)
    //     this.setDisplayMessage(newValue)
    //   },
    // },
  },
  methods: {
    // ...mapActions('messages', ['setDisplayMessage']),
    closeMessage() {
      this.displayMessage = false
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>