import Vue from 'vue'

const projectBudgetedHours = {
  state: {
    projectBudgetedHours: [],
    selectedProjectBudgetedHours: {},
    saving: false,
    searchCount: 0,
    selectedProjectID: null,
  },

  getters: {
    projectBudgetedHours(state) {
      return state.projectBudgetedHours
    },
    selectedProjectBudgetedHours(state) {
      return state.selectedProjectBudgetedHours
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    projectBudgetedHours(state, payload) {
      state.projectBudgetedHours = payload
    },
    selectedProjectID(state, payload) {
      state.selectedProjectID = payload
    },
    selectedProjectBudgetedHours(state, payload) {
      state.selectedProjectBudgetedHours = JSON.parse(JSON.stringify(payload))
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
    saving(state, payload) {
      state.saving = payload
    },
  },

  actions: {
    setSelectedProjectBudgetedHours(context, payload) {
      context.commit('selectedProjectBudgetedHours', payload)
    },
    // this has been modified from the original code to get the expenses for a single project
    loadProjectBudgetedHours(context, payload) {
      if (payload != context.state.selectedProjectID) {
        context.commit('projectBudgetedHours', [])
      }
      context.commit('selectedProjectID', payload)
      return new Promise((resolve, reject) => {
        const url = `ProjectBudgetedHours/ByProject/${payload}`
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectBudgetedHours', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectBudgetedHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectBudgetedHours/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectBudgetedHours', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectBudgetedHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectBudgetedHours/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectBudgetedHours updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectBudgetedHours')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('saving', false)
            reject(err)
          }
        )
      })
    },
    createProjectBudgetedHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectBudgetedHours`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectBudgetedHours created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectBudgetedHours')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('saving', false)
            reject(err)
          }
        )
      })
    },
    saveProjectBudgetedHours(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectBudgetedHours', payload).then(
            (res) => {
              context.dispatch('loadProjectBudgetedHours', payload.ProjectID)
              return resolve(res)
            },
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectBudgetedHours', payload).then(
            (res) => {
              context.dispatch('loadProjectBudgetedHours', payload.ProjectID)
              return resolve(res)
            },
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectBudgetedHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectBudgetedHours/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectBudgetedHours deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectBudgetedHours')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectBudgetedHours
