import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const exciseTaxRateToCountyStatewide = {
  state: {
    exciseTaxRateToCountyStatewide: [],
    selectedExciseTaxRateToCountyStatewide: {},
    selectedExciseTaxRateToCountyStatewideOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    exciseTaxRateToCountyStatewide(state) {
      return state.exciseTaxRateToCountyStatewide
    },
    selectedExciseTaxRateToCountyStatewide(state) {
      return state.selectedExciseTaxRateToCountyStatewide
    },
    selectedExciseTaxRateToCountyStatewideOriginal(state) {
      return state.selectedExciseTaxRateToCountyStatewideOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    exciseTaxRateToCountyStatewide(state, payload) {
      state.exciseTaxRateToCountyStatewide = payload
    },
    selectedExciseTaxRateToCountyStatewide(state, payload) {
      state.selectedExciseTaxRateToCountyStatewide = JSON.parse(JSON.stringify(payload))
    },
    selectedExciseTaxRateToCountyStatewideOriginal(state, payload) {
      state.selectedExciseTaxRateToCountyStatewideOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedExciseTaxRateToCountyStatewide(context, payload) {
      context.commit('selectedExciseTaxRateToCountyStatewide', payload)
    },
    loadExciseTaxRateToCountyStatewide(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ExciseTaxRateToCountyStatewide'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('exciseTaxRateToCountyStatewide', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedExciseTaxRateToCountyStatewide(context, payload) {
      if (context.state.selectedExciseTaxRateToCountyStatewide && context.state.selectedExciseTaxRateToCountyStatewide.ID > 0) {
        context.dispatch('loadSelectedExciseTaxRateToCountyStatewide', context.state.selectedExciseTaxRateToCountyStatewide.ID)

      }
    },
    loadSelectedExciseTaxRateToCountyStatewide(context, payload) {
      if (context.state.selectedExciseTaxRateToCountyStatewide && context.state.selectedExciseTaxRateToCountyStatewide.ID != payload) {
        context.commit('selectedExciseTaxRateToCountyStatewide', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'ExciseTaxRateToCountyStatewide/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedExciseTaxRateToCountyStatewide', res.data)
            context.commit('selectedExciseTaxRateToCountyStatewideOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadExciseTaxRateToCountyStatewide(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadExciseTaxRateToCountyStatewide')
      }, )
    },
    createExciseTaxRateToCountyStatewide(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseTaxRateToCountyStatewide`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to county created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToCountyStatewide')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchExciseTaxRateToCountyStatewide(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedExciseTaxRateToCountyStatewide
        if (payload.ID && 
        payload.ID == context.state.selectedExciseTaxRateToCountyStatewideOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedExciseTaxRateToCountyStatewideOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `ExciseTaxRateToCountyStatewide/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise tax rate to county updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadExciseTaxRateToCountyStatewide')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateExciseTaxRateToCountyStatewide(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `ExciseTaxRateToCountyStatewide/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to county updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToCountyStatewide')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveExciseTaxRateToCountyStatewide(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateExciseTaxRateToCountyStatewide', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createExciseTaxRateToCountyStatewide', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteExciseTaxRateToCountyStatewide(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseTaxRateToCountyStatewide/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rate to county deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseTaxRateToCountyStatewide')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default exciseTaxRateToCountyStatewide