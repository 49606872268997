import Vue from 'vue'

const luFindingType = {
  state: {
    luFindingType: [],
    selectedLuFindingType: {},
  },

  getters: {
    luFindingType(state) {
      return state.luFindingType
    },
    selectedLuFindingType(state) {
      return state.selectedLuFindingType
    },
  },

  mutations: {
    luFindingType(state, payload) {
      state.luFindingType = payload
    },
    selectedLuFindingType(state, payload) {
      state.selectedLuFindingType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuFindingType(context, payload) {
      context.commit('selectedLuFindingType', payload)
    },
    loadLuFindingType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuFindingType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luFindingType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuFindingType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuFindingType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuFindingType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuFindingType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuFindingType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'finding type updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuFindingType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuFindingType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuFindingType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'finding type created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuFindingType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuFindingType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuFindingType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuFindingType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuFindingType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuFindingType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'finding type deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuFindingType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luFindingType
