import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","scrollable":"","width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary text-h5 white--text"},[(!_vm.hasHeaderSlot)?_c('span',[_vm._v(" Confirm? ")]):_c('span',[_vm._t("header")],2)]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[(!_vm.hasDefaultSlot)?_c('span',[_vm._v(" Are you sure you want to delete this item? ")]):_c('span',[_vm._t("default")],2)])],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.callDelete}},[_vm._v("Delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }