import Vue from 'vue'

const error = {
  state: {
    errors: [],
    errorMessage: '',
    displayError: false,
  },

  getters: {
    errors(state) {
      return state.errors
    },
    errorMessage(state) {
      return state.errorMessage
    },
    displayError(state) {
      return state.displayError
    },
  },

  mutations: {
    errors(state, payload) {
      state.errors = payload
    },
    errorMessage(state, payload) {
      state.errorMessage = payload
    },
    displayError(state, payload) {
      state.displayError = payload
    },
    addError(state, payload) {
      state.errors.push(payload)
    },
    removeError(state, payload) {
      state.errors.splice(payload, 1)
      if (state.errors.length == 0) {
        state.displayError = false
      }
    },
    clearErrors(state, payload) {
      state.errors = []
    },
  },

  actions: {
    setErrors(context, payload) {
      context.commit('errors', payload)
    },
    setErrorMessage(context, payload) {
      context.commit('errorMessage', payload)
    },
    setDisplayError(context, payload) {
      context.commit('displayError', payload)
    },
    addError(context, payload) {
      context.commit('addError', payload)
    },
    removeError(context, payload) {
      context.commit('removeError', payload)
    },
    clearError(context) {
      context.commit('errorMessage', '')
      context.commit('displayError', false)
    },
    handleError(context, error) {
      console.error(error)
      let errorMessage = error
      context.commit('clearErrors')
      let errorData = null
      if (error.response && error.response.data) {
        context.commit('addError', error)
        errorData = error.response.data
      } else if (Array.isArray(error) || typeof error == 'string') {
        errorData = error
      }
      if (errorData === Object(errorData)) {
        let errorList = Object.values(errorData)
        if (Array.isArray(errorList)) {
          errorList.forEach((errorItem) => {
            if (Array.isArray(errorItem)) {
              errorItem.forEach((err) => {
                if (typeof err == 'string') {
                  errorMessage += '\n' + err
                  context.commit('addError', err)
                } else {
                  console.error(err)
                }
              })
            } else if (typeof errorItem == 'string') {
              errorMessage += '\n' + errorItem
              context.commit('addError', errorItem)
            } else {
              console.error(errorItem)
            }
          })
        } else if (typeof errorList == 'string') {
          errorMessage += '\n' + errorList
          context.commit('addError', errorList)
        } else {
          console.error(errorList)
        }
      } else if (Array.isArray(errorData)) {
        errorData.forEach((err) => {
          if (typeof err == 'string') {
            errorMessage += '\n' + err
            context.commit('addError', err)
          } else {
            console.error(err)
          }
        })
      } else if (typeof errorData == 'string') {
        errorMessage += '\n' + errorData
        context.commit('addError', errorData)
      } else {
        console.error(errorData)
      }
      if (context.state.errors.length > 0) {
        // or errorMessage if you are using that instead
        context.commit('errorMessage', errorMessage)
        context.commit('displayError', true)
      }
    },
  },
}

export default error
