import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const exciseExceptions = {
  state: {
    exciseExceptions: [],
    selectedExciseExceptions: {},
    selectedExciseExceptionsOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    exciseExceptions(state) {
      return state.exciseExceptions
    },
    selectedExciseExceptions(state) {
      return state.selectedExciseExceptions
    },
    selectedExciseExceptionsOriginal(state) {
      return state.selectedExciseExceptionsOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    exciseExceptions(state, payload) {
      state.exciseExceptions = payload
    },
    selectedExciseExceptions(state, payload) {
      state.selectedExciseExceptions = JSON.parse(JSON.stringify(payload))
    },
    selectedExciseExceptionsOriginal(state, payload) {
      state.selectedExciseExceptionsOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedExciseExceptions(context, payload) {
      context.commit('selectedExciseExceptions', payload)
    },
    loadExciseExceptions(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ExciseExceptions'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('exciseExceptions', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedExciseExceptions(context, payload) {
      if (context.state.selectedExciseExceptions && context.state.selectedExciseExceptions.ID > 0) {
        context.dispatch('loadSelectedExciseExceptions', context.state.selectedExciseExceptions.ID)

      }
    },
    loadSelectedExciseExceptions(context, payload) {
      if (context.state.selectedExciseExceptions && context.state.selectedExciseExceptions.ID != payload) {
        context.commit('selectedExciseExceptions', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'ExciseExceptions/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedExciseExceptions', res.data)
            context.commit('selectedExciseExceptionsOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadExciseExceptions(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadExciseExceptions')
      }, )
    },
    createExciseExceptions(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseExceptions`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise exceptions created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseExceptions')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchExciseExceptions(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedExciseExceptions
        if (payload.ID && 
        payload.ID == context.state.selectedExciseExceptionsOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedExciseExceptionsOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `ExciseExceptions/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise exceptions updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadExciseExceptions')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateExciseExceptions(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `ExciseExceptions/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise exceptions updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseExceptions')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveExciseExceptions(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateExciseExceptions', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createExciseExceptions', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteExciseExceptions(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseExceptions/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise exceptions deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseExceptions')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default exciseExceptions