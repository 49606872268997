import Vue from 'vue'

const projectDetail = {
  state: {
    projectDetail: [],
    selectedProjectDetail: {},
  },

  getters: {
    projectDetail(state) {
      return state.projectDetail
    },
    selectedProjectDetail(state) {
      return state.selectedProjectDetail
    },
  },

  mutations: {
    projectDetail(state, payload) {
      state.projectDetail = payload
    },
    selectedProjectDetail(state, payload) {
      state.selectedProjectDetail = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectDetail(context, payload) {
      context.commit('selectedProjectDetail', payload)
    },
    loadProjectDetail(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectDetail'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectDetail', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectDetail(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectDetail/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectDetail', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectDetail(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectDetail/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project detail updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectDetail')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectDetail(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectDetail`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project detail created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectDetail')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectDetail(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectDetail', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectDetail', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectDetail(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectDetail/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project detail deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectDetail')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectDetail
