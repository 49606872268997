// import Editor from '@toast-ui/editor'

export default function youtubePlugin(context, options) {
  // const { codeBlockManager } = Object.getPrototypeOf(editor).constructor
  const toHTMLRenderers = {
    youtube(node) {
      console.blue(node, context, options)
      const youtubeId = node.literal.replace(/\r\n/g, '')

      let width = 560
      let height = 315
      if (options.width) {
        height = height / (width / options.width)
        width = options.width
      }
      if (options.height) {
        width = width / (height / options.height)
        height = options.height
      }
      let attrs = node.attrs || {}
      attrs.src = `https://www.youtube.com/embed/${youtubeId}`
      attrs.width = width
      attrs.height = height
      attrs.frameborder = '0'
      attrs.allow =
        'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      attrs.allowfullscreen = true
      return [
        {
          type: 'openTag',
          tagName: 'iframe',
          outerNewLine: true,
          attributes: attrs,
        },
        {type: 'closeTag', tagName: 'iframe', outerNewLine: true},
      ]
    },
  }

  // codeBlockManager.setReplacer('youtube', function(youtubeId) {
  //   // Identify multiple code blocks
  // })
  return {toHTMLRenderers}
}

// function renderYoutube(el, youtubeId, height, width) {
//   console.blue(arguments)
//   console.cyan(el, youtubeId, height, width)
//   // const el = document.querySelector(`#${wrapperId}`)

//   el.innerHTML = `<iframe width="${width}" height="${height}" src="https://www.youtube.com/embed/${youtubeId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
// }
