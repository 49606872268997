import Vue from 'vue'

const luProjectRole = {
  state: {
    luProjectRole: [],
    selectedLuProjectRole: {},
  },

  getters: {
    luProjectRole(state) {
      return state.luProjectRole
    },
    selectedLuProjectRole(state) {
      return state.selectedLuProjectRole
    },
  },

  mutations: {
    luProjectRole(state, payload) {
      state.luProjectRole = payload
    },
    selectedLuProjectRole(state, payload) {
      state.selectedLuProjectRole = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuProjectRole(context, payload) {
      context.commit('selectedLuProjectRole', payload)
    },
    loadLuProjectRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuProjectRole'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luProjectRole', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuProjectRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuProjectRole/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuProjectRole', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuProjectRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuProjectRole/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luProjectRole updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuProjectRole')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuProjectRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuProjectRole`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luProjectRole created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuProjectRole')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuProjectRole(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuProjectRole', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuProjectRole', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuProjectRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuProjectRole/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luProjectRole deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuProjectRole')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luProjectRole
