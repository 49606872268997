import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const emailNotification = {
  state: {
    emailNotifications: [],
    selectedEmailNotification: {},
    selectedEmailNotificationOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    emailNotifications(state) {
      return state.emailNotifications
    },
    selectedEmailNotification(state) {
      return state.selectedEmailNotification
    },
    selectedEmailNotificationOriginal(state) {
      return state.selectedEmailNotificationOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    emailNotifications(state, payload) {
      state.emailNotifications = payload
    },
    selectedEmailNotification(state, payload) {
      state.selectedEmailNotification = JSON.parse(JSON.stringify(payload))
    },
    selectedEmailNotificationOriginal(state, payload) {
      state.selectedEmailNotificationOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedEmailNotification(context, payload) {
      context.commit('selectedEmailNotification', payload)
    },
    async loadEmailNotifications(context, payload) {
      const url = 'EmailNotification'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('emailNotifications', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedEmailNotification(context, payload) {
      if (
        context.state.selectedEmailNotification &&
        context.state.selectedEmailNotification.Id >  0 
      ) {
        return await context.dispatch(
          'loadSelectedEmailNotification',
          context.state.selectedEmailNotification.Id
        )
      }
    },
    async loadSelectedEmailNotification(context, payload) {
      if (
        context.state.selectedEmailNotification &&
        context.state.selectedEmailNotification.Id != payload
      ) {
        context.commit('selectedEmailNotification', {})
      }
      const url = 'EmailNotification/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedEmailNotification', res.data)
        context.commit('selectedEmailNotificationOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadEmailNotifications(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadEmailNotifications')
    },
    async createEmailNotification(context, payload) {
      const url = `EmailNotification`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'email notification created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadEmailNotifications')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchEmailNotification(context, payload) {
      var item = context.state.selectedEmailNotification
      if (
        payload.Id &&
        payload.Id == context.state.selectedEmailNotificationOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedEmailNotificationOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `EmailNotification/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'email notification updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadEmailNotifications')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateEmailNotification(context, payload) {
      const url = `EmailNotification/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'email notification updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadEmailNotifications')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveEmailNotification(context, payload) {
      if (payload.Id >  0 ) {
        return context.dispatch('updateEmailNotification', payload)
      } else {
        return context.dispatch('createEmailNotification', payload)
      }
    },
    async deleteEmailNotification(context, payload) {
      const url = `EmailNotification/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'email notification deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadEmailNotifications')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default emailNotification
