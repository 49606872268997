import Vue from 'vue'

const luCOGEntityType = {
  state: {
    luCOGEntityType: [],
    selectedLuCOGEntityType: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luCOGEntityType(state) {
      return state.luCOGEntityType
    },
    selectedLuCOGEntityType(state) {
      return state.selectedLuCOGEntityType
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luCOGEntityType(state, payload) {
      state.luCOGEntityType = payload
    },
    selectedLuCOGEntityType(state, payload) {
      state.selectedLuCOGEntityType = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuCOGEntityType(context, payload) {
      context.commit('selectedLuCOGEntityType', payload)
    },
    loadLuCOGEntityType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuCOGEntityType'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luCOGEntityType', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    refreshSelectedLuCOGEntityType(context, payload) {
      if (
        context.state.selectedLuCOGEntityType &&
        context.state.selectedLuCOGEntityType.ID
      ) {
        context.dispatch(
          'loadSelectedLuCOGEntityType',
          context.state.selectedLuCOGEntityType.ID
        )
      }
    },
    loadSelectedLuCOGEntityType(context, payload) {
      if (
        context.state.selectedLuCOGEntityType &&
        context.state.selectedLuCOGEntityType.ID != payload
      ) {
        context.commit('selected', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'LuCOGEntityType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuCOGEntityType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    clearLookupCacheAndReloadLuCOGEntityType(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
        context.dispatch('loadLuCOGEntityType')
      })
    },
    updateLuCOGEntityType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuCOGEntityType/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Lu C O G Entity Type updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCOGEntityType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuCOGEntityType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuCOGEntityType`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Lu C O G Entity Type created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCOGEntityType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuCOGEntityType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuCOGEntityType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuCOGEntityType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuCOGEntityType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuCOGEntityType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Lu C O G Entity Type deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCOGEntityType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luCOGEntityType
