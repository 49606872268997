<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{on}">
      <v-row no-gutters>
        <v-col sm="12">
          <v-text-field
            return-masked-value
            v-mask="'##/##/####'"
            :value="dateFormatted"
            :label="label"
            v-bind="$attrs"
            @change="dateFormatted = $event"
            :rules="validationRules"
            :disabled="disabled"
            :clearable="clearable"
          >
            <template v-slot:prepend-inner>
              <v-icon v-on="on">event</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      v-model="dateIso"
      no-title
      @input="menu1 = false"
      :min="min"
      :max="max"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import {mask} from 'vue-the-mask'
import dayjs from 'dayjs'
export default {
  model: {
    prop: 'date',
    event: 'change',
  },
  directives: {
    mask,
  },
  props: {
    date: [String, Date],
    label: String,
    previousDatesOnly: false,
    disabled: Boolean,
    rules: Array,
    max: {
      type: String,
      default: '9999-12-31',
    },
    min: {
      type: String,
      default: '1753-01-01',
    },
    maxInvalid: {
      type: String,
      default: 'Date is after maximum date allowed',
    },
    minInvalid: {
      type: String,
      default: 'Date is before minimum date allowed',
    },
    clearable: Boolean,
  },
  data() {
    return {
      menu1: false,
    }
  },
  computed: {
    dateFormatted: {
      get() {
        if (this.date) {
          let date = dayjs(this.date).format('MM/DD/YYYY')
          return date
        } else {
          return null
        }
      },
      set(newValue) {
        if (dayjs(newValue).isValid()) {
          this.$emit(
            'change',
            dayjs(newValue).toDate().toISOString().substr(0, 10)
          )
        } else if (
          typeof this.clearable != 'undefined' &&
          this.clearable != false
        ) {
          this.$emit('change', null)
        }
      },
    },
    dateIso: {
      get() {
        if (this.date) {
          let date = dayjs(this.date).toDate().toISOString().substr(0, 10)
          return date
        } else {
          return null
        }
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
    validationRules() {
      let rules = this.rules || []
      rules.push(
        (val) => !val || dayjs(val).isValid() || 'Please enter a valid date',
        (val) => !val || dayjs(val) >= dayjs(this.min) || this.minInvalid,
        (val) => !val || dayjs(val) <= dayjs(this.max) || this.maxInvalid
      )
      return rules
    },
  },
}
</script>

<style>
</style>