<template>
  <div>
    <v-dialog persistent v-model="dialog" scrollable width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          <span v-if="!hasHeaderSlot"> Confirm? </span>
          <span v-else>
            <slot name="header"></slot>
          </span>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12"
              ><span v-if="!hasDefaultSlot">
                Are you sure you want to delete this item?
              </span>
              <span v-else>
                <slot />
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = !dialog">Cancel</v-btn>
          <v-btn @click="callDelete" outlined color="primary">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    delete: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: {},
      dialog: false,
    }
  },
  created() {},
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasHeaderSlot() {
      return !!this.$slots['header']
    },
  },
  methods: {
    deleteConfirm(item) {
      this.selected = item
      this.dialog = true
    },
    callDelete() {
      this.delete(this.selected).then(
        (res) => {
          this.$emit('refresh')
          this.dialog = false
        },
        (err) => {
          this.$emit('error', err)
        }
      )
    },
  },
}
</script>
<style scoped>
</style>