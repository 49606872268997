import Vue from 'vue'
import {debounce, range} from 'lodash'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const searchSortByDefault = 'ID'
const exciseStratum = {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: searchSortByDefault,
    searchDescending: true,
    searchRowsPerPage: 25,
    searchText: '',
    searchProjectIds: [],
    searchStratumStatus: [],
    lookupText: '',
    //templateTODO: uncomment as used in ExciseStratumSearchParams
    // searchProjectID: [],
    // searchShowInactive: false,
    // searchIsActive: [],
    // searchIsAppended: [],
  },

  getters: {
    searchParameters(state) {
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection: state.searchDescending ? 'desc' : 'asc',
        searchText: state.searchText,
        projectIds: state.searchProjectIds,
        //templateTODO: uncomment as used in ExciseStratumSearchParams
        //projectID: state.searchProjectID,
        // showInactive: state.searchShowInactive,
        //isActive: state.searchIsActive,
        //isAppended: state.searchIsAppended,
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    searchProjectIds(state, payload) {
      state.searchProjectIds = payload
    },
    searchStratumStatus(state, payload) {
      state.searchStratumStatus = payload
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    //TemplateTODO: uncomment as needed
    //searchProjectID(state, payload) {
    //  state.searchProjectID = payload
    //},
    //searchShowInactive(state, payload) {
    //  state.searchShowInactive = payload
    //},
    //searchIsActive(state, payload) {
    //  state.searchIsActive = payload
    //},
    //searchIsAppended(state, payload) {
    //  state.searchIsAppended = payload
    //},
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupExciseStratum')
    }, 300),
    searchBounce: _.debounce((context) => {
      context.dispatch('searchExciseStratum')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (payload != context.state.searchSortBy) {
        context.commit('searchSortBy', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (payload != context.state.searchDescending) {
        context.commit('searchDescending', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchProjectIds(context, payload) {
      if (payload != context.state.searchProjectIds) {
        context.commit('searchProjectIds', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    setSearchStratumStatus(context, payload) {
      if (payload != context.state.searchStratumStatus) {
        context.commit('searchStratumStatus', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText && payload != null) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    //setSearchProjectID(context, payload) {
    //  if (payload != context.state.searchProjectID) {
    //    context.commit('searchProjectID', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    //setSearchShowInactive(context, payload) {
    //  if (payload != context.state.searchShowInactive) {
    //    context.commit('searchShowInactive', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    //setSearchIsActive(context, payload) {
    //  if (payload != context.state.searchIsActive) {
    //    context.commit('searchIsActive', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    //setSearchIsAppended(context, payload) {
    //  if (payload != context.state.searchIsAppended) {
    //    context.commit('searchIsAppended', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    searchExciseStratum(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
          context.commit('searchSortBy', searchSortByDefault)
          context.commit('searchDescending', true)
        }
        if (Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy.length > 0) {
            context.commit('searchSortBy', context.state.searchSortBy[0])
            context.commit(
              'searchDescending',
              context.state.searchDescending[0]
            )
          } else {
            context.commit('searchSortBy', searchSortByDefault)
            context.commit('searchDescending', true)
          }
        }
        if (!context.state.searchText) {
          context.commit('searchText', '')
        }
        context.commit('increaseSearchCount')
        var params = context.getters.searchParameters
        var paramString = JSON.stringify(params)
        const url = `ExciseStratum/Search`
        Vue.prototype.$axios.post(url, params).then(
          (res) => {
            if (
              JSON.stringify(context.getters.searchParameters) == paramString
            ) {
              context.commit('exciseStratum', res.data.Entries)
              context.commit('searchRowsPerPage', res.data.Page.Size)
              context.commit('searchPage', res.data.Page.Number + 1)
              context.commit('searchTotal', res.data.Page.TotalElements)
            }
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    lookupExciseStratum(context, payload) {
      if (context.state.lookupText) {
        return new Promise((resolve, reject) => {
          context.commit('increaseSearchCount')
          const url = `ExciseStratum/Lookup?search=${JSON.stringify({
            searchText: context.state.lookupText,
            projectIds: context.state.searchProjectIds,
            stratumStatus: context.state.searchStratumStatus,
          })}`
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit('exciseStratum', res.data)
              context.commit('decreaseSearchCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.dispatch('errors/handleError', err, {root: true})
              context.commit('decreaseSearchCount')
              reject(err)
            }
          )
        })
      }
    },
  },
}

export default exciseStratum
