<template>
  <v-tooltip :left="left" :top="top" :bottom="bottom" :right="right">
    <template v-slot:activator="{on}">
      <v-btn
        :class="buttonClass"
        :color="iconColor"
        v-bind="$attrs"
        v-on="on"
        @click="handleClick"
        :icon="iconButton"
      >
        {{ buttonLeftText }}
        <v-icon
          :right="!!buttonLeftText"
          :left="!!buttonRightText"
          :dark="dark"
          >{{ icon }}</v-icon
        >
        {{ buttonRightText }}
      </v-btn>
    </template>
    <slot></slot>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    iconButton: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'mdi-circle-medium',
    },
    iconColor: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    buttonLeftText: {
      type: String,
      default: '',
    },
    buttonRightText: {
      type: String,
      default: '',
    },
  },
  components: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    left() {
      return this.position == 'left'
    },
    right() {
      return this.position == 'right'
    },
    top() {
      return this.position == 'top'
    },
    bottom() {
      return this.position == 'bottom'
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>