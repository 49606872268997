import Vue from 'vue'

const savedQueries = {
  state: {
    savedQueries: [],
    selectedSavedQueries: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    savedQueries(state) {
      return state.savedQueries
    },
    selectedSavedQueries(state) {
      return state.selectedSavedQueries
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    savedQueries(state, payload) {
      state.savedQueries = payload
    },
    selectedSavedQueries(state, payload) {
      state.selectedSavedQueries = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedSavedQueries(context, payload) {
      context.commit('selectedSavedQueries', payload)
    },
    loadSavedQueries(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SavedQueries'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('savedQueries', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    refreshSelectedSavedQueries(context, payload) {
      if (
        context.state.selectedSavedQueries &&
        context.state.selectedSavedQueries.ID
      ) {
        context.dispatch(
          'loadSelectedSavedQueries',
          context.state.selectedSavedQueries.ID
        )
      }
    },
    loadSelectedSavedQueries(context, payload) {
      if (
        context.state.selectedSavedQueries &&
        context.state.selectedSavedQueries.ID != payload
      ) {
        context.commit('selected', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'SavedQueries/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedSavedQueries', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    clearLookupCacheAndReloadSavedQueries(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
        context.dispatch('loadSavedQueries')
      })
    },
    updateSavedQueries(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SavedQueries/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Saved Queries updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSavedQueries')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createSavedQueries(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SavedQueries`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Saved Queries created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSavedQueries')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveSavedQueries(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateSavedQueries', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createSavedQueries', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteSavedQueries(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SavedQueries/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Saved Queries deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSavedQueries')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default savedQueries
