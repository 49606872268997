<!-- BODY LAYOUT -->
<template>
  <div>
    <!-- <v-app> -->
    <ErrorDisplay></ErrorDisplay>
    <ToastDisplay></ToastDisplay>
    <MessageDialog></MessageDialog>
    <NavBar />
    <slot />
    <!-- </v-app> -->
  </div>
</template>


<!-- SCRIPTS -->
<script>
import NavBar from '@components/nav-bar'
import ErrorDisplay from '@components/error-display'
import ToastDisplay from '@components/toast-display'
import MessageDialog from '@components/message-dialog'

export default {
  components: {NavBar, ErrorDisplay, ToastDisplay, MessageDialog},
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>



