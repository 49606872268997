<!-- NAV LAYOUT -->
<template>
  <div>
    <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini">
      <v-list dense>
        <v-list-item class="px-2">
          <v-list-item-avatar tile>
            <v-img src="../assets/doa_logo.png" title="Expand Toolbar"></v-img>
          </v-list-item-avatar>
          <!-- <v-btn icon small>
              <v-icon title="Expand Toolbar">mdi-menu</v-icon>
          </v-btn>-->
          <v-list-item-content class="noHover">
            <v-list-item-title class="text-uppercase">Menu</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn icon @click.stop="mini = !mini" small>
              <v-icon title="Condense Toolbar">mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="(item, key) in routes" :key="key">
          <v-list-item v-if="item.route" @click.stop :to="item.name" link>
            <v-list-item-icon>
              <v-tooltip v-if="mini" right>
                <template v-slot:activator="{on}">
                  <v-badge
                    :value="!!item.badge"
                    bordered
                    overlap
                    :color="!!item.badgeColor ? item.badgeColor : item.color"
                    :content="item.badge"
                  >
                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                  </v-badge>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
              <span v-else>
                <v-badge
                  :value="!!item.badge"
                  bordered
                  :color="!!item.badgeColor ? item.badgeColor : item.color"
                  overlap
                  :content="item.badge"
                >
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-badge>
              </span>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <!-- List items with sub menu -->
          <v-list-group v-else :title="item.title" no-action>
            <template v-slot:prependIcon>
              <v-badge
                :value="!!item.badge"
                bordered
                :color="!!item.badgeColor ? item.badgeColor : item.color"
                overlap
                :content="item.badge"
              >
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-badge>
            </template>
            <template v-slot:activator>
              <!-- <v-list-item-content> -->
              <v-list-item-title> {{ item.title }}</v-list-item-title>
              <!-- </v-list-item-content> -->
            </template>
            <v-list-item
              class="pl-8"
              v-for="sub in item.subroutes"
              :key="sub.title"
              :to="sub.name"
              link
            >
              <v-list-item-icon>
                <v-icon :color="sub.color" :title="sub.title">{{
                  sub.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pl-0">{{
                sub.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dark class="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <!-- <v-icon  title="Show Toolbar">mdi-chevron-right</v-icon> -->
        <v-avatar v-if="!drawer" tile>
          <img src="../assets/doa_logo_white.png" title="Expand toolbar" />
        </v-avatar>
        <v-icon v-if="drawer" title="Hide Toolbar">mdi-chevron-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="headline text-uppercase">
        <span>DOA</span>
        <span class="font-weight-light">
          Time
          <span v-if="appInfo" class="caption"
            >&nbsp;Version {{ version }}</span
          >
        </span>
        <v-chip class="ml-3 mb-1" :color="envColor" v-if="environment">{{
          environment
        }}</v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <GoogleSignin></GoogleSignin>
      <BaseTooltipButton
        class="justify-end"
        iconColor="white"
        dark
        :icon="
          $vuetify.theme.dark
            ? 'mdi-white-balance-sunny'
            : 'mdi-moon-waxing-crescent'
        "
        @click="switchTheme"
        >{{
          $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode'
        }}</BaseTooltipButton
      >
      <feedback
        v-if="isDevelopment"
        class="justify-end"
        iconColor="white"
        dark
      ></feedback>
    </v-app-bar>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import GoogleSignin from '@components/google-signin'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
import feedback from '@components/feedback'

export default {
  data() {
    return {
      drawer: null,
      mini: true,
      installBtn: 'none',
      installer: undefined,
      appInfo: {},
      version: VUE_APP_VERSION,
    }
  },
  components: {GoogleSignin, feedback},
  created() {
    // this.getAppInfo()
    let installPrompt

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      installPrompt = e
      this.installBtn = 'inline-block'
    })

    this.installer = () => {
      this.installBtn = 'none'
      installPrompt.prompt()
      installPrompt.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.info('Install accepted!')
        } else {
          console.info('Install denied!')
        }
      })
    }
  },
  methods: {
    ...authMethods,
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('darkMode', this.$vuetify.theme.dark)
    },
  },
  watch: {
    authError(nval, oval) {
      if (!!nval) {
        this.displayError = true
      }
    },
    displayError(nval, oval) {
      if (!nval) {
        this.clearError()
      }
    },
    isLoggedIn(nval, oval) {
      if (nval) {
        if (this.$route.query != {} && this.$route.query.redirectFrom) {
          this.$router.push(this.$route.query.redirectFrom)
        }
      } else {
        if (this.$router.history.current.name != 'login') {
          this.$router.push({
            name: 'login',
            query: {
              redirectFrom: this.$router.history.current.path,
            },
          })
        }
      }
    },
  },
  computed: {
    ...authComputed,
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    environment() {
      let location = window.location.host
      if (location.includes('dev.') || location.includes('localhost')) {
        return 'Dev'
      } else if (location.includes('test.')) {
        return 'Test'
      } else if (location.includes('stage.')) {
        return 'Stage'
      } else {
        return null
      }
    },
    envColor() {
      switch (this.environment) {
        case 'Dev':
          return 'red'
        case 'Test':
          return 'orange'
        case 'Stage':
          return 'yellow'
      }
    },
    routes() {
      if (this.isLoggedIn) {
        let routes = [
          {
            route: true,
            name: '/',
            title: 'Home',
            icon: 'home',
            subroutes: [],
          },
          {
            route: true,
            name: '/userprojects',
            title: 'My Projects',
            icon: 'mdi-calendar-edit',
            subroutes: [],
          },
          // {
          //   route: true,
          //   name: '/users',
          //   title: 'Users',
          //   icon: 'mdi-account',
          //   subroutes: [],
          // },
          {
            route: true,
            name: '/entities',
            title: 'Entities/Projects',
            icon: 'mdi-text-box-multiple',
            subroutes: [],
          },
          {
            route: false,
            title: 'Timesheets',
            icon: 'mdi-calendar-clock',
            subroutes: [
              {
                name: '/timesheetsEntry',
                title: 'Entry',
                icon: 'mdi-account-edit',
              },
              {
                name: '/timesheetsApproval',
                title: 'Approval',
                icon: 'mdi-account-check',
              },
            ],
          },
          {
            route: false,
            title: 'Reports',
            icon: 'mdi-file-chart',
            subroutes: [
              {
                name: '/timesheetReports',
                title: 'Timesheet',
                icon: 'mdi-calendar-clock',
              },
            ],
          },
        ]
        if (this.currentUser.DivisionID == 4) {
          let reports = routes.find((r) => {
            return r.title == 'Reports'
          })
          if (reports) {
            reports.subroutes.unshift({
              name: '/financialReports',
              title: 'Financial',
              icon: 'mdi-file-table',
            })
            reports.subroutes.unshift({
              name: '/EntityContactReport',
              title: 'Entity Contact',
              icon: 'mdi-file-account',
            })
            reports.subroutes.unshift({
              name: '/pfentityReport',
              title: 'Entity',
              icon: 'mdi-file-powerpoint',
            })
          }
        }
        if (this.currentUser.DivisionID == 4 && this.isProjectCreator) {
          routes.push({
            route: true,
            name: '/pfAdmin',
            title: 'Public Funds Admin',
            icon: 'settings',
          })
        }
        if (this.currentUser.DivisionID == 6) {
          routes.push({
            route: true,
            name: '/exciseReturnSearch',
            title: 'Excise Return Search',
            icon: 'mdi-text-box-search-outline',
          })
        }
        if (this.currentUser.DivisionID == 6 && this.isProjectCreator) {
          routes.push({
            route: true,
            name: '/exciseAdmin',
            title: 'Excise Admin',
            icon: 'settings',
          })
        }
        if (this.isSuperUser) {
          let timesheets = routes.find((r) => {
            return r.title == 'Timesheets'
          })
          if (timesheets) {
            timesheets.subroutes.push({
              name: '/timeSheetModify',
              title: 'Modify',
              icon: 'mdi-account-multiple',
            })
          }
          routes.push({
            route: true,
            name: '/admin',
            title: 'System Admin',
            icon: 'settings',
          })
        }
        if (
          (this.isSuperUser || this.isSupervisor || this.isProjectCreator) &&
          this.currentUser.DivisionID == 6
        ) {
          let reports = routes.find((r) => {
            return r.title == 'Reports'
          })
          if (reports) {
            reports.subroutes.push({
              name: '/exciseExceptionReport',
              title: 'Excise Exception Report',
              icon: 'mdi-file-percent',
            })
            reports.subroutes.push({
              name: '/dorInterfaceReport',
              title: 'DOR Interface Report',
              icon: 'mdi-file-arrow-up-down',
            })
          }
        }
        if (
          (this.isSuperUser || this.isSupervisor || this.isProjectCreator) &&
          this.currentUser.DivisionID != 4
        ) {
          let reports = routes.find((r) => {
            return r.title == 'Reports'
          })
          if (reports) {
            reports.subroutes.push({
              name: '/EntityContactReport',
              title: 'Entity Contact',
              icon: 'mdi-file-account',
            })
          }
        }
        if (this.isSuperUser || this.isSupervisor || this.isProjectCreator) {
          let reports = routes.find((r) => {
            return r.title == 'Reports'
          })
          if (reports) {
            reports.subroutes.push(
              {
                name: '/projectReports',
                title: 'Project',
                icon: 'mdi-file-document-edit',
              },
              {
                name: '/cannedReports',
                title: 'Canned',
                icon: 'mdi-file-chart',
              }
            )
          }
        }

        return routes
      } else
        return [
          {
            route: true,
            name: '/',
            title: 'Home',
            icon: 'home',
            subroutes: [],
          },
          // {
          //   route: true,
          //   name: '/contact',
          //   title: 'Contact',
          //   icon: 'mail',
          //   subroutes: [],
          // },
        ]
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
.v-app-bar.v-app-bar--fixed {
  z-index: 50;
}
// @import '@design';
</style>
