import Vue from 'vue'
import { debounce, range } from 'lodash'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
  const searchSortByDefault = 'ID'
const user= {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: searchSortByDefault,
    searchDescending: true,
    searchRowsPerPage: 25,
    searchText: '',
    searchDivision: [],
    lookupText: '',
    //templateTODO: uncomment as used in UserSearchParams
    // searchIsDeleted: [],
  },

  getters: {
    searchParameters(state) {
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection: state.searchDescending ? 'desc' : 'asc',
        searchText: state.searchText,
        searchDivision: state.searchDivision,
        //templateTODO: uncomment as used in UserSearchParams
            //isDeleted: state.searchIsDeleted,
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    searchDivision(state, payload) {
      state.searchDivision = payload
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    //TemplateTODO: uncomment as needed
    //searchIsDeleted(state, payload) {
    //  state.searchIsDeleted = payload
    //},
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupUser')
    }, 300),
    searchBounce: _.debounce((context) => {
      context.dispatch('searchUser')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (payload != context.state.searchSortBy) {
        context.commit('searchSortBy', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (payload != context.state.searchDescending) {
        context.commit('searchDescending', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText && payload != null) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    //setSearchIsDeleted(context, payload) {
    //  if (payload != context.state.searchIsDeleted) {
    //    context.commit('searchIsDeleted', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    searchUser(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
            context.commit('searchSortBy', searchSortByDefault)
            context.commit('searchDescending', true)
        }
        if (Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy.length > 0) {
              context.commit('searchSortBy', context.state.searchSortBy[0])
              context.commit(
              'searchDescending',
              context.state.searchDescending[0]
            )
          } else {
              context.commit('searchSortBy', searchSortByDefault)
              context.commit('searchDescending', true)
          }
        }
        if (!context.state.searchText) {
            context.commit('searchText', '')
        }
        context.commit('increaseSearchCount')
        var params = context.getters.searchParameters
        var paramString = JSON.stringify(params)
        const url = `User/Search`
        Vue.prototype.$axios.post(url, params).then(
          (res) => {
            if (JSON.stringify(context.getters.searchParameters) == paramString) {
            context.commit('user', res.data.Entries)
            context.commit('searchRowsPerPage', res.data.Page.Size)
            context.commit('searchPage', res.data.Page.Number + 1)
            context.commit('searchTotal', res.data.Page.TotalElements)
            }
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    lookupUser(context, payload) {
      if (context.state.lookupText) {
        return new Promise((resolve, reject) => {
          context.commit('increaseSearchCount')
          const url = `User/Lookup?search=${JSON.stringify({ searchText: context.state.lookupText, division: context.state.searchDivision})}`
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit('user', res.data)
              context.commit('decreaseSearchCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.dispatch('errors/handleError', err, { root: true })
              context.commit('decreaseSearchCount')
              reject(err)
            }
          )
        })
      }
    },
  },
}

export default
user