import Vue from 'vue'

const auditSubEntity = {
  state: {
    auditSubEntity: [],
    selectedAuditSubEntity: {},
  },

  getters: {
    auditSubEntity(state) {
      return state.auditSubEntity
    },
    selectedAuditSubEntity(state) {
      return state.selectedAuditSubEntity
    },
  },

  mutations: {
    auditSubEntity(state, payload) {
      state.auditSubEntity = payload
    },
    selectedAuditSubEntity(state, payload) {
      state.selectedAuditSubEntity = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedAuditSubEntity(context, payload) {
      context.commit('selectedAuditSubEntity', payload)
    },
    loadAuditSubEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'AuditSubEntity'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('auditSubEntity', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedAuditSubEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'AuditSubEntity/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedAuditSubEntity', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateAuditSubEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `AuditSubEntity/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'sub entity updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadAuditSubEntity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createAuditSubEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `AuditSubEntity`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'sub entity created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadAuditSubEntity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveAuditSubEntity(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateAuditSubEntity', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createAuditSubEntity', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteAuditSubEntity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `AuditSubEntity/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'sub entity deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadAuditSubEntity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default auditSubEntity
