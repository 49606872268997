import Vue from 'vue'

const luReviewType = {
  state: {
    luReviewType: [],
    selectedLuReviewType: {},
  },

  getters: {
    luReviewType(state) {
      return state.luReviewType
    },
    selectedLuReviewType(state) {
      return state.selectedLuReviewType
    },
  },

  mutations: {
    luReviewType(state, payload) {
      state.luReviewType = payload
    },
    selectedLuReviewType(state, payload) {
      state.selectedLuReviewType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuReviewType(context, payload) {
      context.commit('selectedLuReviewType', payload)
    },
    loadLuReviewType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuReviewType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luReviewType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuReviewType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuReviewType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuReviewType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuReviewType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuReviewType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luReviewType updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuReviewType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuReviewType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuReviewType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luReviewType created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuReviewType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuReviewType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuReviewType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuReviewType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuReviewType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuReviewType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luReviewType deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuReviewType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luReviewType
