import Vue from 'vue'

const projectBanking = {
  state: {
    projectBanking: [],
    selectedProjectBanking: {},
  },

  getters: {
    projectBanking(state) {
      return state.projectBanking
    },
    selectedProjectBanking(state) {
      return state.selectedProjectBanking
    },
  },

  mutations: {
    projectBanking(state, payload) {
      state.projectBanking = payload
    },
    selectedProjectBanking(state, payload) {
      state.selectedProjectBanking = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectBanking(context, payload) {
      context.commit('selectedProjectBanking', payload)
    },
    loadProjectBanking(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectBanking'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectBanking', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectBanking(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectBanking/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectBanking', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectBanking(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectBanking/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectBanking updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectBanking')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectBanking(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectBanking`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectBanking created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectBanking')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectBanking(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectBanking', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectBanking', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectBanking(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectBanking/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectBanking deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectBanking')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectBanking
