import Vue from 'vue'

const luFinancialActivity = {
  state: {
    luFinancialActivity: [],
    selectedLuFinancialActivity: {},
  },

  getters: {
    luFinancialActivity(state) {
      return state.luFinancialActivity
    },
    selectedLuFinancialActivity(state) {
      return state.selectedLuFinancialActivity
    },
  },

  mutations: {
    luFinancialActivity(state, payload) {
      state.luFinancialActivity = payload
    },
    selectedLuFinancialActivity(state, payload) {
      state.selectedLuFinancialActivity = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuFinancialActivity(context, payload) {
      context.commit('selectedLuFinancialActivity', payload)
    },
    loadLuFinancialActivity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'luFinancialActivity'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            let cpaActivities = [2, 12, 13, 14, 18, 19]
            let data = res.data.map((item) => {
              return {
                ...item,
                cpaActivity: cpaActivities.includes(item.FinancialActivityID),
              }
            })
            context.commit('luFinancialActivity', data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuFinancialActivity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'luFinancialActivity/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            let cpaActivities = [2, 12, 13, 14, 18, 19]
            let data = res.data.map((item) => {
              return {
                ...item,
                cpaActivity: cpaActivities.includes(item.FinancialActivityID),
              }
            })
            context.commit('selectedLuFinancialActivity', data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuFinancialActivity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `luFinancialActivity/${payload.FinancialActivityID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luFinancialActivity updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuFinancialActivity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuFinancialActivity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `luFinancialActivity`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luFinancialActivity created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuFinancialActivity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuFinancialActivity(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.FinancialActivityID > 0) {
          context.dispatch('updateLuFinancialActivity', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuFinancialActivity', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuFinancialActivity(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `luFinancialActivity/${payload.FinancialActivityID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luFinancialActivity deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuFinancialActivity')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luFinancialActivity
