import Vue from 'vue'

const luState = {
  state: {
    luState: [],
    selectedLuState: {},
  },

  getters: {
    luState(state) {
      return state.luState
    },
    selectedLuState(state) {
      return state.selectedLuState
    },
  },

  mutations: {
    luState(state, payload) {
      state.luState = payload
    },
    selectedLuState(state, payload) {
      state.selectedLuState = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuState(context, payload) {
      context.commit('selectedLuState', payload)
    },
    loadLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuState'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luState', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuState/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuState', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuState/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'state updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuState')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuState`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'state created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuState')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuState(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuState', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuState', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuState/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'state deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuState')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luState
