import store from '../state/store'

export default [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('./views/home')),
  },
  // {
  //   path: '/users',
  //   name: 'users',
  //   meta: {
  //     authRequired: true,
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => lazyLoadView(import('./views/users')),
  // },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      authRequired: true,
      roles: ['SuperUser'],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/admin')),
  },
  {
    path: '/userprojects',
    name: 'userprojects',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/userProjects')),
  },
  {
    path: '/entities',
    name: 'entities',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/entities')),
  },
  {
    path: '/entity/:id?',
    name: 'entity',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/entity')),
  },
  {
    path: '/subEntity/:entityId/:subEntityId?',
    name: 'subEntity',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/subEntity')),
  },
  {
    path: '/project/:subEntityId/:projectId?',
    name: 'project',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/project')),
  },
  {
    path: '/exceptionDatasheet/:projectId?',
    name: 'exceptionDatasheet',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/exceptionDatasheet'),
    children: [
      {
        name: 'exceptionEdit',
        path: 'edit',
        component: () =>
          lazyLoadView(import('../components/grid/ExciseExceptionsEdit')),
      },
      {
        name: 'exceptionAdd',
        path: 'add',
        component: () =>
          lazyLoadView(import('../components/grid/ExciseExceptionsAdd')),
      },
      {
        name: 'exceptionDuplicates',
        path: 'duplicates',
        component: () =>
          lazyLoadView(import('../components/grid/ExciseExceptionsGrid')),
      },
    ],
  },
  {
    path: '/timesheetsEntry',
    name: 'timesheetsEntry',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/timesheetsEntry'),
  },
  {
    path: '/timeSheetModify',
    name: 'timeSheetModify',
    meta: {
      authRequired: true,
      roles: ['SuperUser'],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/timeSheetModify'),
  },
  {
    path: '/timesheetsApproval',
    name: 'timesheetsApproval',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/timesheetsApproval')),
  },
  {
    path: '/timesheetApprove/:id?',
    name: 'timesheetApprove',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/timesheetApprove')),
  },
  {
    path: '/timesheetSubmit/:id?',
    name: 'timesheetSubmit',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/timesheetSubmit')),
  },
  {
    path: '/cannedReports',
    name: 'cannedReports',
    meta: {
      authRequired: true,
      roles: ['SuperUser', 'Supervisor', 'Project Creator'],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/cannedReports')),
  },
  {
    path: '/projectReports',
    name: 'projectReports',
    meta: {
      authRequired: true,
      roles: ['SuperUser', 'Supervisor', 'Project Creator'],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/projectReports')),
  },
  {
    path: '/timesheetReports',
    name: 'timesheetReports',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/timesheetReports')),
  },
  {
    path: '/financialReports',
    name: 'financialReports',
    meta: {
      authRequired: true,
      division: [4],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/financialReports')),
  },
  {
    path: '/pfentityReport',
    name: 'pfentityReport',
    meta: {
      authRequired: true,
      division: [4],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/PFEntityReport')),
  },
  {
    path: '/pfAdmin',
    name: 'pfAdmin',
    meta: {
      authRequired: true,
      roles: ['Project Creator'],
      division: [4],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/pfAdmin')),
  },
  {
    path: '/exciseAdmin',
    name: 'exciseAdmin',
    meta: {
      authRequired: true,
      roles: ['Project Creator'],
      division: [6],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/exciseAdmin')),
  },
  {
    path: '/exciseReturnSearch',
    name: 'exciseReturnSearch',
    meta: {
      authRequired: true,
      division: [6],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/exciseReturnSearch')),
  },
  {
    path: '/exciseExceptionReport',
    name: 'exciseExceptionReport',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/exciseExceptionReport')),
  },
  {
    path: '/dorInterfaceReport',
    name: 'dorInterfaceReport',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/dorInterfaceReport')),
  },
  {
    path: '/entityContactReport',
    name: 'entityContactReport',
    meta: {
      authRequired: true,
      // division: [4],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/EntityContactReport')),
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/contact')),
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/test')),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      //authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: 'home',
              }
            : {
                ...routeFrom,
              }
        )
      },
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('./views/google-login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/isLoggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('./views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('./views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    // loading: require('./views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('./views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, {data, children}) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
