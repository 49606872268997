import Vue from 'vue'
import {debounce, range} from 'lodash'
import download from 'downloadjs'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const adhocSortByDefault = 'AuditReturnID'
const dorAuditReturnTransaction = {
  state: {
    adhocDOR_AuditReturnTransactions: [],
    adhocTotal: 0,
    adhocPage: 1,
    adhocSortBy: [adhocSortByDefault],
    adhocDescending: [true],
    adhocGroupBy: null,
    adhocGroupDescending: null,
    adhocRowsPerPage: 25,
    adhocSearchCount: 0,
    adhocPivotIncludePivot: false,
    adhocPivotFirst: true,
    adhocPivotName: '',
    adhocPivotRowFields: [],
    adhocPivotColumnFields: [],
    adhocPivotExcludeFields: [],
    adhocPivotDataFields: [],
    adhocPivotFilterFields: [],
    adhocSelectedFields: [],
    adhocQueryIsModified: false,
    adhocQueryName: '',
    adhocText: '',
    // adhocStartDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    // adhocEndDate: dayjs().format('YYYY-MM-DD'),
    //templateTODO: uncomment as used in AdHocDOR_AuditReturnTransactionReportParams
    // adhocAuditReturnID: [],
    // adhocRegistrantID: [],
    // adhocLicenseNumber: [],
    // adhocProjectNumber: [],
    // adhocAuditStatus: [],
    // adhocAmendedReturn: [],
    // adhocReportFileLocation: [],
    // adhocRetrieved: [],
  },

  getters: {
    adhocParameters(state, getters, rootState, rootGetters) {
      var sortDirection
      if (Array.isArray(state.adhocDescending)) {
        sortDirection = state.adhocDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.adhocDescending ? ['desc'] : ['asc']
      }
      var groupByDirection
      if (Array.isArray(state.adhocGroupDescending)) {
        if (state.adhocGroupDescending.length > 0) {
          groupByDirection = state.adhocGroupDescending[0] ? 'desc' : 'asc'
        } else {
          groupByDirection = 'asc'
        }
      } else {
        groupByDirection = state.adhocGroupDescending ? 'desc' : 'asc'
      }
      return {
        pageNum: state.adhocPage - 1,
        pageSize: state.adhocRowsPerPage,
        sortProperty: state.adhocSortBy,
        sortDirection,
        groupByProperty: state.adhocGroupBy,
        groupByDirection,
        searchText: state.adhocText,
        includeFields: state.adhocSelectedFields,
        queryName: state.adhocQueryName,
        queryIsModified: state.adhocQueryIsModified,
        pivotOptions: {
          includePivot: state.adhocPivotIncludePivot,
          pivotFirst: state.adhocPivotFirst,
          pivotName: state.adhocPivotName,
          rowFields: state.adhocPivotRowFields,
          columnFields: state.adhocPivotColumnFields,
          dataFields: state.adhocPivotDataFields,
          excludeFields: state.adhocPivotExcludeFields,
          filterFields: state.adhocPivotFilterFields,
        },
        startDate: rootState.dorInitiateAuditTransaction.adhocStartDate,
        endDate: rootState.dorInitiateAuditTransaction.adhocEndDate,
        //templateTODO: uncomment as used in AdHocDOR_AuditReturnTransactionReportParams
        // auditReturnID: state.adhocAuditReturnID,
        // registrantID: state.adhocRegistrantID,
        // licenseNumber: state.adhocLicenseNumber,
        // projectNumber: state.adhocProjectNumber,
        // auditStatus: state.adhocAuditStatus,
        // amendedReturn: state.adhocAmendedReturn,
        // reportFileLocation: state.adhocReportFileLocation,
        // retrieved: state.adhocRetrieved,
      }
    },
    adhocLoading(state) {
      return state.adhocSearchCount > 0
    },
  },

  mutations: {
    adhocDOR_AuditReturnTransactions(state, payload) {
      state.adhocDOR_AuditReturnTransactions = payload
    },
    adhocTotal(state, payload) {
      state.adhocTotal = payload
    },
    adhocPage(state, payload) {
      state.adhocPage = payload
    },
    adhocSortBy(state, payload) {
      state.adhocSortBy = payload || [adhocSortByDefault]
    },
    adhocDescending(state, payload) {
      state.adhocDescending = payload
    },
    adhocGroupBy(state, payload) {
      state.adhocGroupBy = payload
    },
    adhocGroupDescending(state, payload) {
      state.adhocGroupDescending = payload
    },
    adhocRowsPerPage(state, payload) {
      state.adhocRowsPerPage = payload
    },
    adhocText(state, payload) {
      state.adhocText = payload
    },
    adhocPivotIncludePivot(state, payload) {
      state.adhocPivotIncludePivot = payload
    },
    adhocPivotFirst(state, payload) {
      state.adhocPivotFirst = payload
    },
    adhocPivotName(state, payload) {
      state.adhocPivotName = payload
    },
    adhocPivotRowFields(state, payload) {
      state.adhocPivotRowFields = payload
    },
    adhocPivotColumnFields(state, payload) {
      state.adhocPivotColumnFields = payload
    },
    adhocPivotExcludeFields(state, payload) {
      state.adhocPivotExcludeFields = payload
    },
    adhocPivotDataFields(state, payload) {
      state.adhocPivotDataFields = payload
    },
    adhocPivotFilterFields(state, payload) {
      state.adhocPivotFilterFields = payload
    },
    adhocSelectedFields(state, payload) {
      state.adhocSelectedFields = payload
    },
    adhocQueryIsModified(state, payload) {
      state.adhocQueryIsModified = payload
    },
    adhocQueryName(state, payload) {
      state.adhocQueryName = payload
    },
    adhocSearchCount(state, payload) {
      state.adhocSearchCount = payload
    },
    increaseAdhocCount(state, payload) {
      state.adhocSearchCount = state.adhocSearchCount + 1
    },
    decreaseAdhocCount(state, payload) {
      state.adhocSearchCount = state.adhocSearchCount - 1
    },
    //TemplateTODO: uncomment as needed
    // adhocStartDate(state, payload) {
    //   state.adhocStartDate = payload
    // },
    // adhocEndDate(state, payload) {
    //   state.adhocEndDate = payload
    // },
    //adhocAuditReturnID(state, payload) {
    //  state.adhocAuditReturnID = payload
    //},
    //adhocRegistrantID(state, payload) {
    //  state.adhocRegistrantID = payload
    //},
    //adhocLicenseNumber(state, payload) {
    //  state.adhocLicenseNumber = payload
    //},
    //adhocProjectNumber(state, payload) {
    //  state.adhocProjectNumber = payload
    //},
    //adhocAuditStatus(state, payload) {
    //  state.adhocAuditStatus = payload
    //},
    //adhocAmendedReturn(state, payload) {
    //  state.adhocAmendedReturn = payload
    //},
    //adhocReportFileLocation(state, payload) {
    //  state.adhocReportFileLocation = payload
    //},
    //adhocRetrieved(state, payload) {
    //  state.adhocRetrieved = payload
    //},
  },

  actions: {
    adhocBounce: _.debounce((context) => {
      context.dispatch('displayAdhocDOR_AuditReturnTransactions')
    }, 750),
    setAdhocPage(context, payload) {
      if (payload != context.state.adhocPage) {
        context.commit('adhocPage', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocSortBy)
      ) {
        context.commit('adhocSortBy', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocDescending(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocDescending)
      ) {
        context.commit('adhocDescending', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocGroupBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocGroupBy)
      ) {
        context.commit('adhocGroupBy', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocGroupDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.adhocGroupDescending)
      ) {
        context.commit('adhocGroupDescending', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocRowsPerPage(context, payload) {
      if (payload != context.state.adhocRowsPerPage) {
        context.commit('adhocRowsPerPage', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocText(context, payload) {
      if (payload != context.state.adhocText) {
        context.commit('adhocText', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocText(context, payload) {
      if (payload != context.state.adhocText) {
        context.commit('adhocText', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocPivotIncludePivot(context, payload) {
      if (payload != context.state.adhocPivotIncludePivot) {
        context.commit('adhocPivotIncludePivot', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotFirst(context, payload) {
      if (payload != context.state.adhocPivotFirst) {
        context.commit('adhocPivotFirst', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotName(context, payload) {
      if (payload != context.state.adhocPivotName) {
        context.commit('adhocPivotName', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotRowFields(context, payload) {
      if (payload != context.state.adhocPivotRowFields) {
        context.commit('adhocPivotRowFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotColumnFields(context, payload) {
      if (payload != context.state.adhocPivotColumnFields) {
        context.commit('adhocPivotColumnFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotExcludeFields(context, payload) {
      if (payload != context.state.adhocPivotExcludeFields) {
        context.commit('adhocPivotExcludeFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotDataFields(context, payload) {
      if (payload != context.state.adhocPivotDataFields) {
        context.commit('adhocPivotDataFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotFilterFields(context, payload) {
      if (payload != context.state.adhocPivotFilterFields) {
        context.commit('adhocPivotFilterFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocSelectedFields(context, payload) {
      if (payload != context.state.adhocSelectedFields) {
        context.commit('adhocSelectedFields', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocQueryIsModified(context, payload) {
      if (payload != context.state.adhocQueryIsModified) {
        context.commit('adhocQueryIsModified', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocQueryName(context, payload) {
      if (payload != context.state.adhocQueryName) {
        context.commit('adhocQueryName', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    // setAdhocStartDate(context, payload) {
    //   if (payload != context.state.adhocStartDate) {
    //     context.commit('adhocStartDate', payload)
    //     context.dispatch('adhocBounce', context)
    //   }
    // },
    // setAdhocEndDate(context, payload) {
    //   if (payload != context.state.adhocEndDate) {
    //     context.commit('adhocEndDate', payload)
    //     context.dispatch('adhocBounce', context)
    //   }
    // },
    //TemplateTODO: uncomment as needed
    //setAdhocAuditReturnID(context, payload) {
    //  if (payload != context.state.adhocAuditReturnID) {
    //    context.commit('adhocAuditReturnID', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocRegistrantID(context, payload) {
    //  if (payload != context.state.adhocRegistrantID) {
    //    context.commit('adhocRegistrantID', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocLicenseNumber(context, payload) {
    //  if (payload != context.state.adhocLicenseNumber) {
    //    context.commit('adhocLicenseNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocProjectNumber(context, payload) {
    //  if (payload != context.state.adhocProjectNumber) {
    //    context.commit('adhocProjectNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocAuditStatus(context, payload) {
    //  if (payload != context.state.adhocAuditStatus) {
    //    context.commit('adhocAuditStatus', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocAmendedReturn(context, payload) {
    //  if (payload != context.state.adhocAmendedReturn) {
    //    context.commit('adhocAmendedReturn', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocReportFileLocation(context, payload) {
    //  if (payload != context.state.adhocReportFileLocation) {
    //    context.commit('adhocReportFileLocation', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocRetrieved(context, payload) {
    //  if (payload != context.state.adhocRetrieved) {
    //    context.commit('adhocRetrieved', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    async displayAdhocDOR_AuditReturnTransactions(context, payload) {
      if (!context.state.adhocSortBy) {
        context.commit('adhocSortBy', [adhocSortByDefault])
        context.commit('adhocDescending', [true])
      }
      if (!Array.isArray(context.state.adhocSortBy)) {
        if (context.state.adhocSortBy) {
          context.commit('adhocSortBy', [context.state.adhocSortBy])
          context.commit('adhocDescending', [context.state.adhocDescending])
        } else {
          context.commit('adhocSortBy', [adhocSortByDefault])
          context.commit('adhocDescending', [true])
        }
      }
      if (!context.state.adhocText) {
        context.commit('adhocText', '')
      }
      context.commit('increaseAdhocCount')
      var params = context.getters.adhocParameters
      var paramString = JSON.stringify(params)
      const url = `AdHocDOR_AuditReturnTransactionReport/Display`
      try {
        let res = await Vue.prototype.$axios.post(url, params)
        if (JSON.stringify(context.getters.adhocParameters) == paramString) {
          context.commit('adhocDOR_AuditReturnTransactions', res.data.Entries)
          context.commit('adhocRowsPerPage', res.data.Page.Size)
          context.commit('adhocPage', res.data.Page.Number + 1)
          context.commit('adhocTotal', res.data.Page.TotalElements)
        }
        context.commit('decreaseAdhocCount')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, {root: true})
        context.commit('decreaseAdhocCount')
        throw err
      }
    },
    async downloadAdhoc(context, payload) {
      if (!context.state.adhocSortBy) {
        context.commit('adhocSortBy', [adhocSortByDefault])
        context.commit('adhocDescending', [true])
      }
      if (!Array.isArray(context.state.adhocSortBy)) {
        if (context.state.adhocSortBy) {
          context.commit('adhocSortBy', [context.state.adhocSortBy])
          context.commit('adhocDescending', [context.state.adhocDescending])
        } else {
          context.commit('adhocSortBy', [adhocSortByDefault])
          context.commit('adhocDescending', [true])
        }
      }
      let headers = {responseType: 'blob'}
      let url = 'AdHocDOR_AuditReturnTransactionReport'
      try {
        let res = await Vue.prototype.$axios.post(
          url,
          context.getters.adhocParameters,
          headers
        )
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        download(res.data, filename, content)
      } catch (err) {
        context.dispatch('errors/handleError', err, {root: true})
        console.error(err)
      }
    },
  },
}

export default dorAuditReturnTransaction
