import Vue from 'vue'

const luAuditReason = {
  state: {
    luAuditReason: [],
    selectedLuAuditReason: {},
  },

  getters: {
    luAuditReason(state) {
      return state.luAuditReason
    },
    selectedLuAuditReason(state) {
      return state.selectedLuAuditReason
    },
  },

  mutations: {
    luAuditReason(state, payload) {
      state.luAuditReason = payload
    },
    selectedLuAuditReason(state, payload) {
      state.selectedLuAuditReason = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuAuditReason(context, payload) {
      context.commit('selectedLuAuditReason', payload)
    },
    loadLuAuditReason(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuAuditReason'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luAuditReason', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuAuditReason(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuAuditReason/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuAuditReason', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuAuditReason(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuAuditReason/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'audit reason updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuAuditReason')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuAuditReason(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuAuditReason`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'audit reason created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuAuditReason')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuAuditReason(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuAuditReason', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuAuditReason', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuAuditReason(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuAuditReason/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'audit reason deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuAuditReason')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luAuditReason
