import Vue from 'vue'

const luSentTo = {
  state: {
    luSentTo: [],
    selectedLuSentTo: {},
  },

  getters: {
    luSentTo(state) {
      return state.luSentTo
    },
    selectedLuSentTo(state) {
      return state.selectedLuSentTo
    },
  },

  mutations: {
    luSentTo(state, payload) {
      state.luSentTo = payload
    },
    selectedLuSentTo(state, payload) {
      state.selectedLuSentTo = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuSentTo(context, payload) {
      context.commit('selectedLuSentTo', payload)
    },
    loadLuSentTo(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuSentTo'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luSentTo', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuSentTo(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuSentTo/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuSentTo', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuSentTo(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuSentTo/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'sent to updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuSentTo')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuSentTo(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuSentTo`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'sent to created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuSentTo')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuSentTo(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuSentTo', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuSentTo', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuSentTo(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuSentTo/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'sent to deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuSentTo')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luSentTo
