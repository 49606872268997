import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    dark: false,
    options: {customProperties: true},
    themes: {
      light: {
        primary: '#358345', //colors.green.darken1,
        secondary: '#78AC83', //colors.green.lighten4,
        accent: colors.green.base,
        tertiary: '#35495E',
        customBackground: '#F0F8FF',
        background: '#FFF',
        border: '#d4d4d4',
        hover: '#e9e9e9',
        modified: '#e9f1f8',
        text: '#000',
      },
      dark: {
        primary: '#4C9159', //colors.green.darken1,
        secondary: '#78AC83', //colors.green.lighten4,
        accent: colors.green.base,
        tertiary: '#35495E',
        customBackground: '#35495E',
        background: '#000',
        border: '#3c3c3c',
        hover: '#2b2b2b',
        modified: '#234a6c',
        text: '#FFF',
      },
      // App.vue has commented out code in the mounted
      //that will auto switch the theme based on user preference
    },
  },
})
