import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const cityCounty = {
  state: {
    cityCounty: [],
    selectedCityCounty: {},
    selectedCityCountyOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    cityCounty(state) {
      return state.cityCounty
    },
    selectedCityCounty(state) {
      return state.selectedCityCounty
    },
    selectedCityCountyOriginal(state) {
      return state.selectedCityCountyOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    cityCounty(state, payload) {
      state.cityCounty = payload
    },
    selectedCityCounty(state, payload) {
      state.selectedCityCounty = JSON.parse(JSON.stringify(payload))
    },
    selectedCityCountyOriginal(state, payload) {
      state.selectedCityCountyOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    loadCityCounty(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'CityCounty'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('cityCounty', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedCityCounty(context, payload) {
      if (context.state.selectedCityCounty && 
        context.state.selectedCityCounty.CityID > 0 &&
              context.state.selectedCityCounty.CountyID > 0 
      ) {
      let keys = {
      cityID:  context.state.selectedCityCounty.CityID,
      countyID:  context.state.selectedCityCounty.CountyID,
      }
        context.dispatch('loadSelectedCityCounty', keys)
      }
    },
    loadSelectedCityCounty(context, payload) {
      if (
      payload &&
      payload.cityID > 0 &&
            payload.countyID > 0
      ) {
        if (context.state.selectedCityCounty && (
        context.state.selectedCityCounty.CityID != payload.cityID || 
                context.state.selectedCityCounty.CountyID != payload.countyID 
        )) {
          context.commit('selectedCityCounty', {})
          context.commit('selectedCityCountyOriginal', {})
        }
        return new Promise((resolve, reject) => {
          const url = 'CityCounty/'
          payload.cityID+ '/' +
          payload.countyID
          context.commit('increaseSearchCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedCityCounty', res.data)
              context.commit('selectedCityCountyOriginal', res.data)
              context.commit('decreaseSearchCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseSearchCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    clearLookupCacheAndReloadCityCounty(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadCityCounty')
      }, )
    },
    createCityCounty(context, payload){
      return new Promise((resolve, reject) => {
        const url = `CityCounty`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'city county created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadCityCounty')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchCityCounty(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedCityCounty
        if (
      payload.CityID && payload.CityID == context.state.selectedCityCountyOriginal.CityID && 
      payload.CountyID && payload.CountyID == context.state.selectedCityCountyOriginal.CountyID
        )  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc ||!payload.CityID || 
      !payload.CountyID
      ) {
          let original = context.state.selectedCityCountyOriginal
          let current = item
          patchPayload.CityID = item.CityID
          patchPayload.CountyID = item.CountyID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
        const url = 'CityCounty?'+
        'cityID='+   patchPayload.CityID+ '&' +
        'countyID='+   patchPayload.CountyID
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'city county updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadCityCounty')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateCityCounty(context, payload){
      return new Promise((resolve, reject) => { 
        const url = 'CityCounty?'+
        'cityID='+   payload.CityID+ '&' +
        'countyID='+   payload.CountyID
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'city county updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadCityCounty')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    deleteCityCounty(context, payload){
      return new Promise((resolve, reject) => {
        const url = 'CityCounty?'+
        'cityID='+   payload.CityID+ '&' +
        'countyID='+   payload.CountyID
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'city county deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadCityCounty')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default cityCounty