import Vue from 'vue'
import {debounce, range} from 'lodash'
import download from 'downloadjs'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const adhocSortByDefault = 'ID'
const exciseExceptionsFlat = {
  state: {
    adhocExciseExceptionsFlat: [],
    adhocTotal: 0,
    adhocPage: 1,
    adhocSortBy: [adhocSortByDefault],
    adhocDescending: [true],
    adhocGroupBy: null,
    adhocGroupDescending: null,
    adhocRowsPerPage: 25,
    adhocSearchCount: 0,
    adhocPivotIncludePivot: false,
    adhocPivotFirst: true,
    adhocPivotName: '',
    adhocPivotRowFields: [],
    adhocPivotColumnFields: [],
    adhocPivotExcludeFields: [],
    adhocPivotDataFields: [],
    adhocPivotFilterFields: [],
    adhocSelectedFields: [],
    adhocQueryIsModified: false,
    adhocQueryName: '',
    adhocText: '',
    adhocStartDate: null,
    adhocEndDate: null,
    //templateTODO: uncomment as used in AdHocExciseExceptionsFlatReportParams
    // adhocID: [],
    adhocProjectID: [],
    // adhocProjectNumber: [],
    // adhocProjectDescription: [],
    adhocStratumID: [],
    // adhocStratumNumber: [],
    // adhocStratumDescription: [],
    adhocStratumStatus: [],
    adhocProjectionTypeID: [],
    // adhocProjectionType: [],
    // adhocStratumIsActive: [],
    // adhocDocumentNumber: [],
    adhocIssueID: [],
    // adhocIssue: [],
    adhocTransactionTypeID: [],
    // adhocTransactionType: [],
    adhocTaxTypeID: [],
    // adhocTaxType: [],
    adhocDeliveredToCityID: [],
    // adhocDeliveredToCity: [],
    adhocDeliveredToCountyID: [],
    // adhocDeliveredToCounty: [],
    adhocSpecialDistrictID: [],
    // adhocSpecialDistrict: [],
    // adhocVendorCustomer: [],
    // adhocDescription: [],
    // adhocReference: [],
    // adhocIsActive: [],
    adhocStatus: [],
    // adhocIsAppended: [],
    adhocCreatedByID: [],
    // adhocCreatedBy: [],
    adhocModifiedByID: [],
    // adhocModifiedBy: [],
  },

  getters: {
    adhocParameters(state) {
      var sortDirection
      if (Array.isArray(state.adhocDescending)) {
        sortDirection = state.adhocDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.adhocDescending ? ['desc'] : ['asc']
      }
      var groupByDirection
      if (Array.isArray(state.adhocGroupDescending)) {
        if (state.adhocGroupDescending.length > 0) {
          groupByDirection = state.adhocGroupDescending[0] ? 'desc' : 'asc'
        } else {
          groupByDirection = 'asc'
        }
      } else {
        groupByDirection = state.adhocGroupDescending ? 'desc' : 'asc'
      }
      return {
        pageNum: state.adhocPage - 1,
        pageSize: state.adhocRowsPerPage,
        sortProperty: state.adhocSortBy,
        sortDirection,
        groupByProperty: state.adhocGroupBy,
        groupByDirection,
        searchText: state.adhocText,
        includeFields: state.adhocSelectedFields,
        queryName: state.adhocQueryName,
        queryIsModified: state.adhocQueryIsModified,
        pivotOptions: {
          includePivot: state.adhocPivotIncludePivot,
          pivotFirst: state.adhocPivotFirst,
          pivotName: state.adhocPivotName,
          rowFields: state.adhocPivotRowFields,
          columnFields: state.adhocPivotColumnFields,
          dataFields: state.adhocPivotDataFields,
          excludeFields: state.adhocPivotExcludeFields,
          filterFields: state.adhocPivotFilterFields,
        },
        startDate: state.adhocStartDate,
        endDate: state.adhocEndDate,
        //templateTODO: uncomment as used in AdHocExciseExceptionsFlatReportParams
        // iD: state.adhocID,
        projectID: state.adhocProjectID,
        // projectNumber: state.adhocProjectNumber,
        // projectDescription: state.adhocProjectDescription,
        stratumID: state.adhocStratumID,
        // stratumNumber: state.adhocStratumNumber,
        // stratumDescription: state.adhocStratumDescription,
        stratumStatus: state.adhocStratumStatus,
        projectionTypeID: state.adhocProjectionTypeID,
        // projectionType: state.adhocProjectionType,
        // stratumIsActive: state.adhocStratumIsActive,
        // documentNumber: state.adhocDocumentNumber,
        issueID: state.adhocIssueID,
        // issue: state.adhocIssue,
        transactionTypeID: state.adhocTransactionTypeID,
        // transactionType: state.adhocTransactionType,
        taxTypeID: state.adhocTaxTypeID,
        // taxType: state.adhocTaxType,
        deliveredToCityID: state.adhocDeliveredToCityID,
        // deliveredToCity: state.adhocDeliveredToCity,
        deliveredToCountyID: state.adhocDeliveredToCountyID,
        // deliveredToCounty: state.adhocDeliveredToCounty,
        specialDistrictID: state.adhocSpecialDistrictID,
        // specialDistrict: state.adhocSpecialDistrict,
        // vendorCustomer: state.adhocVendorCustomer,
        // description: state.adhocDescription,
        // reference: state.adhocReference,
        // isActive: state.adhocIsActive,
        status: state.adhocStatus,
        // isAppended: state.adhocIsAppended,
        createdByID: state.adhocCreatedByID,
        // createdBy: state.adhocCreatedBy,
        modifiedByID: state.adhocModifiedByID,
        // modifiedBy: state.adhocModifiedBy,
      }
    },
    adhocLoading(state) {
      return state.adhocSearchCount > 0
    },
  },

  mutations: {
    adhocExciseExceptionsFlat(state, payload) {
      state.adhocExciseExceptionsFlat = payload
    },
    adhocTotal(state, payload) {
      state.adhocTotal = payload
    },
    adhocPage(state, payload) {
      state.adhocPage = payload
    },
    adhocSortBy(state, payload) {
      state.adhocSortBy = payload || [adhocSortByDefault]
    },
    adhocDescending(state, payload) {
      state.adhocDescending = payload
    },
    adhocGroupBy(state, payload) {
      state.adhocGroupBy = payload
    },
    adhocGroupDescending(state, payload) {
      state.adhocGroupDescending = payload
    },
    adhocRowsPerPage(state, payload) {
      state.adhocRowsPerPage = payload
    },
    adhocText(state, payload) {
      state.adhocText = payload
    },
    adhocPivotIncludePivot(state, payload) {
      state.adhocPivotIncludePivot = payload
    },
    adhocPivotFirst(state, payload) {
      state.adhocPivotFirst = payload
    },
    adhocPivotName(state, payload) {
      state.adhocPivotName = payload
    },
    adhocPivotRowFields(state, payload) {
      state.adhocPivotRowFields = payload
    },
    adhocPivotColumnFields(state, payload) {
      state.adhocPivotColumnFields = payload
    },
    adhocPivotExcludeFields(state, payload) {
      state.adhocPivotExcludeFields = payload
    },
    adhocPivotDataFields(state, payload) {
      state.adhocPivotDataFields = payload
    },
    adhocPivotFilterFields(state, payload) {
      state.adhocPivotFilterFields = payload
    },
    adhocSelectedFields(state, payload) {
      state.adhocSelectedFields = payload
    },
    adhocQueryIsModified(state, payload) {
      state.adhocQueryIsModified = payload
    },
    adhocQueryName(state, payload) {
      state.adhocQueryName = payload
    },
    adhocSearchCount(state, payload) {
      state.adhocSearchCount = payload
    },
    increaseAdhocCount(state, payload) {
      state.adhocSearchCount = state.adhocSearchCount + 1
    },
    decreaseAdhocCount(state, payload) {
      state.adhocSearchCount = state.adhocSearchCount - 1
    },
    //TemplateTODO: uncomment as needed
    adhocStartDate(state, payload) {
      state.adhocStartDate = payload
    },
    adhocEndDate(state, payload) {
      state.adhocEndDate = payload
    },
    //adhocID(state, payload) {
    //  state.adhocID = payload
    //},
    adhocProjectID(state, payload) {
      state.adhocProjectID = payload
    },
    //adhocProjectNumber(state, payload) {
    //  state.adhocProjectNumber = payload
    //},
    //adhocProjectDescription(state, payload) {
    //  state.adhocProjectDescription = payload
    //},
    adhocStratumID(state, payload) {
      state.adhocStratumID = payload
    },
    //adhocStratumNumber(state, payload) {
    //  state.adhocStratumNumber = payload
    //},
    //adhocStratumDescription(state, payload) {
    //  state.adhocStratumDescription = payload
    //},
    adhocStratumStatus(state, payload) {
      state.adhocStratumStatus = payload
    },
    adhocProjectionTypeID(state, payload) {
      state.adhocProjectionTypeID = payload
    },
    //adhocProjectionType(state, payload) {
    //  state.adhocProjectionType = payload
    //},
    //adhocStratumIsActive(state, payload) {
    //  state.adhocStratumIsActive = payload
    //},
    //adhocDocumentNumber(state, payload) {
    //  state.adhocDocumentNumber = payload
    //},
    adhocIssueID(state, payload) {
      state.adhocIssueID = payload
    },
    //adhocIssue(state, payload) {
    //  state.adhocIssue = payload
    //},
    adhocTransactionTypeID(state, payload) {
      state.adhocTransactionTypeID = payload
    },
    //adhocTransactionType(state, payload) {
    //  state.adhocTransactionType = payload
    //},
    adhocTaxTypeID(state, payload) {
      state.adhocTaxTypeID = payload
    },
    //adhocTaxType(state, payload) {
    //  state.adhocTaxType = payload
    //},
    adhocDeliveredToCityID(state, payload) {
      state.adhocDeliveredToCityID = payload
    },
    //adhocDeliveredToCity(state, payload) {
    //  state.adhocDeliveredToCity = payload
    //},
    adhocDeliveredToCountyID(state, payload) {
      state.adhocDeliveredToCountyID = payload
    },
    //adhocDeliveredToCounty(state, payload) {
    //  state.adhocDeliveredToCounty = payload
    //},
    adhocSpecialDistrictID(state, payload) {
      state.adhocSpecialDistrictID = payload
    },
    //adhocSpecialDistrict(state, payload) {
    //  state.adhocSpecialDistrict = payload
    //},
    //adhocVendorCustomer(state, payload) {
    //  state.adhocVendorCustomer = payload
    //},
    //adhocDescription(state, payload) {
    //  state.adhocDescription = payload
    //},
    //adhocReference(state, payload) {
    //  state.adhocReference = payload
    //},
    //adhocIsActive(state, payload) {
    //  state.adhocIsActive = payload
    //},
    adhocStatus(state, payload) {
      state.adhocStatus = payload
    },
    //adhocIsAppended(state, payload) {
    //  state.adhocIsAppended = payload
    //},
    adhocCreatedByID(state, payload) {
      state.adhocCreatedByID = payload
    },
    // adhocCreatedBy(state, payload) {
    //   state.adhocCreatedBy = payload
    // },
    adhocModifiedByID(state, payload) {
      state.adhocModifiedByID = payload
    },
    // adhocModifiedBy(state, payload) {
    //   state.adhocModifiedBy = payload
    // },
  },

  actions: {
    adhocBounce: _.debounce((context) => {
      context.dispatch('displayAdhocExciseExceptionsFlat')
    }, 750),
    setAdhocPage(context, payload) {
      if (payload != context.state.adhocPage) {
        context.commit('adhocPage', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocSortBy)
      ) {
        context.commit('adhocSortBy', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocDescending(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocDescending)
      ) {
        context.commit('adhocDescending', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocGroupBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocGroupBy)
      ) {
        context.commit('adhocGroupBy', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocGroupDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.adhocGroupDescending)
      ) {
        context.commit('adhocGroupDescending', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocRowsPerPage(context, payload) {
      if (payload != context.state.adhocRowsPerPage) {
        context.commit('adhocRowsPerPage', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocText(context, payload) {
      if (payload != context.state.adhocText) {
        context.commit('adhocText', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocText(context, payload) {
      if (payload != context.state.adhocText) {
        context.commit('adhocText', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocPivotIncludePivot(context, payload) {
      if (payload != context.state.adhocPivotIncludePivot) {
        context.commit('adhocPivotIncludePivot', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotFirst(context, payload) {
      if (payload != context.state.adhocPivotFirst) {
        context.commit('adhocPivotFirst', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotName(context, payload) {
      if (payload != context.state.adhocPivotName) {
        context.commit('adhocPivotName', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotRowFields(context, payload) {
      if (payload != context.state.adhocPivotRowFields) {
        context.commit('adhocPivotRowFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotColumnFields(context, payload) {
      if (payload != context.state.adhocPivotColumnFields) {
        context.commit('adhocPivotColumnFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotExcludeFields(context, payload) {
      if (payload != context.state.adhocPivotExcludeFields) {
        context.commit('adhocPivotExcludeFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotDataFields(context, payload) {
      if (payload != context.state.adhocPivotDataFields) {
        context.commit('adhocPivotDataFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotFilterFields(context, payload) {
      if (payload != context.state.adhocPivotFilterFields) {
        context.commit('adhocPivotFilterFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocSelectedFields(context, payload) {
      if (payload != context.state.adhocSelectedFields) {
        context.commit('adhocSelectedFields', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocQueryIsModified(context, payload) {
      if (payload != context.state.adhocQueryIsModified) {
        context.commit('adhocQueryIsModified', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocQueryName(context, payload) {
      if (payload != context.state.adhocQueryName) {
        context.commit('adhocQueryName', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocStartDate(context, payload) {
      if (payload != context.state.adhocStartDate) {
        context.commit('adhocStartDate', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocEndDate(context, payload) {
      if (payload != context.state.adhocEndDate) {
        context.commit('adhocEndDate', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    //setAdhocID(context, payload) {
    //  if (payload != context.state.adhocID) {
    //    context.commit('adhocID', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocProjectID(context, payload) {
      if (payload != context.state.adhocProjectID) {
        context.commit('adhocProjectID', payload)
        context.dispatch('adhocBounce', context)
        // context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocProjectNumber(context, payload) {
    //  if (payload != context.state.adhocProjectNumber) {
    //    context.commit('adhocProjectNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocProjectDescription(context, payload) {
    //  if (payload != context.state.adhocProjectDescription) {
    //    context.commit('adhocProjectDescription', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocStratumID(context, payload) {
      if (payload != context.state.adhocStratumID) {
        context.commit('adhocStratumID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocStratumNumber(context, payload) {
    //  if (payload != context.state.adhocStratumNumber) {
    //    context.commit('adhocStratumNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocStratumDescription(context, payload) {
    //  if (payload != context.state.adhocStratumDescription) {
    //    context.commit('adhocStratumDescription', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocStratumStatus(context, payload) {
      if (payload != context.state.adhocStratumStatus) {
        context.commit('adhocStratumStatus', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocProjectionTypeID(context, payload) {
      if (payload != context.state.adhocProjectionTypeID) {
        context.commit('adhocProjectionTypeID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocProjectionType(context, payload) {
    //  if (payload != context.state.adhocProjectionType) {
    //    context.commit('adhocProjectionType', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocStratumIsActive(context, payload) {
    //  if (payload != context.state.adhocStratumIsActive) {
    //    context.commit('adhocStratumIsActive', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocDocumentNumber(context, payload) {
    //  if (payload != context.state.adhocDocumentNumber) {
    //    context.commit('adhocDocumentNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocIssueID(context, payload) {
      if (payload != context.state.adhocIssueID) {
        context.commit('adhocIssueID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocIssue(context, payload) {
    //  if (payload != context.state.adhocIssue) {
    //    context.commit('adhocIssue', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocTransactionTypeID(context, payload) {
      if (payload != context.state.adhocTransactionTypeID) {
        context.commit('adhocTransactionTypeID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocTransactionType(context, payload) {
    //  if (payload != context.state.adhocTransactionType) {
    //    context.commit('adhocTransactionType', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocTaxTypeID(context, payload) {
      if (payload != context.state.adhocTaxTypeID) {
        context.commit('adhocTaxTypeID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocTaxType(context, payload) {
    //  if (payload != context.state.adhocTaxType) {
    //    context.commit('adhocTaxType', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocDeliveredToCityID(context, payload) {
      if (payload != context.state.adhocDeliveredToCityID) {
        context.commit('adhocDeliveredToCityID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocDeliveredToCity(context, payload) {
    //  if (payload != context.state.adhocDeliveredToCity) {
    //    context.commit('adhocDeliveredToCity', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocDeliveredToCountyID(context, payload) {
      if (payload != context.state.adhocDeliveredToCountyID) {
        context.commit('adhocDeliveredToCountyID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocDeliveredToCounty(context, payload) {
    //  if (payload != context.state.adhocDeliveredToCounty) {
    //    context.commit('adhocDeliveredToCounty', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocSpecialDistrictID(context, payload) {
      if (payload != context.state.adhocSpecialDistrictID) {
        context.commit('adhocSpecialDistrictID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocSpecialDistrict(context, payload) {
    //  if (payload != context.state.adhocSpecialDistrict) {
    //    context.commit('adhocSpecialDistrict', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocVendorCustomer(context, payload) {
    //  if (payload != context.state.adhocVendorCustomer) {
    //    context.commit('adhocVendorCustomer', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocDescription(context, payload) {
    //  if (payload != context.state.adhocDescription) {
    //    context.commit('adhocDescription', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocReference(context, payload) {
    //  if (payload != context.state.adhocReference) {
    //    context.commit('adhocReference', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocIsActive(context, payload) {
    //  if (payload != context.state.adhocIsActive) {
    //    context.commit('adhocIsActive', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocStatus(context, payload) {
      if (payload != context.state.adhocStatus) {
        context.commit('adhocStatus', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocIsAppended(context, payload) {
    //  if (payload != context.state.adhocIsAppended) {
    //    context.commit('adhocIsAppended', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocCreatedByID(context, payload) {
      if (payload != context.state.adhocCreatedByID) {
        context.commit('adhocCreatedByID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocCreatedBy(context, payload) {
    //  if (payload != context.state.adhocCreatedBy) {
    //    context.commit('adhocCreatedBy', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocModifiedByID(context, payload) {
      if (payload != context.state.adhocModifiedByID) {
        context.commit('adhocModifiedByID', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocModifiedBy(context, payload) {
    //  if (payload != context.state.adhocModifiedBy) {
    //    context.commit('adhocModifiedBy', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    displayAdhocExciseExceptionsFlat(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.adhocSortBy) {
          context.commit('adhocSortBy', [adhocSortByDefault])
          context.commit('adhocDescending', [true])
        }
        if (!Array.isArray(context.state.adhocSortBy)) {
          if (context.state.adhocSortBy) {
            context.commit('adhocSortBy', [context.state.adhocSortBy])
            if (!Array.isArray(context.state.adhocDescending)) {
              context.commit('adhocDescending', [context.state.adhocDescending])
            }
          } else {
            context.commit('adhocSortBy', [adhocSortByDefault])
            context.commit('adhocDescending', [true])
          }
        }
        if (!context.state.adhocText) {
          context.commit('adhocText', '')
        }
        context.commit('increaseAdhocCount')
        var params = context.getters.adhocParameters
        var paramString = JSON.stringify(params)
        const url = `AdHocVwExciseExceptionsFlatReport/Display`
        Vue.prototype.$axios.post(url, params).then(
          (res) => {
            if (
              JSON.stringify(context.getters.adhocParameters) == paramString
            ) {
              context.commit('adhocExciseExceptionsFlat', res.data.Entries)
              context.commit('adhocRowsPerPage', res.data.Page.Size)
              context.commit('adhocPage', res.data.Page.Number + 1)
              context.commit('adhocTotal', res.data.Page.TotalElements)
            }
            context.commit('decreaseAdhocCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseAdhocCount')
            reject(err)
          }
        )
      })
    },
    downloadAdhoc(context, payload) {
      if (!context.state.adhocSortBy) {
        context.commit('adhocSortBy', [adhocSortByDefault])
        context.commit('adhocDescending', [true])
      }
      if (!Array.isArray(context.state.adhocSortBy)) {
        if (context.state.adhocSortBy) {
          context.commit('adhocSortBy', [context.state.adhocSortBy])
          if (!Array.isArray(context.state.adhocDescending)) {
            context.commit('adhocDescending', [context.state.adhocDescending])
          }
        } else {
          context.commit('adhocSortBy', [adhocSortByDefault])
          context.commit('adhocDescending', [true])
        }
      }
      if (!context.state.adhocText) {
        context.commit('adhocText', '')
      }
      let headers = {responseType: 'blob'}
      let url = 'AdHocVwExciseExceptionsFlatReport'
      Vue.prototype.$axios
        .post(url, context.getters.adhocParameters, headers)
        .then(
          (res) => {
            const content = res.headers['content-type']
            const dispo = res.headers['content-disposition']
            var filename = ''
            if (dispo && dispo.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              var matches = filenameRegex.exec(dispo)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            download(res.data, filename, content)
          },
          (error) => {
            context.dispatch('errors/handleError', error, {root: true})
            console.error(error)
          }
        )
    },
  },
}

export default exciseExceptionsFlat
