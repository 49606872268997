<template>
  <v-tooltip :left="left" :top="top" :bottom="bottom" :right="right">
    <template v-slot:activator="{ on }">
      <v-chip :class="chipClass" v-bind="$attrs" v-on="on" icon>
        <v-icon :color="iconColor" :small="$attrs.small" :dark="dark">{{
          icon
        }}</v-icon>
      </v-chip>
    </template>
    <slot></slot>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-circle-medium',
    },
    iconColor: {
      type: String,
      default: '',
    },
    chipClass: {
      type: String,
      default: '',
    },
  },
  components: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    left() {
      return this.position == 'left'
    },
    right() {
      return this.position == 'right'
    },
    top() {
      return this.position == 'top'
    },
    bottom() {
      return this.position == 'bottom'
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>