import Vue from 'vue'

const projectReportedValues = {
  state: {
    projectReportedValues: [],
    selectedProjectReportedValues: {},
  },

  getters: {
    projectReportedValues(state) {
      return state.projectReportedValues
    },
    selectedProjectReportedValues(state) {
      return state.selectedProjectReportedValues
    },
  },

  mutations: {
    projectReportedValues(state, payload) {
      state.projectReportedValues = payload
    },
    selectedProjectReportedValues(state, payload) {
      state.selectedProjectReportedValues = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectReportedValues(context, payload) {
      context.commit('selectedProjectReportedValues', payload)
    },
    loadProjectReportedValues(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectReportedValues'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectReportedValues', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectReportedValues(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectReportedValues/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectReportedValues', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectReportedValues(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectReportedValues/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectReportedValues updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectReportedValues')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectReportedValues(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectReportedValues`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectReportedValues created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectReportedValues')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectReportedValues(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectReportedValues', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectReportedValues', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectReportedValues(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectReportedValues/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectReportedValues deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectReportedValues')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectReportedValues
