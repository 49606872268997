import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luCounty = {
  state: {
    luCounty: [],
    selectedLuCounty: {},
    selectedLuCountyOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luCounty(state) {
      return state.luCounty
    },
    selectedLuCounty(state) {
      return state.selectedLuCounty
    },
    selectedLuCountyOriginal(state) {
      return state.selectedLuCountyOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luCounty(state, payload) {
      state.luCounty = payload
    },
    selectedLuCounty(state, payload) {
      state.selectedLuCounty = JSON.parse(JSON.stringify(payload))
    },
    selectedLuCountyOriginal(state, payload) {
      state.selectedLuCountyOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuCounty(context, payload) {
      context.commit('selectedLuCounty', payload)
    },
    loadLuCounty(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuCounty'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luCounty', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuCounty(context, payload) {
      if (context.state.selectedLuCounty && context.state.selectedLuCounty.ID > 0) {
        context.dispatch('loadSelectedLuCounty', context.state.selectedLuCounty.ID)

      }
    },
    loadSelectedLuCounty(context, payload) {
      if (context.state.selectedLuCounty && context.state.selectedLuCounty.ID != payload) {
        context.commit('selectedLuCounty', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'LuCounty/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuCounty', res.data)
            context.commit('selectedLuCountyOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuCounty(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuCounty')
      }, )
    },
    createLuCounty(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuCounty`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'county created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCounty')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchLuCounty(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedLuCounty
        if (payload.ID && 
        payload.ID == context.state.selectedLuCountyOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedLuCountyOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `LuCounty/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'county updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadLuCounty')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateLuCounty(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `LuCounty/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'county updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCounty')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuCounty(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuCounty', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuCounty', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuCounty(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuCounty/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'county deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuCounty')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luCounty