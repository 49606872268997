import Vue from 'vue'

const timeSheetEntry = {
  state: {
    timeSheetEntry: [],
    projectTimeSheetEntries: [],
    selectedTimeSheetEntry: {},
  },

  getters: {
    timeSheetEntry(state) {
      return state.timeSheetEntry
    },
    projectTimeSheetEntries(state) {
      return state.projectTimeSheetEntries
    },
    selectedTimeSheetEntry(state) {
      return state.selectedTimeSheetEntry
    },
  },

  mutations: {
    timeSheetEntry(state, payload) {
      state.timeSheetEntry = payload
    },
    projectTimeSheetEntries(state, payload) {
      state.projectTimeSheetEntries = payload
    },
    selectedTimeSheetEntry(state, payload) {
      state.selectedTimeSheetEntry = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedTimeSheetEntry(context, payload) {
      context.commit('selectedTimeSheetEntry', payload)
    },
    loadTimeSheetEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'TimeSheetEntry'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('timeSheetEntry', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadProjectTimeSheetEntries(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetEntry/Project/${payload}`
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectTimeSheetEntries', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedTimeSheetEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'TimeSheetEntry/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedTimeSheetEntry', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    //I made this a separate call so that it can refresh the project entries
    updateBillableCategory(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetEntry/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'billable category updated'},
              {root: true}
            )
            context.dispatch('loadProjectTimeSheetEntries', payload.ProjectID)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateTimeSheetEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetEntry/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'timesheet entry updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadTimeSheetEntry')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createTimeSheetEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetEntry`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'timesheet entry created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadTimeSheetEntry')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveTimeSheetEntry(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateTimeSheetEntry', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createTimeSheetEntry', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteTimeSheetEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetEntry/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'timesheet entry deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadTimeSheetEntry')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default timeSheetEntry
