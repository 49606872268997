export default {
  BaseUrl: `/api/`,
  gapiConfig: {
    /* spell-checker: disable */
    apiKey: 'AIzaSyDVuDKJDZvYAKWde0HXMedVN_jjV8GUwv8',
    clientId:
      '845319349417-ncdn21fn2mul84imqhm77et8t51l3gdv.apps.googleusercontent.com',
    /* spell-checker: enable */
    fetch_basic_profile: true,
    scope: 'email profile',
    // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
  },
}
