import Vue from 'vue'

const luTrackingDateType = {
  state: {
    luTrackingDateType: [],
    selectedLuTrackingDateType: {},
  },

  getters: {
    luTrackingDateType(state) {
      return state.luTrackingDateType
    },
    selectedLuTrackingDateType(state) {
      return state.selectedLuTrackingDateType
    },
  },

  mutations: {
    luTrackingDateType(state, payload) {
      state.luTrackingDateType = payload
    },
    selectedLuTrackingDateType(state, payload) {
      state.selectedLuTrackingDateType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuTrackingDateType(context, payload) {
      context.commit('selectedLuTrackingDateType', payload)
    },
    loadLuTrackingDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuTrackingDateType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luTrackingDateType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuTrackingDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuTrackingDateType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuTrackingDateType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuTrackingDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuTrackingDateType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'tracking date type updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuTrackingDateType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuTrackingDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuTrackingDateType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'tracking date type created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuTrackingDateType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuTrackingDateType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuTrackingDateType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuTrackingDateType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuTrackingDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuTrackingDateType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'tracking date type deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuTrackingDateType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luTrackingDateType
