import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._t("searchParams",function(){return [(_vm.search)?_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e()]}),_c(VDataTable,_vm._b({attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"server-items-length":_vm.searchTotal,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.descending,"group-by":_vm.groupByProperty,"group-desc":_vm.groupByDescending,"page":_vm.selectedPage,"items-per-page":_vm.rowsPerPage,"item-key":_vm.itemKey,"footer-props":_vm.footerProps,"dense":_vm.dense},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.descending=$event},"update:sort-desc":function($event){_vm.descending=$event},"update:groupBy":function($event){_vm.groupByProperty=$event},"update:group-by":function($event){_vm.groupByProperty=$event},"update:groupDesc":function($event){_vm.groupByDescending=$event},"update:group-desc":function($event){_vm.groupByDescending=$event},"update:page":function($event){_vm.selectedPage=$event},"update:itemsPerPage":function($event){_vm.rowsPerPage=$event},"update:items-per-page":function($event){_vm.rowsPerPage=$event}},scopedSlots:_vm._u([(_vm.searchRowsPerPage && _vm.paginateHeader)?{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c(VDataFooter,{attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.descending,"page":_vm.selectedPage,"items-per-page":_vm.rowsPerPage,"showFirstLastPage":_vm.showFirstLastPage,"showCurrentPage":_vm.showCurrentPage,"itemsPerPageOptions":_vm.availableItemsPerPageOptions,"pagination":pagination,"options":options},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.descending=$event},"update:sort-desc":function($event){_vm.descending=$event},"update:page":function($event){_vm.selectedPage=$event},"update:itemsPerPage":function($event){_vm.rowsPerPage=$event},"update:items-per-page":function($event){_vm.rowsPerPage=$event},"update:options":updateOptions},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"v-data-footer__select flex-grow-1"},[_vm._v(" Selected Page: "),_c(VSelect,{attrs:{"hide-details":"","items":_vm.availablePages},model:{value:(_vm.searchPage),callback:function ($$v) {_vm.searchPage=$$v},expression:"searchPage"}})],1)]},proxy:true}],null,true)})]}}:null,(_vm.searchRowsPerPage)?{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"v-data-footer__select flex-grow-1"},[_vm._v(" Selected Page: "),_c(VSelect,{attrs:{"hide-details":"","items":_vm.availablePages},model:{value:(_vm.searchPage),callback:function ($$v) {_vm.searchPage=$$v},expression:"searchPage"}})],1)]},proxy:true}:null,_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}}),_vm._l((_vm.$slots),function(_,slotName){return {key:slotName,fn:function(){return [_vm._t(slotName)]},proxy:true}})],null,true)},'v-data-table',_vm.$attrs,false))],2)}
var staticRenderFns = []

export { render, staticRenderFns }