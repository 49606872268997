import Vue from 'vue'

const financialReport = {
  state: {
    financialReport: [],
    selectedFinancialReport: {},
  },

  getters: {
    financialReport(state) {
      return state.financialReport
    },
    selectedFinancialReport(state) {
      return state.selectedFinancialReport
    },
  },

  mutations: {
    financialReport(state, payload) {
      state.financialReport = payload
    },
    selectedFinancialReport(state, payload) {
      state.selectedFinancialReport = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedFinancialReport(context, payload) {
      context.commit('selectedFinancialReport', payload)
    },
    loadFinancialReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'financialReport'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('financialReport', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedFinancialReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'financialReport/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedFinancialReport', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateFinancialReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `financialReport/${payload.FinancialReportID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'financialReport updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadFinancialReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createFinancialReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `financialReport`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'financialReport created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadFinancialReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveFinancialReport(context, payload) {
      console.devlog('save')
      return new Promise((resolve, reject) => {
        if (payload.FinancialReportID > 0) {
          context.dispatch('updateFinancialReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createFinancialReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteFinancialReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `financialReport/${payload.FinancialReportID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'financialReport deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadFinancialReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default financialReport
