import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"1250","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.openMessage),callback:function ($$v) {_vm.openMessage=$$v},expression:"openMessage"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"white--text text-h5",class:_vm.color},[_vm._v("Messages")]),_c(VCardText,{staticClass:"pa-4"},_vm._l((_vm.messages),function(message,index){return _c(VList,{key:index},[_c(VListItem,[_vm._v(_vm._s(message))])],1)}),1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.openMessage = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }