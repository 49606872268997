import {mapState, mapGetters, mapActions} from 'vuex'

//this is an example of how you can abstract out the mapGetters/mapState/mapActions from your components

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    signingIn: (state) => state.signingIn,
    authErrors: (state) => state.authErrors,
    loginType: (state) => state.loginType,
  }),
  ...mapGetters('auth', [
    'isLoggedIn',
    'isSuperUser',
    'isProjectCreator',
    'isSupervisor',
    'isValidator',
  ]),
}

export const authMethods = mapActions('auth', ['logOut', 'refreshToken'])
