import Vue from 'vue'

const includedWith = {
  state: {
    includedWith: [],
    selectedIncludedWith: {},
  },

  getters: {
    includedWith(state) {
      return state.includedWith
    },
    selectedIncludedWith(state) {
      return state.selectedIncludedWith
    },
  },

  mutations: {
    includedWith(state, payload) {
      state.includedWith = payload
    },
    selectedIncludedWith(state, payload) {
      state.selectedIncludedWith = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedIncludedWith(context, payload) {
      context.commit('selectedIncludedWith', payload)
    },
    loadIncludedWith(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'includedWith'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('includedWith', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedIncludedWith(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'includedWith/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedIncludedWith', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateIncludedWith(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `includedWith/${payload.RequirementLevelID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'includedWith updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadIncludedWith')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createIncludedWith(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `includedWith`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'includedWith created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadIncludedWith')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveIncludedWith(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.RequirementLevelID > 0) {
          context.dispatch('updateIncludedWith', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createIncludedWith', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteIncludedWith(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `includedWith/${payload.RequirementLevelID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'includedWith deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadIncludedWith')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default includedWith
