import Vue from 'vue'

const employeePayType = {
  state: {
    employeePayType: [],
    selectedEmployeePayType: {},
  },

  getters: {
    employeePayType(state) {
      return state.employeePayType
    },
    selectedEmployeePayType(state) {
      return state.selectedEmployeePayType
    },
  },

  mutations: {
    employeePayType(state, payload) {
      state.employeePayType = payload
    },
    selectedEmployeePayType(state, payload) {
      state.selectedEmployeePayType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedEmployeePayType(context, payload) {
      context.commit('selectedEmployeePayType', payload)
    },
    loadEmployeePayType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'EmployeePayType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('employeePayType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedEmployeePayType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'EmployeePayType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedEmployeePayType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateEmployeePayType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EmployeePayType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'employeePayType updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEmployeePayType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createEmployeePayType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EmployeePayType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'employeePayType created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEmployeePayType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveEmployeePayType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateEmployeePayType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createEmployeePayType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteEmployeePayType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EmployeePayType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'employeePayType deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEmployeePayType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default employeePayType
