import Vue from 'vue'

const projectFindings = {
  state: {
    projectFindings: [],
    selectedProjectFindings: {},
  },

  getters: {
    projectFindings(state) {
      return state.projectFindings
    },
    selectedProjectFindings(state) {
      return state.selectedProjectFindings
    },
  },

  mutations: {
    projectFindings(state, payload) {
      state.projectFindings = payload
    },
    selectedProjectFindings(state, payload) {
      state.selectedProjectFindings = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectFindings(context, payload) {
      context.commit('selectedProjectFindings', payload)
    },
    loadProjectFindings(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectFindings'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectFindings', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectFindings(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectFindings/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectFindings', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectFindings(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectFindings/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectFindings updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectFindings')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectFindings(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectFindings`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectFindings created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectFindings')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectFindings(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectFindings', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectFindings', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectFindings(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectFindings/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectFindings deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectFindings')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectFindings
