<template>
  <span>
    <v-tooltip :left="left" :top="top" :bottom="bottom" :right="right">
      <template v-slot:activator="{on}">
        <v-btn
          :class="buttonClass"
          :color="iconColor"
          v-bind="$attrs"
          @click.stop="dialog = true"
          icon
          v-on="on"
        >
          <v-icon :dark="dark">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      @keydown.esc="dialog = false"
      :max-width="maxWidth"
      scrollable
    >
      <v-card class="elevation-3">
        <v-card-title class="primary white--text text-h5">{{
          header
        }}</v-card-title>
        <v-card-text class="pa-4">
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = !dialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
export default {
  name: 'helpTooltip',
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-information',
    },
    header: {
      type: String,
      default: 'More Info',
    },
    tooltipText: {
      type: String,
      default: 'more info',
    },
    iconColor: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 1250,
    },
  },
  components: {},
  filters: {},
  data() {
    return {
      dialog: false,
    }
  },
  created() {},
  computed: {
    left() {
      return this.position == 'left'
    },
    right() {
      return this.position == 'right'
    },
    top() {
      return this.position == 'top'
    },
    bottom() {
      return this.position == 'bottom'
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>