import Vue from 'vue'

const project = {
  state: {
    selectedProjectProjectDetailsId: 0,
    projectDetailsRequestCount: 0,
    selectedProjectProjectDetails: {},
    selectedProjectSubEntityId: 0,
    subEntityRequestCount: 0,
    selectedProjectSubEntity: {},
    selectedProjectProjectBankingId: 0,
    projectBankingRequestCount: 0,
    selectedProjectProjectBanking: {},
    selectedProjectProjectLicenseId: 0,
    projectLicenseRequestCount: 0,
    selectedProjectProjectLicense: {},
    selectedProjectExciseStratumId: 0,
    exciseStratumRequestCount: 0,
    selectedProjectExciseStratum: [],
    selectedProjectProjectBudgetedHoursId: 0,
    projectBudgetedHoursRequestCount: 0,
    selectedProjectProjectBudgetedHours: [],
    selectedProjectProjectCollectionsId: 0,
    projectCollectionsRequestCount: 0,
    selectedProjectProjectCollections: [],
    selectedProjectProjectDateId: 0,
    projectDateRequestCount: 0,
    selectedProjectProjectDate: [],
    selectedProjectProjectExpensesId: 0,
    projectExpensesRequestCount: 0,
    selectedProjectProjectExpenses: [],
    selectedProjectProjectNoteId: 0,
    projectNoteRequestCount: 0,
    selectedProjectProjectNote: [],
    selectedProjectProjectRefundsId: 0,
    projectRefundsRequestCount: 0,
    selectedProjectProjectRefunds: [],
    selectedProjectProjectReportedValuesId: 0,
    projectReportedValuesRequestCount: 0,
    selectedProjectProjectReportedValues: [],
    selectedProjectProjectReviewsId: 0,
    projectReviewsRequestCount: 0,
    selectedProjectProjectReviews: [],
    selectedProjectTimeSheetEntryId: 0,
    timeSheetEntryRequestCount: 0,
    selectedProjectTimeSheetEntry: [],
    selectedProjectUserProjectId: 0,
    userProjectRequestCount: 0,
    selectedProjectUserProject: [],

  },

  getters: {
    selectedProjectProjectDetails(state) {
      return state.selectedProjectProjectDetails
    },
    projectDetailsLoading(state) {
        return state.projectDetailsRequestCount > 0
    },
    selectedProjectSubEntity(state) {
      return state.selectedProjectSubEntity
    },
    subEntityLoading(state) {
        return state.subEntityRequestCount > 0
    },
    selectedProjectProjectBanking(state) {
      return state.selectedProjectProjectBanking
    },
    projectBankingLoading(state) {
        return state.projectBankingRequestCount > 0
    },
    selectedProjectProjectLicense(state) {
      return state.selectedProjectProjectLicense
    },
    projectLicenseLoading(state) {
        return state.projectLicenseRequestCount > 0
    },
    selectedProjectExciseStratum(state) {
      return state.selectedProjectExciseStratum
    },
    exciseStratumLoading(state) {
        return state.exciseStratumRequestCount > 0
    },
    selectedProjectProjectBudgetedHours(state) {
      return state.selectedProjectProjectBudgetedHours
    },
    projectBudgetedHoursLoading(state) {
        return state.projectBudgetedHoursRequestCount > 0
    },
    selectedProjectProjectCollections(state) {
      return state.selectedProjectProjectCollections
    },
    projectCollectionsLoading(state) {
        return state.projectCollectionsRequestCount > 0
    },
    selectedProjectProjectDate(state) {
      return state.selectedProjectProjectDate
    },
    projectDateLoading(state) {
        return state.projectDateRequestCount > 0
    },
    selectedProjectProjectExpenses(state) {
      return state.selectedProjectProjectExpenses
    },
    projectExpensesLoading(state) {
        return state.projectExpensesRequestCount > 0
    },
    selectedProjectProjectNote(state) {
      return state.selectedProjectProjectNote
    },
    projectNoteLoading(state) {
        return state.projectNoteRequestCount > 0
    },
    selectedProjectProjectRefunds(state) {
      return state.selectedProjectProjectRefunds
    },
    projectRefundsLoading(state) {
        return state.projectRefundsRequestCount > 0
    },
    selectedProjectProjectReportedValues(state) {
      return state.selectedProjectProjectReportedValues
    },
    projectReportedValuesLoading(state) {
        return state.projectReportedValuesRequestCount > 0
    },
    selectedProjectProjectReviews(state) {
      return state.selectedProjectProjectReviews
    },
    projectReviewsLoading(state) {
        return state.projectReviewsRequestCount > 0
    },
    selectedProjectTimeSheetEntry(state) {
      return state.selectedProjectTimeSheetEntry
    },
    timeSheetEntryLoading(state) {
        return state.timeSheetEntryRequestCount > 0
    },
    selectedProjectUserProject(state) {
      return state.selectedProjectUserProject
    },
    userProjectLoading(state) {
        return state.userProjectRequestCount > 0
    },

  },

  mutations: {
    selectedProjectProjectDetails(state, payload) {
      state.selectedProjectProjectDetails = payload
    },
    selectedProjectProjectDetailsId(state, payload) {
      state.selectedProjectProjectDetailsId = payload
    },
    projectDetailsRequestCount(state, payload) {
      state.projectDetailsRequestCount = payload
    },
    increaseProjectDetailsRequestCount(state, payload) {
      state.projectDetailsRequestCount = state.projectDetailsRequestCount + 1
    },
    decreaseProjectDetailsRequestCount(state, payload) {
      state.projectDetailsRequestCount = state.projectDetailsRequestCount - 1
    },
    selectedProjectSubEntity(state, payload) {
      state.selectedProjectSubEntity = payload
    },
    selectedProjectSubEntityId(state, payload) {
      state.selectedProjectSubEntityId = payload
    },
    subEntityRequestCount(state, payload) {
      state.subEntityRequestCount = payload
    },
    increaseSubEntityRequestCount(state, payload) {
      state.subEntityRequestCount = state.subEntityRequestCount + 1
    },
    decreaseSubEntityRequestCount(state, payload) {
      state.subEntityRequestCount = state.subEntityRequestCount - 1
    },
    selectedProjectProjectBanking(state, payload) {
      state.selectedProjectProjectBanking = payload
    },
    selectedProjectProjectBankingId(state, payload) {
      state.selectedProjectProjectBankingId = payload
    },
    projectBankingRequestCount(state, payload) {
      state.projectBankingRequestCount = payload
    },
    increaseProjectBankingRequestCount(state, payload) {
      state.projectBankingRequestCount = state.projectBankingRequestCount + 1
    },
    decreaseProjectBankingRequestCount(state, payload) {
      state.projectBankingRequestCount = state.projectBankingRequestCount - 1
    },
    selectedProjectProjectLicense(state, payload) {
      state.selectedProjectProjectLicense = payload
    },
    selectedProjectProjectLicenseId(state, payload) {
      state.selectedProjectProjectLicenseId = payload
    },
    projectLicenseRequestCount(state, payload) {
      state.projectLicenseRequestCount = payload
    },
    increaseProjectLicenseRequestCount(state, payload) {
      state.projectLicenseRequestCount = state.projectLicenseRequestCount + 1
    },
    decreaseProjectLicenseRequestCount(state, payload) {
      state.projectLicenseRequestCount = state.projectLicenseRequestCount - 1
    },
    selectedProjectExciseStratum(state, payload) {
      state.selectedProjectExciseStratum = payload
    },
    selectedProjectExciseStratumId(state, payload) {
      state.selectedProjectExciseStratumId = payload
    },
    exciseStratumRequestCount(state, payload) {
      state.exciseStratumRequestCount = payload
    },
    increaseExciseStratumRequestCount(state, payload) {
      state.exciseStratumRequestCount = state.exciseStratumRequestCount + 1
    },
    decreaseExciseStratumRequestCount(state, payload) {
      state.exciseStratumRequestCount = state.exciseStratumRequestCount - 1
    },
    selectedProjectProjectBudgetedHours(state, payload) {
      state.selectedProjectProjectBudgetedHours = payload
    },
    selectedProjectProjectBudgetedHoursId(state, payload) {
      state.selectedProjectProjectBudgetedHoursId = payload
    },
    projectBudgetedHoursRequestCount(state, payload) {
      state.projectBudgetedHoursRequestCount = payload
    },
    increaseProjectBudgetedHoursRequestCount(state, payload) {
      state.projectBudgetedHoursRequestCount = state.projectBudgetedHoursRequestCount + 1
    },
    decreaseProjectBudgetedHoursRequestCount(state, payload) {
      state.projectBudgetedHoursRequestCount = state.projectBudgetedHoursRequestCount - 1
    },
    selectedProjectProjectCollections(state, payload) {
      state.selectedProjectProjectCollections = payload
    },
    selectedProjectProjectCollectionsId(state, payload) {
      state.selectedProjectProjectCollectionsId = payload
    },
    projectCollectionsRequestCount(state, payload) {
      state.projectCollectionsRequestCount = payload
    },
    increaseProjectCollectionsRequestCount(state, payload) {
      state.projectCollectionsRequestCount = state.projectCollectionsRequestCount + 1
    },
    decreaseProjectCollectionsRequestCount(state, payload) {
      state.projectCollectionsRequestCount = state.projectCollectionsRequestCount - 1
    },
    selectedProjectProjectDate(state, payload) {
      state.selectedProjectProjectDate = payload
    },
    selectedProjectProjectDateId(state, payload) {
      state.selectedProjectProjectDateId = payload
    },
    projectDateRequestCount(state, payload) {
      state.projectDateRequestCount = payload
    },
    increaseProjectDateRequestCount(state, payload) {
      state.projectDateRequestCount = state.projectDateRequestCount + 1
    },
    decreaseProjectDateRequestCount(state, payload) {
      state.projectDateRequestCount = state.projectDateRequestCount - 1
    },
    selectedProjectProjectExpenses(state, payload) {
      state.selectedProjectProjectExpenses = payload
    },
    selectedProjectProjectExpensesId(state, payload) {
      state.selectedProjectProjectExpensesId = payload
    },
    projectExpensesRequestCount(state, payload) {
      state.projectExpensesRequestCount = payload
    },
    increaseProjectExpensesRequestCount(state, payload) {
      state.projectExpensesRequestCount = state.projectExpensesRequestCount + 1
    },
    decreaseProjectExpensesRequestCount(state, payload) {
      state.projectExpensesRequestCount = state.projectExpensesRequestCount - 1
    },
    selectedProjectProjectNote(state, payload) {
      state.selectedProjectProjectNote = payload
    },
    selectedProjectProjectNoteId(state, payload) {
      state.selectedProjectProjectNoteId = payload
    },
    projectNoteRequestCount(state, payload) {
      state.projectNoteRequestCount = payload
    },
    increaseProjectNoteRequestCount(state, payload) {
      state.projectNoteRequestCount = state.projectNoteRequestCount + 1
    },
    decreaseProjectNoteRequestCount(state, payload) {
      state.projectNoteRequestCount = state.projectNoteRequestCount - 1
    },
    selectedProjectProjectRefunds(state, payload) {
      state.selectedProjectProjectRefunds = payload
    },
    selectedProjectProjectRefundsId(state, payload) {
      state.selectedProjectProjectRefundsId = payload
    },
    projectRefundsRequestCount(state, payload) {
      state.projectRefundsRequestCount = payload
    },
    increaseProjectRefundsRequestCount(state, payload) {
      state.projectRefundsRequestCount = state.projectRefundsRequestCount + 1
    },
    decreaseProjectRefundsRequestCount(state, payload) {
      state.projectRefundsRequestCount = state.projectRefundsRequestCount - 1
    },
    selectedProjectProjectReportedValues(state, payload) {
      state.selectedProjectProjectReportedValues = payload
    },
    selectedProjectProjectReportedValuesId(state, payload) {
      state.selectedProjectProjectReportedValuesId = payload
    },
    projectReportedValuesRequestCount(state, payload) {
      state.projectReportedValuesRequestCount = payload
    },
    increaseProjectReportedValuesRequestCount(state, payload) {
      state.projectReportedValuesRequestCount = state.projectReportedValuesRequestCount + 1
    },
    decreaseProjectReportedValuesRequestCount(state, payload) {
      state.projectReportedValuesRequestCount = state.projectReportedValuesRequestCount - 1
    },
    selectedProjectProjectReviews(state, payload) {
      state.selectedProjectProjectReviews = payload
    },
    selectedProjectProjectReviewsId(state, payload) {
      state.selectedProjectProjectReviewsId = payload
    },
    projectReviewsRequestCount(state, payload) {
      state.projectReviewsRequestCount = payload
    },
    increaseProjectReviewsRequestCount(state, payload) {
      state.projectReviewsRequestCount = state.projectReviewsRequestCount + 1
    },
    decreaseProjectReviewsRequestCount(state, payload) {
      state.projectReviewsRequestCount = state.projectReviewsRequestCount - 1
    },
    selectedProjectTimeSheetEntry(state, payload) {
      state.selectedProjectTimeSheetEntry = payload
    },
    selectedProjectTimeSheetEntryId(state, payload) {
      state.selectedProjectTimeSheetEntryId = payload
    },
    timeSheetEntryRequestCount(state, payload) {
      state.timeSheetEntryRequestCount = payload
    },
    increaseTimeSheetEntryRequestCount(state, payload) {
      state.timeSheetEntryRequestCount = state.timeSheetEntryRequestCount + 1
    },
    decreaseTimeSheetEntryRequestCount(state, payload) {
      state.timeSheetEntryRequestCount = state.timeSheetEntryRequestCount - 1
    },
    selectedProjectUserProject(state, payload) {
      state.selectedProjectUserProject = payload
    },
    selectedProjectUserProjectId(state, payload) {
      state.selectedProjectUserProjectId = payload
    },
    userProjectRequestCount(state, payload) {
      state.userProjectRequestCount = payload
    },
    increaseUserProjectRequestCount(state, payload) {
      state.userProjectRequestCount = state.userProjectRequestCount + 1
    },
    decreaseUserProjectRequestCount(state, payload) {
      state.userProjectRequestCount = state.userProjectRequestCount - 1
    },

  },

  actions: {
    setSelectedProjectProjectDetails(context, payload) {
      context.commit('selectedProjectProjectDetails', payload)
    },
    loadSelectedProjectProjectDetails(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectDetails.length > 0 && selectedId != context.state.selectedProjectProjectDetailsId ) {
          context.commit('selectedProjectProjectDetails', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectDetails'
          context.commit('increaseProjectDetailsRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectDetails', res.data)
              context.commit('selectedProjectProjectDetailsId', selectedId)
              context.commit('decreaseProjectDetailsRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectDetailsRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectSubEntity(context, payload) {
      context.commit('selectedProjectSubEntity', payload)
    },
    loadSelectedProjectSubEntity(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectSubEntity.length > 0 && selectedId != context.state.selectedProjectSubEntityId ) {
          context.commit('selectedProjectSubEntity', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/SubEntity'
          context.commit('increaseSubEntityRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectSubEntity', res.data)
              context.commit('selectedProjectSubEntityId', selectedId)
              context.commit('decreaseSubEntityRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseSubEntityRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectBanking(context, payload) {
      context.commit('selectedProjectProjectBanking', payload)
    },
    loadSelectedProjectProjectBanking(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectBanking.length > 0 && selectedId != context.state.selectedProjectProjectBankingId ) {
          context.commit('selectedProjectProjectBanking', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectBanking'
          context.commit('increaseProjectBankingRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectBanking', res.data)
              context.commit('selectedProjectProjectBankingId', selectedId)
              context.commit('decreaseProjectBankingRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectBankingRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectLicense(context, payload) {
      context.commit('selectedProjectProjectLicense', payload)
    },
    loadSelectedProjectProjectLicense(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectLicense.length > 0 && selectedId != context.state.selectedProjectProjectLicenseId ) {
          context.commit('selectedProjectProjectLicense', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectLicense'
          context.commit('increaseProjectLicenseRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectLicense', res.data)
              context.commit('selectedProjectProjectLicenseId', selectedId)
              context.commit('decreaseProjectLicenseRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectLicenseRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectExciseStratum(context, payload) {
      context.commit('selectedProjectExciseStratum', payload)
    },
    loadSelectedProjectExciseStratum(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectExciseStratum.length > 0 && selectedId != context.state.selectedProjectExciseStratumId ) {
          context.commit('selectedProjectExciseStratum', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ExciseStratum'
          context.commit('increaseExciseStratumRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectExciseStratum', res.data)
              context.commit('selectedProjectExciseStratumId', selectedId)
              context.commit('decreaseExciseStratumRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseExciseStratumRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectBudgetedHours(context, payload) {
      context.commit('selectedProjectProjectBudgetedHours', payload)
    },
    loadSelectedProjectProjectBudgetedHours(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectBudgetedHours.length > 0 && selectedId != context.state.selectedProjectProjectBudgetedHoursId ) {
          context.commit('selectedProjectProjectBudgetedHours', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectBudgetedHours'
          context.commit('increaseProjectBudgetedHoursRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectBudgetedHours', res.data)
              context.commit('selectedProjectProjectBudgetedHoursId', selectedId)
              context.commit('decreaseProjectBudgetedHoursRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectBudgetedHoursRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectCollections(context, payload) {
      context.commit('selectedProjectProjectCollections', payload)
    },
    loadSelectedProjectProjectCollections(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectCollections.length > 0 && selectedId != context.state.selectedProjectProjectCollectionsId ) {
          context.commit('selectedProjectProjectCollections', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectCollections'
          context.commit('increaseProjectCollectionsRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectCollections', res.data)
              context.commit('selectedProjectProjectCollectionsId', selectedId)
              context.commit('decreaseProjectCollectionsRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectCollectionsRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectDate(context, payload) {
      context.commit('selectedProjectProjectDate', payload)
    },
    loadSelectedProjectProjectDate(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectDate.length > 0 && selectedId != context.state.selectedProjectProjectDateId ) {
          context.commit('selectedProjectProjectDate', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectDate'
          context.commit('increaseProjectDateRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectDate', res.data)
              context.commit('selectedProjectProjectDateId', selectedId)
              context.commit('decreaseProjectDateRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectDateRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectExpenses(context, payload) {
      context.commit('selectedProjectProjectExpenses', payload)
    },
    loadSelectedProjectProjectExpenses(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectExpenses.length > 0 && selectedId != context.state.selectedProjectProjectExpensesId ) {
          context.commit('selectedProjectProjectExpenses', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectExpenses'
          context.commit('increaseProjectExpensesRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectExpenses', res.data)
              context.commit('selectedProjectProjectExpensesId', selectedId)
              context.commit('decreaseProjectExpensesRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectExpensesRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectNote(context, payload) {
      context.commit('selectedProjectProjectNote', payload)
    },
    loadSelectedProjectProjectNote(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectNote.length > 0 && selectedId != context.state.selectedProjectProjectNoteId ) {
          context.commit('selectedProjectProjectNote', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectNote'
          context.commit('increaseProjectNoteRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectNote', res.data)
              context.commit('selectedProjectProjectNoteId', selectedId)
              context.commit('decreaseProjectNoteRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectNoteRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectRefunds(context, payload) {
      context.commit('selectedProjectProjectRefunds', payload)
    },
    loadSelectedProjectProjectRefunds(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectRefunds.length > 0 && selectedId != context.state.selectedProjectProjectRefundsId ) {
          context.commit('selectedProjectProjectRefunds', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectRefunds'
          context.commit('increaseProjectRefundsRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectRefunds', res.data)
              context.commit('selectedProjectProjectRefundsId', selectedId)
              context.commit('decreaseProjectRefundsRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectRefundsRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectReportedValues(context, payload) {
      context.commit('selectedProjectProjectReportedValues', payload)
    },
    loadSelectedProjectProjectReportedValues(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectReportedValues.length > 0 && selectedId != context.state.selectedProjectProjectReportedValuesId ) {
          context.commit('selectedProjectProjectReportedValues', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectReportedValues'
          context.commit('increaseProjectReportedValuesRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectReportedValues', res.data)
              context.commit('selectedProjectProjectReportedValuesId', selectedId)
              context.commit('decreaseProjectReportedValuesRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectReportedValuesRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectProjectReviews(context, payload) {
      context.commit('selectedProjectProjectReviews', payload)
    },
    loadSelectedProjectProjectReviews(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectProjectReviews.length > 0 && selectedId != context.state.selectedProjectProjectReviewsId ) {
          context.commit('selectedProjectProjectReviews', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ProjectReviews'
          context.commit('increaseProjectReviewsRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectProjectReviews', res.data)
              context.commit('selectedProjectProjectReviewsId', selectedId)
              context.commit('decreaseProjectReviewsRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseProjectReviewsRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectTimeSheetEntry(context, payload) {
      context.commit('selectedProjectTimeSheetEntry', payload)
    },
    loadSelectedProjectTimeSheetEntry(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectTimeSheetEntry.length > 0 && selectedId != context.state.selectedProjectTimeSheetEntryId ) {
          context.commit('selectedProjectTimeSheetEntry', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/TimeSheetEntry'
          context.commit('increaseTimeSheetEntryRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectTimeSheetEntry', res.data)
              context.commit('selectedProjectTimeSheetEntryId', selectedId)
              context.commit('decreaseTimeSheetEntryRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseTimeSheetEntryRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },
    setSelectedProjectUserProject(context, payload) {
      context.commit('selectedProjectUserProject', payload)
    },
    loadSelectedProjectUserProject(context, payload) {
      if (context.state.selectedProject && context.state.selectedProject.ID > 0) {
        let selectedId = context.state.selectedProject.ID
        if (context.state.selectedProject && context.state.selectedProjectUserProject.length > 0 && selectedId != context.state.selectedProjectUserProjectId ) {
          context.commit('selectedProjectUserProject', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/UserProject'
          context.commit('increaseUserProjectRequestCount')
          Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('selectedProjectUserProject', res.data)
              context.commit('selectedProjectUserProjectId', selectedId)
              context.commit('decreaseUserProjectRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseUserProjectRequestCount')
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        })
      }
    },

  },
}

export default project