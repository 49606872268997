import Vue from 'vue'

const holidayEntry = {
  state: {
    holidayEntry: [],
    selectedHolidayEntry: {},
  },

  getters: {
    holidayEntry(state) {
      return state.holidayEntry
    },
    selectedHolidayEntry(state) {
      return state.selectedHolidayEntry
    },
  },

  mutations: {
    holidayEntry(state, payload) {
      state.holidayEntry = payload
    },
    selectedHolidayEntry(state, payload) {
      state.selectedHolidayEntry = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedHolidayEntry(context, payload) {
      context.commit('selectedHolidayEntry', payload)
    },
    loadHolidayEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HolidayEntry'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('holidayEntry', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedHolidayEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HolidayEntry/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedHolidayEntry', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateHolidayEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `HolidayEntry/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'holiday entry updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadHolidayEntry')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createHolidayEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `HolidayEntry`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'holiday entry created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadHolidayEntry')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveHolidayEntry(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateHolidayEntry', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createHolidayEntry', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteHolidayEntry(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `HolidayEntry/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'holiday entry deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadHolidayEntry')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default holidayEntry
