import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const exciseStratum = {
  state: {
    exciseStratum: [],
    selectedExciseStratum: {},
    selectedExciseStratumOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    exciseStratum(state) {
      return state.exciseStratum
    },
    selectedExciseStratum(state) {
      return state.selectedExciseStratum
    },
    selectedExciseStratumOriginal(state) {
      return state.selectedExciseStratumOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    exciseStratum(state, payload) {
      state.exciseStratum = payload
    },
    selectedExciseStratum(state, payload) {
      state.selectedExciseStratum = JSON.parse(JSON.stringify(payload))
    },
    selectedExciseStratumOriginal(state, payload) {
      state.selectedExciseStratumOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedExciseStratum(context, payload) {
      context.commit('selectedExciseStratum', payload)
    },
    loadExciseStratum(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ExciseStratum'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('exciseStratum', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedExciseStratum(context, payload) {
      if (context.state.selectedExciseStratum && context.state.selectedExciseStratum.ID > 0) {
        context.dispatch('loadSelectedExciseStratum', context.state.selectedExciseStratum.ID)

      }
    },
    loadSelectedExciseStratum(context, payload) {
      if (context.state.selectedExciseStratum && context.state.selectedExciseStratum.ID != payload) {
        context.commit('selectedExciseStratum', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'ExciseStratum/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedExciseStratum', res.data)
            context.commit('selectedExciseStratumOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadExciseStratum(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadExciseStratum')
      }, )
    },
    createExciseStratum(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseStratum`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise stratum created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseStratum')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchExciseStratum(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedExciseStratum
        if (payload.ID && 
        payload.ID == context.state.selectedExciseStratumOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedExciseStratumOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `ExciseStratum/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise stratum updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadExciseStratum')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateExciseStratum(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `ExciseStratum/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise stratum updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseStratum')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveExciseStratum(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateExciseStratum', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createExciseStratum', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteExciseStratum(context, payload){
      return new Promise((resolve, reject) => {
        const url = `ExciseStratum/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise stratum deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadExciseStratum')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default exciseStratum