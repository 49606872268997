import Vue from 'vue'

const projectRefunds = {
  state: {
    projectRefunds: [],
    selectedProjectRefunds: {},
  },

  getters: {
    projectRefunds(state) {
      return state.projectRefunds
    },
    selectedProjectRefunds(state) {
      return state.selectedProjectRefunds
    },
  },

  mutations: {
    projectRefunds(state, payload) {
      state.projectRefunds = payload
    },
    selectedProjectRefunds(state, payload) {
      state.selectedProjectRefunds = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectRefunds(context, payload) {
      context.commit('selectedProjectRefunds', payload)
    },
    loadProjectRefunds(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectRefunds'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectRefunds', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectRefunds(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectRefunds/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectRefunds', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectRefunds(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectRefunds/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectRefunds updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectRefunds')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectRefunds(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectRefunds`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectRefunds created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectRefunds')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectRefunds(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectRefunds', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectRefunds', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectRefunds(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectRefunds/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectRefunds deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectRefunds')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectRefunds
