import Vue from 'vue'

const luStandardBudgetHours = {
  state: {
    luStandardBudgetHours: [],
    selectedLuStandardBudgetHours: {},
  },

  getters: {
    luStandardBudgetHours(state) {
      return state.luStandardBudgetHours
    },
    selectedLuStandardBudgetHours(state) {
      return state.selectedLuStandardBudgetHours
    },
  },

  mutations: {
    luStandardBudgetHours(state, payload) {
      state.luStandardBudgetHours = payload
    },
    selectedLuStandardBudgetHours(state, payload) {
      state.selectedLuStandardBudgetHours = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuStandardBudgetHours(context, payload) {
      context.commit('selectedLuStandardBudgetHours', payload)
    },
    loadLuStandardBudgetHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuStandardBudgetHours'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luStandardBudgetHours', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuStandardBudgetHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuStandardBudgetHours/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuStandardBudgetHours', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuStandardBudgetHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuStandardBudgetHours/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luStandardBudgetHours updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuStandardBudgetHours')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuStandardBudgetHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuStandardBudgetHours`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luStandardBudgetHours created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuStandardBudgetHours')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuStandardBudgetHours(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuStandardBudgetHours', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuStandardBudgetHours', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuStandardBudgetHours(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuStandardBudgetHours/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'luStandardBudgetHours deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuStandardBudgetHours')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luStandardBudgetHours
