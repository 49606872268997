import Vue from 'vue'
import { debounce, range } from 'lodash'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
      const searchSortByDefault = 'ReportPeriodFrom'
const exciseDORLicReturns= {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: [searchSortByDefault],
    searchDescending: [true],
    searchRowsPerPage: 25,
    searchText: '',
    lookupText: '',
    //templateTODO: uncomment as used in ExciseDORLicReturnsSearchParams
    searchStartDate: null,
    searchEndDate: null,
    searchLicenseNumber: ''
  },

  getters: {
    searchParameters(state) {
      var sortDirection
      if (Array.isArray(state.searchDescending)) {
        sortDirection = state.searchDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.searchDescending ? ['desc'] : ['asc']
      }
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection,
        searchText: state.searchText,
        //templateTODO: uncomment as used in ExciseDORLicReturnsSearchParams
        startDate: state.searchStartDate,
        endDate: state.searchEndDate,
        licenseNumber: state.searchLicenseNumber,
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    //TemplateTODO: uncomment as needed
    searchStartDate(state, payload) {
     state.searchStartDate = payload
    },
    searchEndDate(state, payload) {
     state.searchEndDate = payload
    },
    searchLicenseNumber(state, payload) {
     state.searchLicenseNumber = payload
    },
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupExciseDORLicReturns')
    }, 300),
    searchBounce: _.debounce((context) => {
      context.dispatch('searchExciseDORLicReturns')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.searchSortBy)
      ) {
        context.commit('searchSortBy', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.searchDescending)
      ) {
        context.commit('searchDescending', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText && payload != null) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    setSearchStartDate(context, payload) {
      if (payload != context.state.searchStartDate) {
        context.commit('searchStartDate', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchEndDate(context, payload) {
      if (payload != context.state.searchEndDate) {
        context.commit('searchEndDate', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchLicenseNumber(context, payload) {
      if (payload != context.state.searchLicenseNumber) {
        context.commit('searchLicenseNumber', payload)
        context.dispatch('searchBounce', context)
      }
    },
    async searchExciseDORLicReturns(context, payload) {
      if (!context.state.searchSortBy) {
        context.commit('searchSortBy', [searchSortByDefault])
        context.commit('searchDescending', [true])
      }
      if (!Array.isArray(context.state.searchSortBy)) {
        if (context.state.searchSortBy) {
          context.commit('searchSortBy', [context.state.searchSortBy])
          context.commit('searchDescending', [context.state.searchDescending])
        } else {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
      }
      if (!context.state.searchText) {
          context.commit('searchText', '')
      }
      context.commit('increaseSearchCount')
      var params = context.getters.searchParameters
      var paramString = JSON.stringify(params)
      const url = `ExciseDORLicReturns/Search`
      try {
        let res = await Vue.prototype.$axios.post(url, params)
        if (JSON.stringify(context.getters.searchParameters) == paramString) {
        context.commit('exciseDORLicReturns', res.data.Entries)
        context.commit('searchRowsPerPage', res.data.Page.Size)
        context.commit('searchPage', res.data.Page.Number + 1)
        context.commit('searchTotal', res.data.Page.TotalElements)
        }
        context.commit('decreaseSearchCount')
        return res
      }catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        context.commit('decreaseSearchCount')
        throw err
      }
    },
    async lookupExciseDORLicReturns(context, payload) {
      if (context.state.lookupText) {
          context.commit('increaseSearchCount')
          const url = `ExciseDORLicReturns/Lookup?searchText=${context.state.lookupText}`
          try {
            let res = await Vue.prototype.$axios.get(url)
            context.commit('exciseDORLicReturns', res.data)
            context.commit('decreaseSearchCount')
            return res
          } catch (err) {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            context.commit('decreaseSearchCount')
            throw err
          }
      }
    },
  },
}

export default
exciseDORLicReturns