import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luSpecialDistrict = {
  state: {
    luSpecialDistrict: [],
    selectedLuSpecialDistrict: {},
    selectedLuSpecialDistrictOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luSpecialDistrict(state) {
      return state.luSpecialDistrict
    },
    selectedLuSpecialDistrict(state) {
      return state.selectedLuSpecialDistrict
    },
    selectedLuSpecialDistrictOriginal(state) {
      return state.selectedLuSpecialDistrictOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luSpecialDistrict(state, payload) {
      state.luSpecialDistrict = payload
    },
    selectedLuSpecialDistrict(state, payload) {
      state.selectedLuSpecialDistrict = JSON.parse(JSON.stringify(payload))
    },
    selectedLuSpecialDistrictOriginal(state, payload) {
      state.selectedLuSpecialDistrictOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuSpecialDistrict(context, payload) {
      context.commit('selectedLuSpecialDistrict', payload)
    },
    loadLuSpecialDistrict(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuSpecialDistrict'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luSpecialDistrict', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuSpecialDistrict(context, payload) {
      if (context.state.selectedLuSpecialDistrict && context.state.selectedLuSpecialDistrict.ID > 0) {
        context.dispatch('loadSelectedLuSpecialDistrict', context.state.selectedLuSpecialDistrict.ID)

      }
    },
    loadSelectedLuSpecialDistrict(context, payload) {
      if (context.state.selectedLuSpecialDistrict && context.state.selectedLuSpecialDistrict.ID != payload) {
        context.commit('selectedLuSpecialDistrict', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'LuSpecialDistrict/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuSpecialDistrict', res.data)
            context.commit('selectedLuSpecialDistrictOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuSpecialDistrict(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuSpecialDistrict')
      }, )
    },
    createLuSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuSpecialDistrict`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'special district created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuSpecialDistrict')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchLuSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedLuSpecialDistrict
        if (payload.ID && 
        payload.ID == context.state.selectedLuSpecialDistrictOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedLuSpecialDistrictOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `LuSpecialDistrict/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'special district updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadLuSpecialDistrict')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateLuSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `LuSpecialDistrict/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'special district updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuSpecialDistrict')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuSpecialDistrict', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuSpecialDistrict', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuSpecialDistrict(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuSpecialDistrict/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'special district deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuSpecialDistrict')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luSpecialDistrict