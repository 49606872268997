// import '@babel/polyfill'
import Vue from 'vue'
// import VueCompositionAPI from '@vue/composition-api'
import {provide} from 'vue'
import './plugins/axios'
// import './plugins/gapi'
import './plugins/google'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from '@router'
import store from '@state/store'
import '@components/_globals'
import './plugins/logger'
import '@filters'
import './registerServiceWorker'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

// Vue.use(VueCompositionAPI)
window.eventBus = new Vue()

Vue.config.productionTip = false
Vue.prototype.$filters = Vue.options.filters

new Vue({
  setup() {
    provide('vuex-store', store)
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
