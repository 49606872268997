<template>
  <v-row>
    <v-col sm="12">
      <v-card
        ><v-expansion-panels v-if="collapsable" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="text-h5 text-left"
              :class="headerClass"
              ><span>
                <slot name="header">{{ headerText }}</slot>
              </span>

              <template v-if="textHeaderClass" v-slot:actions>
                <v-icon :class="textHeaderClass">$vuetify.icons.expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <slot name="content"></slot>
              <slot></slot>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else>
          <v-list-item :class="headerClass">
            <v-list-item-content>
              <v-list-item-title class="text-h5 text-left" :class="headerClass"
                ><slot name="header">{{ headerText }}</slot></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <slot name="content"></slot>
            <slot></slot>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    collapsable: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
    },
    headerClass: {
      type: String,
      default: 'white--text primary',
    },
  },
  components: {},
  filters: {},
  data() {
    return {
      panel: [0],
    }
  },
  created() {},
  computed: {
    textHeaderClass() {
      var classes = this.headerClass.split(' ')
      var textClass = classes.find((c) => {
        return c.endsWith('--text')
      })
      if (textClass) {
        return textClass
      } else {
        return null
      }
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>