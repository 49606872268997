import Vue from 'vue'

const luReport = {
  state: {
    luReport: [],
    selectedLuReport: {},
  },

  getters: {
    luReport(state) {
      return state.luReport
    },
    selectedLuReport(state) {
      return state.selectedLuReport
    },
  },

  mutations: {
    luReport(state, payload) {
      state.luReport = payload
    },
    selectedLuReport(state, payload) {
      state.selectedLuReport = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuReport(context, payload) {
      context.commit('selectedLuReport', payload)
    },
    loadLuReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuReport'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luReport', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuReport/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuReport', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuReport/${payload.Id}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'report updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuReport`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'report created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuReport(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.Id > 0) {
          context.dispatch('updateLuReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuReport/${payload.Id}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'report deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luReport
