import Vue from 'vue'

const entityContact = {
  state: {
    entityContact: [],
    selectedEntityContact: {},
  },

  getters: {
    entityContact(state) {
      return state.entityContact
    },
    selectedEntityContact(state) {
      return state.selectedEntityContact
    },
  },

  mutations: {
    entityContact(state, payload) {
      state.entityContact = payload
    },
    selectedEntityContact(state, payload) {
      state.selectedEntityContact = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedEntityContact(context, payload) {
      context.commit('selectedEntityContact', payload)
    },
    loadEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'EntityContact'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('entityContact', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'EntityContact/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedEntityContact', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EntityContact/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEntityContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EntityContact`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEntityContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateEntityContact', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createEntityContact', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteEntityContact(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EntityContact/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'contact deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEntityContact')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default entityContact
