import Vue from 'vue'

const savedQueriesProject = {
  state: {
    savedQueriesProject: [],
    selectedSavedQueriesProject: {},
  },

  getters: {
    savedQueriesProject(state) {
      return state.savedQueriesProject
    },
    selectedSavedQueriesProject(state) {
      return state.selectedSavedQueriesProject
    },
  },

  mutations: {
    savedQueriesProject(state, payload) {
      state.savedQueriesProject = payload
    },
    selectedSavedQueriesProject(state, payload) {
      state.selectedSavedQueriesProject = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedSavedQueriesProject(context, payload) {
      context.commit('selectedSavedQueriesProject', payload)
    },
    loadSavedQueriesProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SavedQueriesProject'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('savedQueriesProject', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedSavedQueriesProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SavedQueriesProject/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedSavedQueriesProject', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateSavedQueriesProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SavedQueriesProject/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'saved query updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSavedQueriesProject')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createSavedQueriesProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SavedQueriesProject`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'saved query created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSavedQueriesProject')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveSavedQueriesProject(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateSavedQueriesProject', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createSavedQueriesProject', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteSavedQueriesProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SavedQueriesProject/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'saved query deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSavedQueriesProject')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default savedQueriesProject
