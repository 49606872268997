<!-- BODY LAYOUT -->
<template>
  <div id="app">
    <v-app>
      <Layout>
        <v-main>
          <!--
        $route.fullPath - will always load components as distinct components
        this will allow you to navigate from one instance of a component to another
        (from one item to another) and it will recreate the component so that the data is changed
        -->
          <keep-alive include="entities,userprojects">
            <RouterView :key="$route.fullPath" />
          </keep-alive>
        </v-main>
      </Layout>
    </v-app>
  </div>
</template>


<!-- SCRIPTS -->
<script>
import appConfig from '@src/app.config'
import {authMethods, authComputed} from '@state/helpers'
import mainVue from './router/layouts/main.vue'

export default {
  name: 'App',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  mounted() {
    this.setUpTokenRefresh()
    this.refreshToken()
    let localDarkModeSetting = localStorage.getItem('darkMode')
    if (localDarkModeSetting != null) {
      setTimeout(
        () =>
          (this.$vuetify.theme.dark =
            localDarkModeSetting === 'true' ? true : false),
        0
      )
    } else {
      const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      darkMediaQuery.addEventListener('change', (e) => {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      })
      if (darkMediaQuery.matches) {
        // console.info('change default light to dark theme')
        // need to set 0 sec timeout to set the dark more after mounted event, due to some bug in the framework
        setTimeout(() => (this.$vuetify.theme.dark = true), 0)
      }
    }
  },
  computed: {...authComputed},
  methods: {
    ...authMethods,
    setUpTokenRefresh() {
      try {
        //this refreshes the etsjwt token
        window.setInterval(() => {
          this.refreshToken()
        }, 1000 * 10 * 60 * 20)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
  },
  components: {Layout: mainVue},
}
</script>

<!-- STYLES -->
<style lang="scss">
// @import '@design';
.pointer:hover {
  cursor: pointer;
}
</style>


