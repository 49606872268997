/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.info('Service worker has been registered.')
    },
    cached() {
      console.info('Content has been cached for offline use.')
    },
    updatefound() {
      console.info('New content is downloading.')
    },
    updated(registration) {
      console.info('New content is available; please refresh.')
      let confirmationResult = confirm(
        'New content found! Do you want to reload the app?'
      )
      if (confirmationResult)
        registration.waiting.postMessage({
          action: 'skipWaiting',
        })
    },
    offline() {
      console.info(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
  let refreshing
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', (e) => {
      if (refreshing) return
      window.location.reload()
      refreshing = true
    })
  }
}
