import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const exciseDORLicReturns = {
  state: {
    exciseDORLicReturns: [],
    selectedExciseDORLicReturns: {},
    selectedExciseDORLicReturnsOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    exciseDORLicReturns(state) {
      return state.exciseDORLicReturns
    },
    selectedExciseDORLicReturns(state) {
      return state.selectedExciseDORLicReturns
    },
    selectedExciseDORLicReturnsOriginal(state) {
      return state.selectedExciseDORLicReturnsOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    exciseDORLicReturns(state, payload) {
      state.exciseDORLicReturns = payload
    },
    selectedExciseDORLicReturns(state, payload) {
      state.selectedExciseDORLicReturns = JSON.parse(JSON.stringify(payload))
    },
    selectedExciseDORLicReturnsOriginal(state, payload) {
      state.selectedExciseDORLicReturnsOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadExciseDORLicReturns(context, payload) {
      const url = 'ExciseDORLicReturns'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('exciseDORLicReturns', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadExciseDORLicReturns(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadExciseDORLicReturns')
      }, )
    },
    async createExciseDORLicReturns(context, payload){
      const url = `ExciseDORLicReturns`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'excise dor lic returns created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadExciseDORLicReturns')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default exciseDORLicReturns