import Vue from 'vue'

const activityCodes = {
  state: {
    activityCodes: [],
    selectedActivityCodes: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    activityCodes(state) {
      return state.activityCodes
    },
    selectedActivityCodes(state) {
      return state.selectedActivityCodes
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    activityCodes(state, payload) {
      state.activityCodes = payload
    },
    selectedActivityCodes(state, payload) {
      state.selectedActivityCodes = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedActivityCodes(context, payload) {
      context.commit('selectedActivityCodes', payload)
    },
    loadActivityCodes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ActivityCodes'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('activityCodes', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    refreshSelectedActivityCodes(context, payload) {
      if (
        context.state.selectedActivityCodes &&
        context.state.selectedActivityCodes.ID
      ) {
        context.dispatch(
          'loadSelectedActivityCodes',
          context.state.selectedActivityCodes.ID
        )
      }
    },
    loadSelectedActivityCodes(context, payload) {
      if (
        context.state.selectedActivityCodes &&
        context.state.selectedActivityCodes.ID != payload
      ) {
        context.commit('selected', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'ActivityCodes/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedActivityCodes', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    clearLookupCacheAndReloadActivityCodes(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
        context.dispatch('loadActivityCodes')
      })
    },
    updateActivityCodes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ActivityCodes/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Activity Code updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadActivityCodes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createActivityCodes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ActivityCodes`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Activity Code created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadActivityCodes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveActivityCodes(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateActivityCodes', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createActivityCodes', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteActivityCodes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ActivityCodes/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'Activity Code deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadActivityCodes')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default activityCodes
