<template>
  <span>
    <v-tooltip :left="left" :top="top" :bottom="bottom" :right="right">
      <template v-slot:activator="{on}">
        <v-btn
          :class="buttonClass"
          :color="iconColor"
          v-bind="$attrs"
          @click.stop="addFeedback"
          icon
          v-on="on"
        >
          <v-icon :dark="dark">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>Submit Feedback</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      @keydown.esc="dialog = false"
      scrollable
      max-width="1250"
    >
      <v-card class="elevation-3">
        <v-card-title class="primary white--text text-h5"
          >Submit Feedback</v-card-title
        >
        <v-card-text class="pa-4">
          <TuiEditor
            ref="tuiEditor"
            v-model="selectedFeedback.Message"
          ></TuiEditor>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submitFeedback">Submit</v-btn>
          <v-btn color="secondary" @click="dialog = !dialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import TuiEditor from '@components/TuiEditor'

var Feedback = class {
  constructor(objIn = {}) {
    const defaults = {
      Message: '',
      UriPath: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Message = obj.Message
    this.UriPath = obj.UriPath
  }
  getForSave() {
    let obj = Object.assign({}, this)
    obj.UriPath = window.location.pathname + window.location.hash
    return obj
  }
}

export default {
  //model: {
  //	prop: ''
  //},
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-message-alert-outline',
    },
    iconColor: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
  },
  components: {
    TuiEditor,
  },
  filters: {},
  data: () => ({
    dialog: false,
    valid: true,
    selectedFeedback: new Feedback(),
  }),
  created() {},
  computed: {
    left() {
      return this.position == 'left'
    },
    right() {
      return this.position == 'right'
    },
    top() {
      return this.position == 'top'
    },
    bottom() {
      return this.position == 'bottom'
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    addFeedback() {
      this.selectedFeedback = new Feedback()
      if (this.$refs.tuiEditor) {
        this.$refs.tuiEditor.setMarkdown(this.selectedFeedback.Message)
      }
      this.dialog = true
    },
    submitFeedback() {
      this.$refs.tuiEditor.getMarkdown()
      // console.devlog(this.selectedFeedback.getForSave())
      if (this.selectedFeedback.Message) {
        let url = 'Feedback'
        this.$axios.post(url, this.selectedFeedback.getForSave()).then(
          (res) => {
            this.dialog = false
          },
          (err) => {}
        )
      }
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>