import Vue from 'vue'

const projectReviews = {
  state: {
    projectReviews: [],
    selectedProjectReviews: {},
  },

  getters: {
    projectReviews(state) {
      return state.projectReviews
    },
    selectedProjectReviews(state) {
      return state.selectedProjectReviews
    },
  },

  mutations: {
    projectReviews(state, payload) {
      state.projectReviews = payload
    },
    selectedProjectReviews(state, payload) {
      state.selectedProjectReviews = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectReviews(context, payload) {
      context.commit('selectedProjectReviews', payload)
    },
    loadProjectReviews(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectReviews'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectReviews', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectReviews(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectReviews/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectReviews', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectReviews(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectReviews/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectReviews updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectReviews')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectReviews(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectReviews`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectReviews created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectReviews')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProjectReviews(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectReviews', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectReviews', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectReviews(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectReviews/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'projectReviews deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectReviews')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectReviews
