import Vue from 'vue'

const userRole = {
  state: {
    userRole: [],
    selectedUserRole: {},
  },

  getters: {
    userRole(state) {
      return state.userRole
    },
    selectedUserRole(state) {
      return state.selectedUserRole
    },
  },

  mutations: {
    userRole(state, payload) {
      state.userRole = payload
    },
    selectedUserRole(state, payload) {
      state.selectedUserRole = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedUserRole(context, payload) {
      context.commit('selectedUserRole', payload)
    },
    loadUserRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'UserRole'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('userRole', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedUserRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'UserRole/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedUserRole', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateUserRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `UserRole/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'user role updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadUserRole')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createUserRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `UserRole`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'user role created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadUserRole')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveUserRole(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateUserRole', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createUserRole', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteUserRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `UserRole/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'user role deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadUserRole')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default userRole
