import Vue from 'vue'

const projectCollections = {
  state: {
    projectCollections: [],
    selectedProjectCollections: {},
  },

  getters: {
    projectCollections(state) {
      return state.projectCollections
    },
    selectedProjectCollections(state) {
      return state.selectedProjectCollections
    },
  },

  mutations: {
    projectCollections(state, payload) {
      state.projectCollections = payload
    },
    selectedProjectCollections(state, payload) {
      state.selectedProjectCollections = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedProjectCollections(context, payload) {
      context.commit('selectedProjectCollections', payload)
    },
    loadProjectCollections(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectCollections'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectCollections', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectCollections(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectCollections/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectCollections', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    updateProjectCollections(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectCollections/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'projectCollections updated',
              },
              {
                root: true,
              }
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectCollections')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    createProjectCollections(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectCollections`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'projectCollections created',
              },
              {
                root: true,
              }
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectCollections')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    saveProjectCollections(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectCollections', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectCollections', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectCollections(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectCollections/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'projectCollections deleted',
              },
              {
                root: true,
              }
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectCollections')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
  },
}

export default projectCollections
