<template>
  <div>
    <v-dialog
      v-model="openMessage"
      @keydown.esc="dialog = false"
      max-width="1250"
      scrollable
    >
      <v-card class="elevation-3">
        <v-card-title class="white--text text-h5" :class="color"
          >Messages</v-card-title
        >
        <v-card-text class="pa-4">
          <v-list v-for="(message, index) in messages" :key="index">
            <v-list-item>{{ message }}</v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="openMessage = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  components: {},
  filters: {
    snippet(text) {
      if (text.toString().length > 80) {
        return text.toString().substring(0, 80) + '...'
      } else {
        return text
      }
    },
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    ...sync('messages', ['openMessage']),
    ...mapGetters('messages', ['messages', 'messageText', 'color']),
  },
  methods: {
    closeMessage() {
      this.openMessage = false
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>