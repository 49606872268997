export default function moneyFormat(value) {
  if (value || value === 0) {
    const amt = Number.parseFloat(value).toFixed(2)
    // .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')

    if (amt < 0) {
      return (
        '($' +
        ((amt &&
          Math.abs(amt)
            .toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) ||
          '0') +
        ')'
      )
    }
    return (
      '$' +
      ((amt &&
        amt
          .toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) ||
        '0')
    )
  }
  // return Number.parseFloat(value).toFixed(2)
}
