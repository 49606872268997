import Vue from 'vue'

const requirementLevel = {
  state: {
    requirementLevel: [],
    selectedRequirementLevel: {},
  },

  getters: {
    requirementLevel(state) {
      return state.requirementLevel
    },
    selectedRequirementLevel(state) {
      return state.selectedRequirementLevel
    },
  },

  mutations: {
    requirementLevel(state, payload) {
      state.requirementLevel = payload
    },
    selectedRequirementLevel(state, payload) {
      state.selectedRequirementLevel = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedRequirementLevel(context, payload) {
      context.commit('selectedRequirementLevel', payload)
    },
    loadRequirementLevel(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'requirementLevel'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('requirementLevel', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedRequirementLevel(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'requirementLevel/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedRequirementLevel', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateRequirementLevel(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `requirementLevel/${payload.RequirementLevelID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'requirementLevel updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadRequirementLevel')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createRequirementLevel(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `requirementLevel`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'requirementLevel created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadRequirementLevel')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveRequirementLevel(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.RequirementLevelID > 0) {
          context.dispatch('updateRequirementLevel', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createRequirementLevel', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteRequirementLevel(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `requirementLevel/${payload.RequirementLevelID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'requirementLevel deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadRequirementLevel')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default requirementLevel
