import Vue from 'vue'

const project = {
  state: {
    selectedProjectExciseExceptionsId: 0,
    exciseExceptionsRequestCount: 0,
    selectedProjectExciseExceptions: [],
    selectedProjectExciseExceptionReferencesId: 0,
    exciseExceptionReferencesRequestCount: 0,
    selectedProjectExciseExceptionReferences: [],
    selectedProjectExciseExceptionReferencesObject: [],
    selectedProjectExciseExceptionVendorCustomerId: 0,
    exciseExceptionVendorCustomerRequestCount: 0,
    selectedProjectExciseExceptionVendorCustomer: [],
    selectedProjectExciseExceptionVendorCustomerObject: [],
    selectedProjectExciseExceptionUsersId: 0,
    exciseExceptionUsersRequestCount: 0,
    selectedProjectExciseExceptionUsers: [],
    selectedProjectExciseExceptionDuplicateCountId: 0,
    exciseExceptionDuplicateCountRequestCount: 0,
    selectedProjectExciseExceptionDuplicateCount: [],
    selectedProjectExciseExceptionsDuplicatesId: 0,
    exciseExceptionsDuplicatesRequestCount: 0,
    selectedProjectExciseExceptionsDuplicates: [],
  },

  getters: {
    selectedProjectExciseExceptions(state) {
      return state.selectedProjectExciseExceptions
    },
    exciseExceptionsLoading(state) {
      return state.exciseExceptionsRequestCount > 0
    },
    selectedProjectExciseExceptionReferences(state) {
      return state.selectedProjectExciseExceptionReferences
    },
    selectedProjectExciseExceptionReferencesObject(state) {
      return state.selectedProjectExciseExceptionReferencesObject
    },
    exciseExceptionReferencesLoading(state) {
      return state.exciseExceptionReferencesRequestCount > 0
    },
    selectedProjectExciseExceptionVendorCustomer(state) {
      return state.selectedProjectExciseExceptionVendorCustomer
    },
    selectedProjectExciseExceptionVendorCustomerObject(state) {
      return state.selectedProjectExciseExceptionVendorCustomerObject
    },
    exciseExceptionVendorCustomerLoading(state) {
      return state.exciseExceptionVendorCustomerRequestCount > 0
    },
    selectedProjectExciseExceptionUsers(state) {
      return state.selectedProjectExciseExceptionUsers
    },
    exciseExceptionUsersLoading(state) {
      return state.exciseExceptionUsersRequestCount > 0
    },
    selectedProjectExciseExceptionDuplicateCount(state) {
      return state.selectedProjectExciseExceptionDuplicateCount
    },
    exciseExceptionDuplicateCountLoading(state) {
      return state.exciseExceptionDuplicateCountRequestCount > 0
    },
    selectedProjectExciseExceptionsDuplicates(state) {
      return state.selectedProjectExciseExceptionsDuplicates
    },
    exciseExceptionsDuplicatesLoading(state) {
      return state.exciseExceptionsDuplicatesRequestCount > 0
    },
  },

  mutations: {
    selectedProjectExciseExceptions(state, payload) {
      state.selectedProjectExciseExceptions = payload
    },
    selectedProjectExciseExceptionsId(state, payload) {
      state.selectedProjectExciseExceptionsId = payload
    },
    exciseExceptionsRequestCount(state, payload) {
      state.exciseExceptionsRequestCount = payload
    },
    increaseExciseExceptionsRequestCount(state, payload) {
      state.exciseExceptionsRequestCount =
        state.exciseExceptionsRequestCount + 1
    },
    decreaseExciseExceptionsRequestCount(state, payload) {
      state.exciseExceptionsRequestCount =
        state.exciseExceptionsRequestCount - 1
    },
    selectedProjectExciseExceptionReferences(state, payload) {
      state.selectedProjectExciseExceptionReferences = payload
    },
    selectedProjectExciseExceptionReferencesObject(state, payload) {
      state.selectedProjectExciseExceptionReferencesObject = payload
    },
    selectedProjectExciseExceptionReferencesId(state, payload) {
      state.selectedProjectExciseExceptionReferencesId = payload
    },
    exciseExceptionReferencesRequestCount(state, payload) {
      state.exciseExceptionReferencesRequestCount = payload
    },
    increaseExciseExceptionReferencesRequestCount(state, payload) {
      state.exciseExceptionReferencesRequestCount =
        state.exciseExceptionReferencesRequestCount + 1
    },
    decreaseExciseExceptionReferencesRequestCount(state, payload) {
      state.exciseExceptionReferencesRequestCount =
        state.exciseExceptionReferencesRequestCount - 1
    },
    selectedProjectExciseExceptionVendorCustomer(state, payload) {
      state.selectedProjectExciseExceptionVendorCustomer = payload
    },
    selectedProjectExciseExceptionVendorCustomerObject(state, payload) {
      state.selectedProjectExciseExceptionVendorCustomerObject = payload
    },
    selectedProjectExciseExceptionVendorCustomerId(state, payload) {
      state.selectedProjectExciseExceptionVendorCustomerId = payload
    },
    exciseExceptionVendorCustomerRequestCount(state, payload) {
      state.exciseExceptionVendorCustomerRequestCount = payload
    },
    increaseExciseExceptionVendorCustomerRequestCount(state, payload) {
      state.exciseExceptionVendorCustomerRequestCount =
        state.exciseExceptionVendorCustomerRequestCount + 1
    },
    decreaseExciseExceptionVendorCustomerRequestCount(state, payload) {
      state.exciseExceptionVendorCustomerRequestCount =
        state.exciseExceptionVendorCustomerRequestCount - 1
    },
    selectedProjectExciseExceptionUsers(state, payload) {
      state.selectedProjectExciseExceptionUsers = payload
    },
    selectedProjectExciseExceptionUsersId(state, payload) {
      state.selectedProjectExciseExceptionUsersId = payload
    },
    exciseExceptionUsersRequestCount(state, payload) {
      state.exciseExceptionUsersRequestCount = payload
    },
    increaseExciseExceptionUsersRequestCount(state, payload) {
      state.exciseExceptionUsersRequestCount =
        state.exciseExceptionUsersRequestCount + 1
    },
    decreaseExciseExceptionUsersRequestCount(state, payload) {
      state.exciseExceptionUsersRequestCount =
        state.exciseExceptionUsersRequestCount - 1
    },
    selectedProjectExciseExceptionDuplicateCount(state, payload) {
      state.selectedProjectExciseExceptionDuplicateCount = payload
    },
    selectedProjectExciseExceptionDuplicateCountId(state, payload) {
      state.selectedProjectExciseExceptionDuplicateCountId = payload
    },
    exciseExceptionDuplicateCountRequestCount(state, payload) {
      state.exciseExceptionDuplicateCountRequestCount = payload
    },
    increaseExciseExceptionDuplicateCountRequestCount(state, payload) {
      state.exciseExceptionDuplicateCountRequestCount =
        state.exciseExceptionDuplicateCountRequestCount + 1
    },
    decreaseExciseExceptionDuplicateCountRequestCount(state, payload) {
      state.exciseExceptionDuplicateCountRequestCount =
        state.exciseExceptionDuplicateCountRequestCount - 1
    },
    selectedProjectExciseExceptionsDuplicates(state, payload) {
      state.selectedProjectExciseExceptionsDuplicates = payload
    },
    selectedProjectExciseExceptionsDuplicatesId(state, payload) {
      state.selectedProjectExciseExceptionsDuplicatesId = payload
    },
    exciseExceptionsDuplicatesRequestCount(state, payload) {
      state.exciseExceptionsDuplicatesRequestCount = payload
    },
    increaseExciseExceptionsDuplicatesRequestCount(state, payload) {
      state.exciseExceptionsDuplicatesRequestCount =
        state.exciseExceptionsDuplicatesRequestCount + 1
    },
    decreaseExciseExceptionsDuplicatesRequestCount(state, payload) {
      state.exciseExceptionsDuplicatesRequestCount =
        state.exciseExceptionsDuplicatesRequestCount - 1
    },
  },

  actions: {
    setSelectedProjectExciseExceptions(context, payload) {
      context.commit('selectedProjectExciseExceptions', payload)
    },
    loadSelectedProjectExciseExceptions(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          context.state.selectedProjectExciseExceptions.length > 0 &&
          selectedId != context.state.selectedProjectExciseExceptionsId
        ) {
          context.commit('selectedProjectExciseExceptions', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ExciseExceptions'
          context.commit('increaseExciseExceptionsRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit('selectedProjectExciseExceptions', res.data)
              context.commit('selectedProjectExciseExceptionsId', selectedId)
              context.commit('decreaseExciseExceptionsRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseExciseExceptionsRequestCount')
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionReferences(context, payload) {
      context.commit('selectedProjectExciseExceptionReferences', payload)
    },
    loadSelectedProjectExciseExceptionReferences(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          context.state.selectedProjectExciseExceptionReferences.length > 0 &&
          selectedId != context.state.selectedProjectExciseExceptionReferencesId
        ) {
          context.commit('selectedProjectExciseExceptionReferences', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ExciseExceptions/References'
          context.commit('increaseExciseExceptionReferencesRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit(
                'selectedProjectExciseExceptionReferences',
                res.data
              )
              context.commit(
                'selectedProjectExciseExceptionReferencesId',
                selectedId
              )
              context.commit('decreaseExciseExceptionReferencesRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseExciseExceptionReferencesRequestCount')
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionReferencesObject(context, payload) {
      context.commit('selectedProjectExciseExceptionReferencesObject', payload)
    },
    loadSelectedProjectExciseExceptionReferencesObject(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          context.state.selectedProjectExciseExceptionReferencesObject.length >
            0 &&
          selectedId != context.state.selectedProjectExciseExceptionReferencesId
        ) {
          context.commit('selectedProjectExciseExceptionReferencesObject', [])
        }
        return new Promise((resolve, reject) => {
          const url =
            'Project/' + selectedId + '/ExciseExceptions/object/References'
          context.commit('increaseExciseExceptionReferencesRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit(
                'selectedProjectExciseExceptionReferencesObject',
                res.data
              )
              context.commit(
                'selectedProjectExciseExceptionReferencesId',
                selectedId
              )
              context.commit('decreaseExciseExceptionReferencesRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseExciseExceptionReferencesRequestCount')
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionVendorCustomer(context, payload) {
      context.commit('selectedProjectExciseExceptionVendorCustomer', payload)
    },
    loadSelectedProjectExciseExceptionVendorCustomer(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          context.state.selectedProjectExciseExceptionVendorCustomer.length >
            0 &&
          selectedId !=
            context.state.selectedProjectExciseExceptionVendorCustomerId
        ) {
          context.commit('selectedProjectExciseExceptionVendorCustomer', [])
        }
        return new Promise((resolve, reject) => {
          const url =
            'Project/' + selectedId + '/ExciseExceptions/VendorCustomer'
          context.commit('increaseExciseExceptionVendorCustomerRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit(
                'selectedProjectExciseExceptionVendorCustomer',
                res.data
              )
              context.commit(
                'selectedProjectExciseExceptionVendorCustomerId',
                selectedId
              )
              context.commit(
                'decreaseExciseExceptionVendorCustomerRequestCount'
              )
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit(
                'decreaseExciseExceptionVendorCustomerRequestCount'
              )
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog(context.state.selectedProject)
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionVendorCustomerObject(context, payload) {
      context.commit(
        'selectedProjectExciseExceptionVendorCustomerObject',
        payload
      )
    },
    loadSelectedProjectExciseExceptionVendorCustomerObject(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          context.state.selectedProjectExciseExceptionVendorCustomerObject
            .length > 0 &&
          selectedId !=
            context.state.selectedProjectExciseExceptionVendorCustomerId
        ) {
          context.commit(
            'selectedProjectExciseExceptionVendorCustomerObject',
            []
          )
        }
        return new Promise((resolve, reject) => {
          const url =
            'Project/' + selectedId + '/ExciseExceptions/object/VendorCustomer'
          context.commit('increaseExciseExceptionVendorCustomerRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit(
                'selectedProjectExciseExceptionVendorCustomerObject',
                res.data
              )
              context.commit(
                'selectedProjectExciseExceptionVendorCustomerId',
                selectedId
              )
              context.commit(
                'decreaseExciseExceptionVendorCustomerRequestCount'
              )
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit(
                'decreaseExciseExceptionVendorCustomerRequestCount'
              )
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog(context.state.selectedProject)
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionUsers(context, payload) {
      context.commit('selectedProjectExciseExceptionUsers', payload)
    },
    loadSelectedProjectExciseExceptionUsers(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          context.state.selectedProjectExciseExceptionUsers.length > 0 &&
          selectedId != context.state.selectedProjectExciseExceptionUsersId
        ) {
          context.commit('selectedProjectExciseExceptionUsers', [])
        }
        return new Promise((resolve, reject) => {
          const url = 'Project/' + selectedId + '/ExciseExceptions/Users'
          context.commit('increaseExciseExceptionUsersRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              let users = res.data
              let currentUser = context.rootState.auth.currentUser
              let match = users.findIndex((x) => x.ID == currentUser.ID)
              if (match == -1) {
                users.push(currentUser)
              }
              context.commit('selectedProjectExciseExceptionUsers', users)
              context.commit(
                'selectedProjectExciseExceptionUsersId',
                selectedId
              )
              context.commit('decreaseExciseExceptionUsersRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseExciseExceptionUsersRequestCount')
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionsDuplicateCount(context, payload) {
      context.commit('selectedProjectExciseExceptionsDuplicateCount', payload)
    },
    loadSelectedProjectExciseExceptionsDuplicateCount(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          selectedId !=
            context.state.selectedProjectExciseExceptionDuplicateCountId
        ) {
          context.commit('selectedProjectExciseExceptionDuplicateCount', 0)
        }
        return new Promise((resolve, reject) => {
          const url = '/ExciseExceptions/DuplicateCount/' + selectedId
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit(
                'selectedProjectExciseExceptionDuplicateCount',
                res.data
              )
              context.commit(
                'selectedProjectExciseExceptionDuplicateCountId',
                selectedId
              )
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog('selected project not loaded')
      }
    },
    setSelectedProjectExciseExceptionsDuplicates(context, payload) {
      context.commit('selectedProjectExciseExceptionsDuplicates', payload)
    },
    loadSelectedProjectExciseExceptionsDuplicates(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID > 0
      ) {
        let selectedId = context.state.selectedProject.ID
        if (
          context.state.selectedProject &&
          selectedId !=
            context.state.selectedProjectExciseExceptionsDuplicatesId
        ) {
          context.commit('selectedProjectExciseExceptionsDuplicates', [])
        }
        return new Promise((resolve, reject) => {
          const url = '/ExciseExceptions/Duplicates/' + selectedId
          context.commit('increaseExciseExceptionsDuplicatesRequestCount')
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit(
                'selectedProjectExciseExceptionsDuplicates',
                res.data
              )
              context.commit(
                'selectedProjectExciseExceptionsDuplicatesId',
                selectedId
              )
              context.commit('decreaseExciseExceptionsDuplicatesRequestCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('decreaseExciseExceptionsDuplicatesRequestCount')
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        })
      } else {
        console.devlog('selected project not loaded')
      }
    },
  },
}

export default project
