import Vue from 'vue'

const luDateType = {
  state: {
    luDateType: [],
    selectedLuDateType: {},
  },

  getters: {
    luDateType(state) {
      return state.luDateType
    },
    selectedLuDateType(state) {
      return state.selectedLuDateType
    },
  },

  mutations: {
    luDateType(state, payload) {
      state.luDateType = payload
    },
    selectedLuDateType(state, payload) {
      state.selectedLuDateType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLuDateType(context, payload) {
      context.commit('selectedLuDateType', payload)
    },
    loadLuDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuDateType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('luDateType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLuDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuDateType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLuDateType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLuDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuDateType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'date type updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuDateType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLuDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuDateType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'date type created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuDateType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLuDateType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuDateType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLuDateType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLuDateType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LuDateType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'date type deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuDateType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default luDateType
