import Vue from 'vue'

const workWeekType = {
  state: {
    workWeekType: [],
    selectedWorkWeekType: {},
  },

  getters: {
    workWeekType(state) {
      return state.workWeekType
    },
    selectedWorkWeekType(state) {
      return state.selectedWorkWeekType
    },
  },

  mutations: {
    workWeekType(state, payload) {
      state.workWeekType = payload
    },
    selectedWorkWeekType(state, payload) {
      state.selectedWorkWeekType = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedWorkWeekType(context, payload) {
      context.commit('selectedWorkWeekType', payload)
    },
    loadWorkWeekType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'WorkWeekType'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('workWeekType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedWorkWeekType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'WorkWeekType/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedWorkWeekType', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateWorkWeekType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `WorkWeekType/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'work week type updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadWorkWeekType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createWorkWeekType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `WorkWeekType`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'work week type created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadWorkWeekType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveWorkWeekType(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateWorkWeekType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createWorkWeekType', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteWorkWeekType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `WorkWeekType/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'work week type deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadWorkWeekType')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default workWeekType
