import Vue from 'vue'
import {debounce, range} from 'lodash'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const searchSortByDefault = 'ID'
const exciseExceptions = {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: [searchSortByDefault],
    searchDescending: [true],
    searchRowsPerPage: 100,
    searchText: '',
    lookupText: '',
    searchProjectID: 0,
    //templateTODO: uncomment as used in ExciseExceptionsSearchParams
    searchStartDate: null,
    searchEndDate: null,
    searchStratumID: [],
    searchIssueID: [],
    searchTransactionTypeID: [],
    searchTaxTypeID: [],
    // searchSoldFromCountyID: [],
    searchDeliveredToCityID: [],
    searchDeliveredToCountyID: [],
    searchSpecialDistrictID: [],
    searchVendorCustomer: [],
    searchReference: [],
    // searchShowInactive: false,
    // searchIsAppended: [],
    searchIsActive: ['true'],
    searchStratumIsActive: ['true'],
    searchCreatedByID: [],
  },

  getters: {
    searchParameters(state) {
      var sortDirection
      if (Array.isArray(state.searchDescending)) {
        sortDirection = state.searchDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.searchDescending ? ['desc'] : ['asc']
      }
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection,
        searchText: state.searchText,
        projectID: state.searchProjectID,
        //templateTODO: uncomment as used in ExciseExceptionsSearchParams
        startDate: state.searchStartDate,
        endDate: state.searchEndDate,
        stratumID: state.searchStratumID,
        issueID: state.searchIssueID,
        transactionTypeID: state.searchTransactionTypeID,
        taxTypeID: state.searchTaxTypeID,
        // soldFromCountyID: state.searchSoldFromCountyID,
        deliveredToCityID: state.searchDeliveredToCityID,
        deliveredToCountyID: state.searchDeliveredToCountyID,
        specialDistrictID: state.searchSpecialDistrictID,
        vendorCustomer: state.searchVendorCustomer,
        reference: state.searchReference,
        // showInactive: state.searchShowInactive,
        isActive: state.searchIsActive,
        stratumIsActive: state.searchStratumIsActive,
        //isAppended: state.searchIsAppended,
        createdByID: state.searchCreatedByID,
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    searchProjectID(state, payload) {
      state.searchProjectID = payload
    },
    //TemplateTODO: uncomment as needed
    searchStartDate(state, payload) {
      state.searchStartDate = payload
    },
    searchEndDate(state, payload) {
      state.searchEndDate = payload
    },
    searchStratumID(state, payload) {
      state.searchStratumID = payload
    },
    searchIssueID(state, payload) {
      state.searchIssueID = payload
    },
    searchTransactionTypeID(state, payload) {
      state.searchTransactionTypeID = payload
    },
    searchTaxTypeID(state, payload) {
      state.searchTaxTypeID = payload
    },
    // searchSoldFromCountyID(state, payload) {
    //   state.searchSoldFromCountyID = payload
    // },
    searchDeliveredToCityID(state, payload) {
      state.searchDeliveredToCityID = payload
    },
    searchDeliveredToCountyID(state, payload) {
      state.searchDeliveredToCountyID = payload
    },
    searchSpecialDistrictID(state, payload) {
      state.searchSpecialDistrictID = payload
    },
    searchVendorCustomer(state, payload) {
      state.searchVendorCustomer = payload
    },
    searchReference(state, payload) {
      state.searchReference = payload
    },
    //searchShowInactive(state, payload) {
    //  state.searchShowInactive = payload
    //},
    searchIsActive(state, payload) {
      state.searchIsActive = payload
    },
    searchStratumIsActive(state, payload) {
      state.searchStratumIsActive = payload
    },
    //searchIsAppended(state, payload) {
    //  state.searchIsAppended = payload
    //},
    searchCreatedByID(state, payload) {
      state.searchCreatedByID = payload
    },
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupExciseExceptions')
    }, 300),
    searchBounce: _.debounce((context) => {
      context.dispatch('searchExciseExceptions')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.searchSortBy)
      ) {
        context.commit('searchSortBy', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.searchDescending)
      ) {
        context.commit('searchDescending', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText && payload != null) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    setSearchProjectID(context, payload) {
      if (payload != context.state.searchProjectID) {
        context.commit('searchProjectID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    setSearchStartDate(context, payload) {
      if (payload != context.state.searchStartDate) {
        context.commit('searchStartDate', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchEndDate(context, payload) {
      if (payload != context.state.searchEndDate) {
        context.commit('searchEndDate', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchStratumID(context, payload) {
      if (payload != context.state.searchStratumID) {
        context.commit('searchStratumID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchIssueID(context, payload) {
      if (payload != context.state.searchIssueID) {
        context.commit('searchIssueID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchTransactionTypeID(context, payload) {
      if (payload != context.state.searchTransactionTypeID) {
        context.commit('searchTransactionTypeID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchTaxTypeID(context, payload) {
      if (payload != context.state.searchTaxTypeID) {
        context.commit('searchTaxTypeID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    // setSearchSoldFromCountyID(context, payload) {
    //   if (payload != context.state.searchSoldFromCountyID) {
    //     context.commit('searchSoldFromCountyID', payload)
    //     context.dispatch('searchBounce', context)
    //   }
    // },
    setSearchDeliveredToCityID(context, payload) {
      if (payload != context.state.searchDeliveredToCityID) {
        context.commit('searchDeliveredToCityID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDeliveredToCountyID(context, payload) {
      if (payload != context.state.searchDeliveredToCountyID) {
        context.commit('searchDeliveredToCountyID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSpecialDistrictID(context, payload) {
      if (payload != context.state.searchSpecialDistrictID) {
        context.commit('searchSpecialDistrictID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchVendorCustomer(context, payload) {
      if (payload != context.state.searchVendorCustomer) {
        context.commit('searchVendorCustomer', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchReference(context, payload) {
      if (payload != context.state.searchReference) {
        context.commit('searchReference', payload)
        context.dispatch('searchBounce', context)
      }
    },
    //setSearchShowInactive(context, payload) {
    //  if (payload != context.state.searchShowInactive) {
    //    context.commit('searchShowInactive', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    setSearchIsActive(context, payload) {
      if (payload != context.state.searchIsActive) {
        context.commit('searchIsActive', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchStratumIsActive(context, payload) {
      if (payload != context.state.searchStratumIsActive) {
        context.commit('searchStratumIsActive', payload)
        context.dispatch('searchBounce', context)
      }
    },
    //setSearchIsAppended(context, payload) {
    //  if (payload != context.state.searchIsAppended) {
    //    context.commit('searchIsAppended', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    setSearchCreatedByID(context, payload) {
      if (payload != context.state.searchCreatedByID) {
        context.commit('searchCreatedByID', payload)
        context.dispatch('searchBounce', context)
      }
    },
    searchExciseExceptions(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
        if (!Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy) {
            context.commit('searchSortBy', [context.state.searchSortBy])
            if (!Array.isArray(context.state.searchDescending)) {
              context.commit('searchDescending', [
                context.state.searchDescending,
              ])
            }
          } else {
            context.commit('searchSortBy', [searchSortByDefault])
            context.commit('searchDescending', [true])
          }
        }
        if (!context.state.searchText) {
          context.commit('searchText', '')
        }
        context.commit('increaseSearchCount')
        var params = context.getters.searchParameters
        var paramString = JSON.stringify(params)
        const url = `ExciseExceptions/Search`
        Vue.prototype.$axios.post(url, params).then(
          (res) => {
            if (
              JSON.stringify(context.getters.searchParameters) == paramString
            ) {
              // let entries = res.data.Entries.map((entry) => {
              //   return {
              //     ...entry,
              //     // InvoiceDate: dayjs(entry.InvoiceDate).toDate(),
              //     //   .format(
              //     //   'MM/DD/YYYY'
              //     // ),
              //   }
              // })
              context.commit('exciseExceptions', res.data.Entries)
              context.commit('searchRowsPerPage', res.data.Page.Size)
              context.commit('searchPage', res.data.Page.Number + 1)
              context.commit('searchTotal', res.data.Page.TotalElements)
            }
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    lookupExciseExceptions(context, payload) {
      if (context.state.lookupText) {
        return new Promise((resolve, reject) => {
          context.commit('increaseSearchCount')
          const url = `ExciseExceptions/Lookup?searchText=${context.state.lookupText}`
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit('exciseExceptions', res.data)
              context.commit('decreaseSearchCount')
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.dispatch('errors/handleError', err, {root: true})
              context.commit('decreaseSearchCount')
              reject(err)
            }
          )
        })
      }
    },
  },
}

export default exciseExceptions
