import Vue from 'vue'

const systemMessage = {
  state: {
    systemMessage: [],
    selectedSystemMessage: {},
  },

  getters: {
    systemMessage(state) {
      return state.systemMessage
    },
    selectedSystemMessage(state) {
      return state.selectedSystemMessage
    },
  },

  mutations: {
    systemMessage(state, payload) {
      state.systemMessage = payload
    },
    selectedSystemMessage(state, payload) {
      state.selectedSystemMessage = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedSystemMessage(context, payload) {
      context.commit('selectedSystemMessage', payload)
    },
    loadSystemMessage(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SystemMessage'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('systemMessage', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    loadSelectedSystemMessage(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'SystemMessage/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedSystemMessage', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    updateSystemMessage(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SystemMessage/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'systemMessage updated',
              },
              {
                root: true,
              }
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSystemMessage')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    createSystemMessage(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SystemMessage`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'systemMessage created',
              },
              {
                root: true,
              }
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadSystemMessage')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
    saveSystemMessage(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateSystemMessage', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createSystemMessage', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteSystemMessage(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `SystemMessage/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'systemMessage deleted',
              },
              {
                root: true,
              }
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            context.dispatch('loadSystemMessage')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {
              root: true,
            })
            reject(err)
          }
        )
      })
    },
  },
}

export default systemMessage
