import Vue from 'vue'
import jwtDecode from 'jwt-decode'

export const state = {
  currentUser: null,
  gapiEmail: null,
  gapiToken: null,
  signingIn: false,
  etsJwtToken: null,
  authErrors: [],
  isRefreshing: false,
  refreshingCall: null,
  loginType: 'google',
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
  },

  SET_GAPI_USER(state, payload) {
    if (!!payload) {
      state.gapiEmail = payload.email
      state.gapiToken = payload.token
    } else {
      state.gapiEmail = null
      state.gapiToken = null
    }
  },
  SET_ETS_JWT_TOKEN(state, newValue) {
    state.etsJwtToken = newValue
  },
  SET_SIGNING_IN(state, value) {
    state.signingIn = value
  },
  ADD_AUTH_ERROR(state, error) {
    if (state.authError.length > 20) {
      state.authError.shift()
    }
    state.authError.push(error)
  },
  CLEAR_AUTH_ERRORS(state) {
    state.authError = []
  },
  setRefreshingState: (state, isRefreshing) =>
    (state.isRefreshing = isRefreshing),
  setRefreshingCall: (state, func) => (state.refreshingCall = func),
  setLoginType(state, payload) {
    state.loginType = payload
  },
}

export const getters = {
  // Whether the user is currently logged in.
  isLoggedIn(state) {
    //once you have a user object being returned and saved you also want to check that that has been populated
    return !!state.etsJwtToken && getters.hasCurrentToken && !!state.currentUser
  },
  isSuperUser: (state) => {
    if (state.currentUser != null) {
      let adminRole = state.currentUser.UserRole.find(
        (ur) => ur.Role.Description == 'SuperUser'
      )
      return !!adminRole
    }
    return false
  },
  isSupervisor: (state) => {
    if (state.currentUser != null) {
      let adminRole = state.currentUser.UserRole.find(
        (ur) => ur.Role.Description == 'Supervisor'
      )
      return !!adminRole
    }
    return false
  },
  isProjectCreator: (state) => {
    if (state.currentUser != null) {
      let adminRole = state.currentUser.UserRole.find(
        (ur) => ur.Role.Description == 'Project Creator'
      )
      return !!adminRole
    }
    return false
  },
  isValidator: (state) => {
    if (state.currentUser != null) {
      let adminRole = state.currentUser.UserRole.find(
        (ur) => ur.Role.Description == 'Validator'
      )
      return !!adminRole
    }
    return false
  },
  decodedToken: (state) => {
    if (!!state.etsJwtToken) {
      return jwtDecode(state.etsJwtToken)
    } else {
      return null
    }
  },
  tokenExpiration: (state) => {
    if (!!state.etsJwtToken) {
      return new Date(jwtDecode(state.etsJwtToken).exp * 1000)
    } else {
      return null
    }
  },
  hasCurrentToken: (state) => {
    if (!!state.etsJwtToken) {
      var token = jwtDecode(state.etsJwtToken)
      if (token.exp > Date.now() / 1000) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  tokenExpiresSoon: (state) => {
    if (!!state.etsJwtToken) {
      var token = jwtDecode(state.etsJwtToken)
      if (
        token.exp > Date.now() / 1000 && // not yet expired
        token.exp < Date.now() / 1000 + 30 * 60 // expires within 30 minutes
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({state, dispatch}) {
    //can not run this on init as gapi is not initialized yet
    //dispatch('getBrowserUser')
  },

  // Logs out the current user.
  logOut: async ({commit}) => {
    console.info('logout')
    let url = `/Logout`
    Vue.prototype.$axios.get(url)
    Vue.axios.defaults.headers.common['Authorization'] = null
    if (Vue.prototype.$google) {
      var client = await Vue.prototype.$google.getClient()
      //this will prevent the auto-sign in from happening
      client.accounts.id.disableAutoSelect()
    }
    commit('SET_CURRENT_USER', null)
    commit('SET_ETS_JWT_TOKEN', null)
    commit('SET_GAPI_USER', null)
    commit('setLoginType', null)
  },
  async setGoogleCredentials({commit, dispatch}, payload) {
    //this is called by the callback of the google login
    commit('SET_SIGNING_IN', true)
    console.devlog('google credential callback')
    try {
      var userInfo = jwtDecode(payload)
      userInfo.token = payload
      userInfo.expiration = new Date(userInfo.exp * 1000)
      commit('SET_GAPI_USER', userInfo)
      commit('setLoginType', 'google')
      await dispatch('validateToken', {
        email: userInfo.email,
        token: payload,
        password: null,
      })
    } catch (e) {
      commit('SET_SIGNING_IN', false)
      commit('SET_GAPI_USER', null)
      commit('setLoginType', null)
      dispatch('errors/handleError', e, {
        root: true,
      })
    }
  },
  async getBrowserUser({getters, commit, dispatch}, payload) {
    try {
      //note: the new google signin is also able to retrieve passwords stored in the browser credential manager
      // this allows us to attempt to refresh the ets jwt tokens without the user needing to actively sign in again
      console.devlog('getBrowserUser', state.signingIn)
      if (!state.signingIn) {
        return new Promise((resolve, reject) => {
          if (
            Vue.prototype.$google &&
            (state.loginType == 'google' || payload)
          ) {
            commit('SET_SIGNING_IN', true) //does the pop up for user select
            let returned = false
            Vue.prototype.$google.prompt((notification) => {
              if (notification.isNotDisplayed()) {
                if (
                  notification.getNotDisplayedReason() ==
                  'opt_out_or_no_session'
                ) {
                  // we are not using un/pw logins, so we are just going to reject here
                  returned = true
                  commit('SET_SIGNING_IN', false)
                  reject('no session')
                }
              }
              if (notification.isDisplayMoment()) {
                let x = 0
                var intervalID = window.setInterval(() => {
                  // if a promise has not been resolved we are going to return a reject
                  if (returned) {
                    console.devlog(`returned ${x}`)
                    window.clearInterval(intervalID)
                  }
                  if (++x >= 20 && !returned) {
                    console.devlog(`timeout ${x}`)
                    returned = true
                    window.clearInterval(intervalID)
                    commit('SET_SIGNING_IN', false)
                    reject('timeout')
                  }
                }, 500)
              }
              if (notification.isDismissedMoment()) {
                if (
                  notification.getDismissedReason() == 'credential_returned'
                ) {
                  returned = true
                  commit('SET_SIGNING_IN', false)
                  resolve('google')
                }
              }
            })
          }
        })
      }
    } catch (e) {
      dispatch('errors/handleError', e, {
        root: true,
      })
      console.error(e)
      commit('SET_SIGNING_IN', false)
      dispatch('logOut')
    }
  },
  refreshToken: async ({commit, getters, dispatch}, payload) => {
    //only refresh token if we have a current (not expired) one
    console.devlog('refresh token called')
    if (getters.hasCurrentToken) {
      //note: this will only work with google accounts as un/pw accounts will require the password to be reentered to retrieve the token from identity
      //in the future we should add a refreshToken to identity that will take a valid jwt and generate a new one for it
      if (getters.tokenExpiresSoon && state.loginType == 'google') {
        if (!!state.gapiEmail && !!state.gapiToken) {
          //this will refresh the google token if it has expired
          var token = jwtDecode(state.gapiToken)
          if (token.exp > Date.now() / 1000) {
            //if not expired, we use the token we have.
            await dispatch('setGoogleCredentials', state.gapiToken)
          } else {
            await dispatch('getBrowserUser', true)
          }
        } else {
          await dispatch('getBrowserUser', true)
        }
      }
    } else {
      //try to refresh token from backend if we have a session already
      await dispatch('getSessionToken')
    }
  },
  getSessionToken: async ({commit, dispatch, getters}, payload) => {
    console.devlog('getSessionToken', state.signingIn)
    if (!state.signingIn) {
      commit('SET_SIGNING_IN', true)
      let url = `/Login`
      try {
        let response = await Vue.prototype.$axios.get(url)
        commit('SET_SIGNING_IN', false)
        if (!!response.data) {
          var token = jwtDecode(response.data)
          if (token && token.exp > Date.now() / 1000) {
            //we got back a valid auth token from the backend... let's set it
            commit('SET_ETS_JWT_TOKEN', response.data)
            Vue.axios.defaults.headers.common['Authorization'] =
              'Bearer ' + response.data
            //call this to retrieve user details / roles from the api
            if (getters.decodedToken.sub) {
              dispatch('retrieveUserDetail', getters.decodedToken.sub)
            }
          } else {
            // token is invalid or expired try to get the user from google
            await dispatch('getBrowserUser', false)
          }
        } else {
          // no current session - try to get the user from google
          await dispatch('getBrowserUser', false)
        }
      } catch (e) {
        console.error(e)
        commit('SET_SIGNING_IN', false)
      }
    }
  },
  validateToken: async ({commit, dispatch}, payload) => {
    commit('SET_SIGNING_IN', true)
    let url = `/Login`
    let email = payload.email
    let token = payload.token
    if (!!email && !!token) {
      try {
        let response = await Vue.prototype.$axios.post(url, {
          email: email,
          id_token: token,
        })
        console.devlog(response.data)
        if (!!response.data && !!response.data.auth_token) {
          //we got back an auth token from the backend... let's set it
          commit('SET_ETS_JWT_TOKEN', response.data.auth_token)
          Vue.axios.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.auth_token
          //call this to retrieve user details / roles from the api
          dispatch('retrieveUserDetail', email)

          commit('SET_SIGNING_IN', false)
        } else {
          console.info('jwt token not retrieved: ', response.data)
          dispatch('logOut')
        }
      } catch (e) {
        dispatch('errors/handleError', e, {root: true})
        console.error(e)
        commit('SET_SIGNING_IN', false)
        dispatch('logOut')
      }
    } else {
      console.devlog(payload)
      commit('SET_SIGNING_IN', false)
      dispatch('errors/handleError', 'check authentication', {
        root: true,
      })
    }
  },
  retrieveUserDetail: async (context, email) => {
    try {
      let {data} = await Vue.prototype.$axios.get(`/User/Email/${email}`)
      // call mutation to set user details in store
      context.commit('SET_CURRENT_USER', data)
    } catch (e) {
      console.error(e)
      context.dispatch('errors/handleError', e, {root: true})
      context.dispatch('logOut')
    }
  },
}
