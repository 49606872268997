import Vue from 'vue'

const projectDate = {
  state: {
    projectDate: [],
    selectedProjectDate: {},
    saving: false,
    searchCount: 0,
    selectedProjectID: null,
  },

  getters: {
    projectDate(state) {
      return state.projectDate
    },
    selectedProjectDate(state) {
      return state.selectedProjectDate
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    projectDate(state, payload) {
      state.projectDate = payload
    },
    selectedProjectID(state, payload) {
      state.selectedProjectID = payload
    },
    selectedProjectDate(state, payload) {
      state.selectedProjectDate = JSON.parse(JSON.stringify(payload))
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
    saving(state, payload) {
      state.saving = payload
    },
  },

  actions: {
    setSelectedProjectDate(context, payload) {
      context.commit('selectedProjectDate', payload)
    },
    // this has been modified from the original code to get the expenses for a single project
    loadProjectDate(context, payload) {
      if (payload != context.state.selectedProjectID) {
        context.commit('projectDate', [])
      }
      context.commit('selectedProjectID', payload)
      return new Promise((resolve, reject) => {
        const url = `ProjectDate/ByProject/${payload}`
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('projectDate', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    loadSelectedProjectDate(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'ProjectDate/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProjectDate', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProjectDate(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectDate/${payload.ID}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project date updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectDate')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProjectDate(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectDate`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project date created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectDate')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            context.commit('saving', false)
            reject(err)
          }
        )
      })
    },
    saveProjectDate(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProjectDate', payload).then(
            (res) => {
              context.dispatch('loadProjectDate', payload.ProjectID)
              return resolve(res)
            },
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProjectDate', payload).then(
            (res) => {
              context.dispatch('loadProjectDate', payload.ProjectID)
              return resolve(res)
            },
            (err) => reject(err)
          )
        }
      })
    },
    deleteProjectDate(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `ProjectDate/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project date deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProjectDate')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default projectDate
