import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luExciseTaxRates = {
  state: {
    luExciseTaxRates: [],
    selectedLuExciseTaxRates: {},
    selectedLuExciseTaxRatesOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luExciseTaxRates(state) {
      return state.luExciseTaxRates
    },
    selectedLuExciseTaxRates(state) {
      return state.selectedLuExciseTaxRates
    },
    selectedLuExciseTaxRatesOriginal(state) {
      return state.selectedLuExciseTaxRatesOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luExciseTaxRates(state, payload) {
      state.luExciseTaxRates = payload
    },
    selectedLuExciseTaxRates(state, payload) {
      state.selectedLuExciseTaxRates = JSON.parse(JSON.stringify(payload))
    },
    selectedLuExciseTaxRatesOriginal(state, payload) {
      state.selectedLuExciseTaxRatesOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuExciseTaxRates(context, payload) {
      context.commit('selectedLuExciseTaxRates', payload)
    },
    loadLuExciseTaxRates(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuExciseTaxRates'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luExciseTaxRates', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuExciseTaxRates(context, payload) {
      if (context.state.selectedLuExciseTaxRates && context.state.selectedLuExciseTaxRates.ID > 0) {
        context.dispatch('loadSelectedLuExciseTaxRates', context.state.selectedLuExciseTaxRates.ID)

      }
    },
    loadSelectedLuExciseTaxRates(context, payload) {
      if (context.state.selectedLuExciseTaxRates && context.state.selectedLuExciseTaxRates.ID != payload) {
        context.commit('selectedLuExciseTaxRates', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'LuExciseTaxRates/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuExciseTaxRates', res.data)
            context.commit('selectedLuExciseTaxRatesOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuExciseTaxRates(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuExciseTaxRates')
      }, )
    },
    createLuExciseTaxRates(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuExciseTaxRates`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rates created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExciseTaxRates')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchLuExciseTaxRates(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedLuExciseTaxRates
        if (payload.ID && 
        payload.ID == context.state.selectedLuExciseTaxRatesOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedLuExciseTaxRatesOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `LuExciseTaxRates/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise tax rates updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadLuExciseTaxRates')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateLuExciseTaxRates(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `LuExciseTaxRates/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rates updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExciseTaxRates')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuExciseTaxRates(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuExciseTaxRates', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuExciseTaxRates', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuExciseTaxRates(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuExciseTaxRates/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax rates deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExciseTaxRates')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luExciseTaxRates