import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import camelCase from 'lodash/camelCase'

const moduleCache = {}
const root = {
  modules: {},
}

;(function updateModules() {
  // Allow us to dynamically require all Vuex module files.
  // https://webpack.js.org/guides/dependency-management/#require-context

  const requireFolderModule = require.context(
    // Search for files in the current directory.
    '.',
    // Search for files in subdirectories.
    true,
    // Get files that contain separated vuex files
    /\/getters.js$|\/actions.js$|\/mutations.js$|\/state.js$/i
  )
  //for every separated Vuex module
  requireFolderModule.keys().forEach((fileName) => {
    //get folderNames for folders that have getters actions mutations and state files
    const folderName = fileName.match(
      /.+(?=getters.js$|actions.js$|mutations.js$|state.js$)/i
    )[0]
    //get moduleName based on the folder
    const moduleName = camelCase(folderName.replace(/(\.\/|\.js)/g, ''))
    //if module does not exist create it
    if (root.modules[moduleName] == null) {
      root.modules[moduleName] = {
        namespaced: true,
        ...(requireFolderModule(fileName).default ||
          requireFolderModule(fileName)),
      }
    } else {
      //if module exists append additional vuex files to the module
      root.modules[moduleName] = Object.assign(
        requireFolderModule(fileName).default || requireFolderModule(fileName),
        root.modules[moduleName]
      )
    }

    // If the environment supports hot reloading...
    if (module.hot) {
      // Whenever any Vuex module is updated...
      module.hot.accept(requireFolderModule.id, () => {
        // Update `root.modules` with the latest definitions.
        updateModules()
        // Trigger a hot update in the store.
        require('../store').default.hotUpdate({
          modules: root.modules,
        })
      })
    }
  })
  const requireFolderSplitModule = require.context(
    // Search for files in the current directory.
    '.',
    // Search for files in subdirectories.
    true,
    // Get files that contain separated vuex files
    /\/_split-[\w-]+\.js$/i
  )
  //for every separated Vuex module
  requireFolderSplitModule.keys().forEach((fileName) => {
    //get folderNames for folders that have split module files
    const folderName = fileName.match(/.+(?=_split-[\w-]+\.js$)/i)[0]
    //get moduleName based on the folder
    const moduleName = camelCase(folderName.replace(/(\.\/|\.js)/g, ''))
    let fileModule = requireFolderSplitModule(fileName)
    fileModule = fileModule.default || fileModule
    //if module does not exist create it
    if (root.modules[moduleName] == null) {
      root.modules[moduleName] = {
        namespaced: true,
        ...fileModule,
      }
    } else {
      //if module exists append additional vuex files to the module
      let combined = Object.assign({}, fileModule, root.modules[moduleName])
      let mergedModule = {}
      for (const [key, value] of Object.entries(combined)) {
        mergedModule[key] = Object.assign({}, value, fileModule[key])
      }
      root.modules[moduleName] = mergedModule
    }

    // If the environment supports hot reloading...
    if (module.hot) {
      // Whenever any Vuex module is updated...
      module.hot.accept(requireFolderSplitModule.id, () => {
        // Update `root.modules` with the latest definitions.
        updateModules()
        // Trigger a hot update in the store.
        require('../store').default.hotUpdate({
          modules: root.modules,
        })
      })
    }
  })
  const requireModule = require.context(
    // Search for files in the current directory.
    '.',
    // Search for files in subdirectories.
    true,
    // Include any .js files that are not this file or a unit test.
    /^((?!index|\.unit\.|\/getters.js$|\/actions.js$|\/mutations.js$|\/state.js|\/_split-[\w-]+\.js$).)*\.js$/
  )
  // For every Vuex file module...
  requireModule.keys().forEach((fileName) => {
    const moduleDefinition =
      requireModule(fileName).default || requireModule(fileName)

    // Skip the module during hot reload if it refers to the
    // same module definition as the one we have cached.
    if (moduleCache[fileName] === moduleDefinition) return
    // Update the module cache, for efficient hot reloading.
    moduleCache[fileName] = moduleDefinition

    // Get the module path as an array.
    const modulePath = fileName
      // Remove the "./" from the beginning.
      .replace(/^\.\//, '')
      // Remove the file extension from the end.
      .replace(/\.\w+$/, '')
      // Split nested modules into an array path.
      .split(/\//)
      // camelCase all module namespaces and names.
      .map(camelCase)

    // Get the modules object for the current path.
    const {modules} = getNamespace(root, modulePath)

    // Add the module to our modules object.
    modules[modulePath.pop()] = {
      // Modules are namespaced by default.
      namespaced: true,
      ...moduleDefinition,
    }
  })

  // If the environment supports hot reloading...
  if (module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept(requireModule.id, () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      require('../store').default.hotUpdate({
        modules: root.modules,
      })
    })
  }
})()

// Recursively get the namespace of a Vuex module, even if nested.
function getNamespace(subtree, path) {
  if (path.length === 1) return subtree

  const namespace = path.shift()
  subtree.modules[namespace] = {
    modules: {},
    ...subtree.modules[namespace],
  }
  return getNamespace(subtree.modules[namespace], path)
}

export default root.modules
