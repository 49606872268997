import Vue from 'vue'

const timeSheetStatus = {
  state: {
    timeSheetStatus: [],
    selectedTimeSheetStatus: {},
  },

  getters: {
    timeSheetStatus(state) {
      return state.timeSheetStatus
    },
    selectedTimeSheetStatus(state) {
      return state.selectedTimeSheetStatus
    },
  },

  mutations: {
    timeSheetStatus(state, payload) {
      state.timeSheetStatus = payload
    },
    selectedTimeSheetStatus(state, payload) {
      state.selectedTimeSheetStatus = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedTimeSheetStatus(context, payload) {
      context.commit('selectedTimeSheetStatus', payload)
    },
    loadTimeSheetStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'TimeSheetStatus'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('timeSheetStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedTimeSheetStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'TimeSheetStatus/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedTimeSheetStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateTimeSheetStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetStatus/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'timesheet status updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadTimeSheetStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createTimeSheetStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetStatus`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'timesheet status created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadTimeSheetStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveTimeSheetStatus(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateTimeSheetStatus', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createTimeSheetStatus', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteTimeSheetStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `TimeSheetStatus/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'timesheet status deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadTimeSheetStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default timeSheetStatus
