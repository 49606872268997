<template>
  <span>
    <slot name="searchParams">
      <v-row>
        <v-col>
          <v-text-field
            v-if="search"
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col sm="2" v-if="exportExcel">
          <excelExport
            :data="items"
            :exportFields="excelFields"
            :worksheet="sheetName"
            :name="`Export.xlsx`"
            >Export</excelExport
          >
        </v-col>
      </v-row>
    </slot>
    <v-data-table
      v-bind="$attrs"
      :items="items"
      :search="searchText"
      :headers="computedHeaders"
      :dense="dense"
      :loading="loading"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>
    </v-data-table>
  </span>
</template>
<script>
import excelExport from '@components/excelExport'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
    },
    searchText: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
    },
    exportExcel: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    exportFileName: {
      type: String,
      default: 'Export',
    },
    sheetName: {
      type: String,
      default: 'Sheet1',
    },
  },
  components: {
    excelExport,
  },
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    computedHeaders() {
      if (this.headers && this.headers.length > 0) {
        return this.headers
      } else {
        if (this.items.length > 0) {
          return Object.keys(this.items[0]).map((i) => {
            return {
              text: i.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1'),
              value: i,
              sortable: true,
            }
          })
        } else {
          return []
        }
      }
    },
    excelFields() {
      let fields = {}
      this.computedHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      return fields
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>