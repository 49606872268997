import Vue from 'vue'

const leaveSlip = {
  state: {
    leaveSlip: [],
    selectedLeaveSlip: {},
  },

  getters: {
    leaveSlip(state) {
      return state.leaveSlip
    },
    selectedLeaveSlip(state) {
      return state.selectedLeaveSlip
    },
  },

  mutations: {
    leaveSlip(state, payload) {
      state.leaveSlip = payload
    },
    selectedLeaveSlip(state, payload) {
      state.selectedLeaveSlip = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedLeaveSlip(context, payload) {
      context.commit('selectedLeaveSlip', payload)
    },
    loadLeaveSlip(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LeaveSlip'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('leaveSlip', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedLeaveSlip(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LeaveSlip/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedLeaveSlip', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateLeaveSlip(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LeaveSlip/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'leave slip updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLeaveSlip')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createLeaveSlip(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LeaveSlip`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'leave slip created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLeaveSlip')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveLeaveSlip(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLeaveSlip', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createLeaveSlip', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteLeaveSlip(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `LeaveSlip/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'leave slip deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLeaveSlip')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default leaveSlip
