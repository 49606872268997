import Vue from 'vue'

const employeeStatus = {
  state: {
    employeeStatus: [],
    selectedEmployeeStatus: {},
  },

  getters: {
    employeeStatus(state) {
      return state.employeeStatus
    },
    selectedEmployeeStatus(state) {
      return state.selectedEmployeeStatus
    },
  },

  mutations: {
    employeeStatus(state, payload) {
      state.employeeStatus = payload
    },
    selectedEmployeeStatus(state, payload) {
      state.selectedEmployeeStatus = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedEmployeeStatus(context, payload) {
      context.commit('selectedEmployeeStatus', payload)
    },
    loadEmployeeStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'EmployeeStatus'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('employeeStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedEmployeeStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'EmployeeStatus/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedEmployeeStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateEmployeeStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EmployeeStatus/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'employee status updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEmployeeStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createEmployeeStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EmployeeStatus`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'employee status created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEmployeeStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveEmployeeStatus(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateEmployeeStatus', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createEmployeeStatus', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteEmployeeStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `EmployeeStatus/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'employee status deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadEmployeeStatus')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default employeeStatus
