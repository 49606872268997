import Vue from 'vue'

const project = {
  state: {
    project: [],
    selectedProject: {},
    searchCount: 0,
  },

  getters: {
    project(state) {
      return state.project
    },
    selectedProject(state) {
      return state.selectedProject
    },
    loading(state) {
    return state.searchCount > 0
    },
  },

  mutations: {
    project(state, payload) {
      state.project = payload
    },
    selectedProject(state, payload) {
      state.selectedProject = JSON.parse(JSON.stringify(payload))
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedProject(context, payload) {
      context.commit('selectedProject', payload)
    },
    loadProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Project'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('project', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedProject(context, payload) {
      if (
        context.state.selectedProject &&
        context.state.selectedProject.ID != payload
      ) {
        context.commit('selectedProject', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'Project/' + payload
        // context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedProject', res.data)
            // context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            // context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Project/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProject')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Project`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProject')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveProject(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateProject', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createProject', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteProject(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Project/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'project deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadProject')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default project
