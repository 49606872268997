import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luExciseTaxTypes = {
  state: {
    luExciseTaxTypes: [],
    selectedLuExciseTaxTypes: {},
    selectedLuExciseTaxTypesOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luExciseTaxTypes(state) {
      return state.luExciseTaxTypes
    },
    selectedLuExciseTaxTypes(state) {
      return state.selectedLuExciseTaxTypes
    },
    selectedLuExciseTaxTypesOriginal(state) {
      return state.selectedLuExciseTaxTypesOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luExciseTaxTypes(state, payload) {
      state.luExciseTaxTypes = payload
    },
    selectedLuExciseTaxTypes(state, payload) {
      state.selectedLuExciseTaxTypes = JSON.parse(JSON.stringify(payload))
    },
    selectedLuExciseTaxTypesOriginal(state, payload) {
      state.selectedLuExciseTaxTypesOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuExciseTaxTypes(context, payload) {
      context.commit('selectedLuExciseTaxTypes', payload)
    },
    loadLuExciseTaxTypes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuExciseTaxTypes'
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luExciseTaxTypes', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuExciseTaxTypes(context, payload) {
      if (context.state.selectedLuExciseTaxTypes && context.state.selectedLuExciseTaxTypes.ID > 0) {
        context.dispatch('loadSelectedLuExciseTaxTypes', context.state.selectedLuExciseTaxTypes.ID)

      }
    },
    loadSelectedLuExciseTaxTypes(context, payload) {
      if (context.state.selectedLuExciseTaxTypes && context.state.selectedLuExciseTaxTypes.ID != payload) {
        context.commit('selectedLuExciseTaxTypes', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'LuExciseTaxTypes/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuExciseTaxTypes', res.data)
            context.commit('selectedLuExciseTaxTypesOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuExciseTaxTypes(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuExciseTaxTypes')
      }, )
    },
    createLuExciseTaxTypes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuExciseTaxTypes`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax types created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExciseTaxTypes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    patchLuExciseTaxTypes(context, payload){
      return new Promise((resolve, reject) => { 
        var item = context.state.selectedLuExciseTaxTypes
        if (payload.ID && 
        payload.ID == context.state.selectedLuExciseTaxTypesOriginal.ID)  {
        item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.ID) {
          let original = context.state.selectedLuExciseTaxTypesOriginal
          let current = item
          patchPayload.ID = item.ID
          var diff = jsonpatch.compare(
                original,
                current
              )
          patchPayload.patchDoc = diff

        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `LuExciseTaxTypes/${ patchPayload.ID }`
          context.commit('saving', true)
          Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          .then(
            (res) => {
              context.dispatch('messages/toastMessage',
              {color: 'success', message: 'excise tax types updated',},{ root: true })
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadLuExciseTaxTypes')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, { root: true })
              reject(err)
            },
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateLuExciseTaxTypes(context, payload){
      return new Promise((resolve, reject) => { 
        const url = `LuExciseTaxTypes/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax types updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExciseTaxTypes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuExciseTaxTypes(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuExciseTaxTypes', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuExciseTaxTypes', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuExciseTaxTypes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuExciseTaxTypes/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'excise tax types deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadLuExciseTaxTypes')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luExciseTaxTypes