import Vue from 'vue'

const holiday = {
  state: {
    holiday: [],
    selectedHoliday: {},
  },

  getters: {
    holiday(state) {
      return state.holiday
    },
    selectedHoliday(state) {
      return state.selectedHoliday
    },
  },

  mutations: {
    holiday(state, payload) {
      state.holiday = payload
    },
    selectedHoliday(state, payload) {
      state.selectedHoliday = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setSelectedHoliday(context, payload) {
      context.commit('selectedHoliday', payload)
    },
    loadHoliday(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Holiday'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('holiday', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    loadSelectedHoliday(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Holiday/' + payload
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedHoliday', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    updateHoliday(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Holiday/${payload.ID}`
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'holiday updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadHoliday')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    createHoliday(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Holiday`
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'holiday created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadHoliday')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveHoliday(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateHoliday', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createHoliday', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteHoliday(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `Holiday/${payload.ID}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'holiday deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadHoliday')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default holiday
