import Vue from 'vue'
import {debounce, range} from 'lodash'
import dayjs from 'dayjs'
import download from 'downloadjs'

const cannedReport = {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: [],
    searchDescending: [],
    groupByProperty: '',
    groupByDescending: false,
    searchRowsPerPage: 25,
    searchText: '',
    startDate: null,
    endDate: null,
    projectId: null,
    licenseNumber: null,
    ridNumber: null,
    sectionId: null,
    stratumIds: [],
    transactionTypeId: null,
    projectionTypeId: null,
    gridData: [],
    formatFields: {},
    headerItems: [],
    defaultSortBy: [],
    defaultSortDirection: [],
  },

  getters: {
    searchParameters(state) {
      var sortDirection
      if (Array.isArray(state.searchDescending)) {
        sortDirection = state.searchDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.searchDescending ? ['desc'] : ['asc']
      }
      var groupByDirection
      if (Array.isArray(state.groupByDescending)) {
        if (state.groupByDescending.length > 0) {
          groupByDirection = state.groupByDescending[0] ? 'desc' : 'asc'
        } else {
          groupByDirection = 'asc'
        }
      } else {
        groupByDirection = state.groupByDescending ? 'desc' : 'asc'
      }
      return {
        pagination: {
          pageNum: state.searchPage - 1,
          pageSize: state.searchRowsPerPage,
          sortProperty: state.searchSortBy,
          sortDirection,
          groupByProperty: state.groupByProperty,
          groupByDirection,
        },
        searchText: state.searchText,
        startDate: state.startDate,
        endDate: state.endDate,
        projectId: state.projectId,
        licenseNumber: state.licenseNumber,
        ridNumber: state.ridNumber,
        stratumIds: state.stratumIds,
        transactionTypeId: state.transactionTypeId,
        projectionTypeId: state.projectionTypeId,

        //todo: add additional search parameters
      }
    },
    reportDefaults(state) {
      if (state.selectedCannedReport.ReportDefaults) {
        var defaults = JSON.parse(state.selectedCannedReport.ReportDefaults)
        return defaults
      } else {
        return {}
      }
    },
    reportParameters(state) {
      if (state.selectedCannedReport.ReportParameters) {
        var params = JSON.parse(state.selectedCannedReport.ReportParameters)
        params.forEach((item) => {
          if (item.calculateDefault && item.defaultValue === null) {
            console.devlog(item)
            var periodDifference = item.calculateDefault.relatedPeriod
            var period = 'M'
            var startEndOf = 'month'
            //we are looking at a monthly report
            if (item.calculateDefault.frequencyID == 5) {
              period = 'M'
              startEndOf = 'month'
            }
            //todo: add other date calculation periods if used
            var date = dayjs()
            date = date.add(periodDifference, period)
            if (item.calculateDefault.pointInPeriod == 0) {
              date = date.startOf(startEndOf)
            } else if (item.calculateDefault.pointInPeriod == 2) {
              date = date.endOf(startEndOf)
            }
            item.defaultValue = date.format('YYYY-MM-DD')
            // if (state[item.text] == null) {
            //   state[item.text] = item.defaultValue
            // }
          }
        })
        return params
      } else {
        return []
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    startDate(state, payload) {
      state.startDate = payload
    },
    endDate(state, payload) {
      state.endDate = payload
    },
    projectId(state, payload) {
      state.projectId = payload
    },
    licenseNumber(state, payload) {
      state.licenseNumber = payload
    },
    ridNumber(state, payload) {
      state.ridNumber = payload
    },
    sectionId(state, payload) {
      state.sectionId = payload
    },
    stratumIds(state, payload) {
      state.stratumIds = payload
    },
    transactionTypeId(state, payload) {
      state.transactionTypeId = payload
    },
    projectionTypeId(state, payload) {
      state.projectionTypeId = payload
    },
    groupByProperty(state, payload) {
      state.groupByProperty = payload
    },
    groupByDescending(state, payload) {
      state.groupByDescending = payload
    },
    gridData(state, payload) {
      state.gridData = payload
    },
    formatFields(state, payload) {
      state.formatFields = payload
    },
    headerItems(state, payload) {
      state.headerItems = payload
    },
    defaultSortBy(state, payload) {
      state.defaultSortBy = payload
    },
    defaultSortDirection(state, payload) {
      state.defaultSortDirection = payload
    },
    resetSearchParamsAndData(state) {
      state.headerItems = []
      state.formatFields = {}
      state.searchTotal = 0
      state.searchCount = 0
      state.searchPage = 1
      state.searchSortBy = []
      state.searchDescending = []
      state.searchRowsPerPage = 25
      state.searchText = ''
      state.startDate = null
      state.endDate = null
      // state.licenseNumber = null
      state.ridNumber = null
      // state.projectId = null
      state.stratumIds = []
      state.transactionTypeId = null
      state.projectionTypeId = null
      state.groupByProperty = null
      state.groupByDescending = null
    },
  },

  actions: {
    loadBounce: _.debounce((context) => {
      context.dispatch('loadCannedReportGridData')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.searchSortBy)
      ) {
        context.commit('searchSortBy', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.searchDescending)
      ) {
        context.commit('searchDescending', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setGroupByProperty(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.groupByProperty)
      ) {
        context.commit('groupByProperty', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setGroupByDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.groupByDescending)
      ) {
        context.commit('groupByDescending', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setStartDate(context, payload) {
      if (payload != context.state.startDate) {
        context.commit('startDate', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setEndDate(context, payload) {
      if (payload != context.state.endDate) {
        context.commit('endDate', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setProjectId(context, payload) {
      if (payload != context.state.projectId) {
        context.commit('projectId', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setLicenseNumber(context, payload) {
      if (payload != context.state.licenseNumber) {
        context.commit('licenseNumber', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setRidNumber(context, payload) {
      if (payload != context.state.ridNumber) {
        context.commit('ridNumber', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setSectionId(context, payload) {
      if (payload != context.state.sectionId) {
        context.commit('sectionId', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setStratumIds(context, payload) {
      if (JSON.stringify(payload) != JSON.stringify(context.state.stratumIds)) {
        context.commit('stratumIds', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setTransactionTypeId(context, payload) {
      if (payload != context.state.transactionTypeId) {
        context.commit('transactionTypeId', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setProjectionTypeId(context, payload) {
      if (payload != context.state.projectionTypeId) {
        context.commit('projectionTypeId', payload)
        context.dispatch('loadBounce', context)
      }
    },
    setGroupByProperty(context, payload) {
      if (payload != context.state.groupByProperty) {
        context.commit('groupByProperty', payload)
        context.dispatch('loadBounce', context)
      }
    },
    loadCannedReportGridData(context, payload) {
      if (
        context.state.selectedCannedReport &&
        context.state.selectedCannedReport.ID > 0
      ) {
        return new Promise((resolve, reject) => {
          if (!context.state.searchSortBy) {
            context.commit('searchSortBy', context.state.defaultSortBy)
            context.commit(
              'searchDescending',
              context.state.defaultSortDirection
            )
          }
          if (!Array.isArray(context.state.searchSortBy)) {
            if (context.state.searchSortBy) {
              context.commit('searchSortBy', [context.state.searchSortBy])
              if (!Array.isArray(context.state.searchDescending)) {
                context.commit('searchDescending', [
                  context.state.searchDescending,
                ])
              }
            } else {
              context.commit('searchSortBy', context.state.defaultSortBy)
              context.commit(
                'searchDescending',
                context.state.defaultSortDirection
              )
            }
          }
          if (!context.state.searchText) {
            context.commit('searchText', '')
          }
          var hasAllParameters = true
          var groupRequired = {}
          context.getters.reportParameters.forEach((p) => {
            if (p.required && !context.getters.searchParameters[p.name]) {
              console.devlog(p.name)
              hasAllParameters = false
            }
            // -check for group required parameters
            if (p.requiredGroup) {
              if (!groupRequired[p.requiredGroup]) {
                groupRequired[p.requiredGroup] = [p.name]
              } else {
                groupRequired[p.requiredGroup].push(p.name)
              }
            }
          })
          if (Object.keys(groupRequired).length > 0) {
            Object.keys(groupRequired).forEach((g) => {
              var groupHasParam = false
              groupRequired[g].forEach((f) => {
                if (context.getters.searchParameters[f]) {
                  groupHasParam = true
                }
              })
              if (!groupHasParam) {
                hasAllParameters = false
              }
            })
          }
          if (hasAllParameters) {
            context.commit('increaseSearchCount')
            var params = context.getters.searchParameters
            var paramString = JSON.stringify(params)
            var selectedCannedReport = context.state.selectedCannedReport
            var section =
              selectedCannedReport &&
              selectedCannedReport.reportDefaults &&
              selectedCannedReport.reportDefaults.ReportSections &&
              selectedCannedReport.reportDefaults.ReportSections.length > 0
                ? context.state.sectionId
                : null

            let url = `Report/GetGridData/${selectedCannedReport.ID}`
            if (section) {
              url += `/${section}`
            }
            Vue.prototype.$axios.post(url, params).then(
              (res) => {
                if (
                  JSON.stringify(context.getters.searchParameters) ==
                  paramString
                ) {
                  context.commit('headerItems', res.data.Headers)
                  context.commit('formatFields', res.data.FormatFields)
                  context.commit('gridData', res.data.Entries)
                  context.commit('searchRowsPerPage', res.data.Page.Size)
                  context.commit('searchPage', res.data.Page.Number + 1)
                  context.commit('searchTotal', res.data.Page.TotalElements)
                  if (
                    res.data.SortProperty.every((x) =>
                      context.state.headerItems.includes(x)
                    )
                  ) {
                    context.commit('searchSortBy', res.data.SortProperty)
                    context.commit('defaultSortBy', res.data.SortProperty)
                    var defaultDirection = res.data.SortDirection.map((x) =>
                      x == 'desc' ? true : false
                    )
                    context.commit('searchDescending', defaultDirection)
                    context.commit('defaultSortDirection', defaultDirection)
                  }
                  if (
                    context.state.headerItems.includes(res.data.GroupByProperty)
                  ) {
                    context.commit('groupByProperty', res.data.GroupByProperty)
                    context.commit(
                      'groupByDescending',
                      res.data.GroupByDirection == 'desc' ? true : false
                    )
                  }
                }
                context.commit('decreaseSearchCount')
                resolve(res)
              },
              (err) => {
                console.error(err)
                context.dispatch('errors/handleError', err, {root: true})
                context.commit('decreaseSearchCount')
                reject(err)
              }
            )
          }
        })
      }
    },
    downloadCannedReport(context, payload) {
      if (!context.state.searchSortBy) {
        context.commit('searchSortBy', [])
        context.commit('searchDescending', [])
      }
      if (!Array.isArray(context.state.searchSortBy)) {
        if (context.state.searchSortBy) {
          context.commit('searchSortBy', [context.state.searchSortBy])
          if (!Array.isArray(context.state.searchDescending)) {
            context.commit('searchDescending', [context.state.searchDescending])
          }
        } else {
          context.commit('searchSortBy', [])
          context.commit('searchDescending', [])
        }
      }
      if (!context.state.searchText) {
        context.commit('searchText', '')
      }
      let headers = {responseType: 'blob'}
      //todo: send filetype
      let url = `Report/Download/${payload.selectedFileTypeID}/${context.state.selectedCannedReport.ID}`
      Vue.prototype.$axios
        .post(url, context.getters.searchParameters, headers)
        .then(
          (res) => {
            const content = res.headers['content-type']
            const dispo = res.headers['content-disposition']
            var filename = ''
            if (dispo && dispo.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              var matches = filenameRegex.exec(dispo)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            download(res.data, filename, content)
          },
          (error) => {
            context.dispatch('errors/handleError', error, {root: true})
            console.error(error)
          }
        )
    },
  },
}

export default cannedReport
